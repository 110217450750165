<ul *ngIf="!iconState" class="list-group my-3">
        <li *ngFor="let item of items" class="list-group-item w-100">
                <div class="prueba"
                        (click)="sendOption(item.type, (item.price_sm === 0 && item.description === 'Pago mínimo'))">
                        <h3 *ngIf="item.title">{{item.title}}</h3>
                        <h4 *ngIf="item.title_sm">{{item.title_sm}}</h4>
                        <p *ngIf="item.description" class="description">{{item.description}}</p>
                        <p *ngIf="item.price" class="price">{{ item.price ? (item.price | numberFormat) : '0' }} </p>
                        <p *ngIf="item.price_sm === 0 && item.description === 'Pago mínimo'" class="price_sm"> $0 (Estas  al día) </p>
                        <p *ngIf="item.price_sm" class="price_sm">{{ item.price_sm ? (item.price_sm | numberFormat) : '' }} </p>
                        <p *ngIf="item.description2" class="description2">{{item.description2}}</p>
                        <span>></span>
                </div>
        </li>
</ul>


<ul *ngIf="iconState" class="list-group my-3 payment">
        <li class="list-group-item w-100 shhoping-cart">
                <p> Detalles de compras a pagar</p>
                <i class="far fa-credit-card"></i>
        </li>
        <li *ngFor="let item of items;let i=index" (click)="chooseCreditToPay(item, item.type, i)"
                class="list-group-item w-100 items">
                <div class="logo">
                        <img class="img-fluid" src="{{item.img_url}}">
                </div>
                <div class="content">
                        <p class="proveedor"> Proveedor: {{ item.shop_commerce_name }} </p>
                        <p class="price">Valor: {{ item.total_credit | numberFormat }} </p>
                        <p class="text">Fecha de entrega: {{ item.delivery_date | customDateFormat }} </p>
                        <p class="text"># de orden: {{ item.order_number }} </p>
                        <p class="text">Estado: {{ item.credit_delivered }} </p>
                        <p class="text date">Fecha limite de pago: {{ item.deadline | customDateFormat }} </p>
                </div>
                <span *ngIf="item.type == 1">></span>
        </li>
</ul>