<div class="modal-header">
        <h4 class="modal-title">
                {{ title }} Solicitud de credito #{{ creditRequestId }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
        <ul class="nav nav-tabs">
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'informacionPersonal'"
                                (click)="cambiarPestana('informacionPersonal')">
                                Información Personal
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'documentosAsociados'"
                                (click)="cambiarPestana('documentosAsociados')">
                                Documentos Asociados
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'historialCreditos'"
                                (click)="cambiarPestana('historialCreditos')">
                                Historial de Créditos
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'informacionFinanciera'"
                                (click)="openFinancialInfoModal(customerInfo)">
                                Información Financiera
                        </a>
                </li>
        </ul>

        <div class="tab-content">
                <div [class]="
        tabActiva === 'informacionPersonal'
          ? 'tab-pane show active'
          : 'tab-pane'
      ">
                        <h2 *ngIf="isAdmin"> Score: {{ customerInfo.score }} </h2>
                        <div class="row my-4">
                                <div class="col-12">
                                        <h5>Solicitud diligenciada por el cliente</h5>
                                </div>
                        </div>
                        <!-- Campos de información personal -->
                        <form [formGroup]="customerEditForm">
                                <div class="row">
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>ID Usuario</label>
                                                        <input formControlName="id" type="text" class="form-control" [disabled]="true" />
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Nit</label>
                                                        <input formControlName="nit" type="text" class="form-control"[disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Nombre de la empresa</label>
                                                        <input formControlName="buss_name" type="text" class="form-control"  [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Departamento</label>
                                                        <select (change)="changeArrayCities()"  formControlName="department_id" class="form-select"[disabled]="datosDeshabilitados">
                                                                <option *ngFor="let department of departments" [value]="department.id">{{ department.name }}</option>
                                                        </select>
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Ciudad</label>
                                                        <select  formControlName="city_id" class="form-select" [disabled]="datosDeshabilitados">
                                                                <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                                                        </select>
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Dirección de la Empresa</label>
                                                        <input  formControlName="businness_address" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-3 mb-2">
                                                <div class="form-group">
                                                        <label>Registrado en Cámara de Comercio</label>
                                                        <select  formControlName="registered_chamber_commerce" class="form-select" [disabled]="datosDeshabilitados">
                                                                <option *ngFor="let cci of ccis" [value]="cci.id">{{ cci.name }}</option>
                                                                <option  [value]="null"> Seleccione una opción </option>
                                                        </select>
                                                </div>
                                        </div>
                                </div>
                                <hr />
                                <div class="row">
                                        <div class="col-md-4">
                                                <div class="form-group">
                                                        <label>Nombres</label>
                                                        <input  formControlName="name" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group">
                                                        <label>Apellidos</label>
                                                        <input  formControlName="last_name" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group">
                                                        <label>Tipo de identificación</label>
                                                        <select  formControlName="identification_type" class="form-select"[disabled]="datosDeshabilitados">
                                                                <option *ngFor="let typeIdentifications of typeIdentifications" [value]="typeIdentifications.id">{{ typeIdentifications.name }}</option>
                                                        </select>
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group mt-2">
                                                        <label>Número de identificación</label>
                                                        <input  formControlName="identification" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group mt-2">
                                                        <label>Número de Celular</label>
                                                        <input  formControlName="phone" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>

                                        <div class="col-md-4">
                                                <div class="form-group mt-2">
                                                        <label>Número de Celular 2</label>
                                                        <input  formControlName="phone_2" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group mt-2">
                                                        <label>Email</label>
                                                        <input  formControlName="email" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                        <div class="col-md-4">
                                                <div class="form-group mt-2">
                                                        <label>Dirección</label>
                                                        <input  formControlName="address" type="text" class="form-control" [disabled]="datosDeshabilitados" />
                                                </div>
                                        </div>
                                </div>
                                <div class="row justify-content-end mt-3">
                                        <div class="col-12 text-end">
                                                <button *ngIf="canSendWhatsapp" type="button" class="btn btn-success me-2" (click)="sendWhatsappLinkDeceval()">
                                                        Enviar enlace de pagare
                                                </button>
                                                <button type="button" class="btn btn-primary me-2"
                                                        (click)="habilitarDatos()">
                                                        <i class="fas fa-save ml-2"></i> Editar
                                                </button>
                                                <button type="button" class="btn btn-success"
                                                        (click)="ActualizarDatos()">
                                                        <i class="fas fa-save ml-2"></i> Actualizar
                                                </button>
                                        </div>
                                </div>
                        </form>

                        <!-- FOTOS -->
                        <hr />
                        <div class="row my-2">
                                <div class="col-12">
                                        <h5>FOTOS CLIENTE</h5>
                                </div>
                        </div>
                        <div>
                                <a [href]="customerInfo.document_file_up" target="_blank" class="btn btn-danger me-2">
                                        <i class="fas fa-camera"></i>
                                        Ver foto frontal
                                </a>
                                <a [href]="customerInfo.document_file_down" target="_blank" class="btn btn-danger me-2">
                                        <i class="fas fa-camera"></i>
                                        Ver foto reverso
                                </a>
                                <a [href]="customerInfo.image_file" target="_blank" class="btn btn-danger">
                                        <i class="fas fa-camera"></i>
                                        Ver selfie
                                </a>
                        </div>

                        <!-- NOTAS -->
                        <hr />
                        <div class="row my-2">
                                <div class="col-12">
                                        <h5>NOTAS</h5>
                                </div>
                        </div>
                        <div class="row">
                                <div class="col-12">
                                        <div class="form-group">
                                                <textarea class="form-control" [(ngModel)]="textareaContent"></textarea>
                                        </div>
                                </div>
                        </div>
                        <div class="row justify-content-end">
                                <div class="col-md-2 mt-3 text-end">
                                        <button type="button" class="btn btn-success" (click)="saveNotes()">
                                                <i class="fas fa-save ml-2"></i> Guardar
                                        </button>
                                </div>
                        </div>
                        <hr />
                        <div class="card">
                                <div class="card-header pb-0">
                                        <div class="media">
                                                <div class="media-body">
                                                        <h5 class="mb-0">Notas agregadas:</h5>
                                                </div>
                                        </div>
                                </div>
                                <div class="card-body">
                                        <div class="activity-media">
                                                <div class="media" *ngFor="let nota of notasAgregadas">
                                                        <div class="recent-circle bg-primary"></div>
                                                        <div class="media-body">
                                                                <h6 class="font-roboto mb-0">{{ nota.note }}</h6>
                                                                <span>
                                                                        <i class="fas fa-clock"></i>
                                                                        <span class="font-roboto">
                                                                                Nota dejada el {{ nota.created }} por {{
                                                                                nota.user }}
                                                                        </span>
                                                                </span>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>

                <!-- DOCUMENTOS -->
        <div [class]="
        tabActiva === 'documentosAsociados'
          ? 'tab-pane show active'
          : 'tab-pane'
      ">
                        <div class="row my-4">
                                <div class="col-12">
                                        <h5>DOCUMENTOS ASOCIADOS</h5>
                                </div>
                        </div>
                        <div class="form-group justify-content-between d-flex">
                                <input type="file" #fileInput class="form-control-file" />
                                <button type="button" class="btn btn-primary" (click)="subirDocumento(fileInput)">
                                        Subir documento
                                </button>
                        </div>
                        <div>
                                <!-- Historial de documentos subidos -->
                                <hr />
                                <div>
                                        <h6>Documentos agregados:</h6>
                                        <ul class="files-content">
                                                <li *ngFor="let document of documentsList; let i = index"
                                                        class="ng-star-inserted">
                                                        <div class="d-flex align-items-center files-list">
                                                                <div class="flex-shrink-0 file-left">
                                                                        <i
                                                                                class="f-22 fa fa-file font-success"></i>
                                                                </div>
                                                                <div class="flex-grow-1 ms-3">
                                                                        <h6 class="mb-1">Documento # {{ i + 1 }}</h6>
                                                                        <h6 class="mb-1">Nombre: {{ document.file_name }}</h6>
                                                                        <button class="btn btn-primary" (click)="downloadFile(document.file)" > Descargar archivo </button>
                                                                        <p>Fecha creación : <small>{{ document.created |
                                                                                        date:'yyyy MM dd' }}</small></p>
                                                                </div>
                                                        </div>
                                                </li>
                                        </ul>

                                </div>
                        </div>
                </div>

                <!-- HITORIAL CREDITOS -->

                <div [class]="
        tabActiva === 'historialCreditos' ? 'tab-pane show active' : 'tab-pane'
      ">
                        <table class="table mt-2">
                                <thead>
                                        <tr>
                                                <th>Obligación</th>
                                                <th>Valor Crédito</th>
                                                <th>F. pago cliente</th>
                                                <th>Coutas</th>
                                                <th>V. Cuota</th>
                                                <th>Abonado</th>
                                                <th>Saldo</th>
                                                <th>Dias mora</th>
                                                <th>V. mora</th>
                                                <th>Saldo total</th>
                                                <th>Estado</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        <!-- si hay registros -->
                                        <ng-container
                                                *ngIf="historyCreditsCustomerList && historyCreditsCustomerList.length > 0;">
                                                <tr *ngFor="let item of historyCreditsCustomerList">
                                                        <td>{{item.credits_code_pay }}</td>
                                                        <td>{{item.credits_value | currency:'$':'symbol':'1.0-0' }}</td>
                                                        <td>{{ item.credits_deadline | defaultIfEmpty:'No disponible'  }}</td>
                                                        <td>{{item.credits_number_fee}}</td>
                                                        <td>{{item.credits_quota_value | currency:'$':'symbol':'1.0-0'
                                                                }}</td>
                                                        <td>{{item.total_abonado | currency:'$':'symbol':'1.0-0' }}</td>
                                                        <td>{{item.credits_value_pending | currency:'$':'symbol':'1.0-0'
                                                                }}</td>
                                                        <td>{{ item.dias_mora }}</td>
                                                        <td>{{ item.value_intereses_mora }}</td>
                                                        <td>{{item.saldo_total| currency:'$':'symbol':'1.0-0' }}</td>
                                                        <td>
                                                                <span
                                                                        class="badge badge-{{ item.credits_states_color }}">
                                                                        {{ item.credits_states_name }}
                                                                </span>
                                                        </td>
                                                </tr>
                                        </ng-container>

                                        <!-- si no hay registros -->
                                        <ng-container
                                                *ngIf="!(historyCreditsCustomerList && historyCreditsCustomerList.length > 0);">
                                                <tr>
                                                        <td colspan="10">
                                                                <app-msj-no-registers></app-msj-no-registers>
                                                        </td>
                                                </tr>
                                        </ng-container>
                                </tbody>
                        </table>
                </div>
        
        </div>
</div>