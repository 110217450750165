


<div *ngFor="let item of commerceDashboardCard | keyvalue ">
    <div class="d-flex content-card-dashboard shadow-sm rounded" >
        <div [ngClass]="colorClasses[item.key]" class="ball  d-flex justify-content-center align-items-center">
          <i class="fa fa-xl" [ngClass]="{
            'fa-money-bill-transfer': item.key === 'withdrawnCredits',
            'fa-person': item.key === 'totalCustomers',
            'fa-bell': item.key === 'creditsRequestsCount',
            'fa-user-minus': item.key === 'withdrawnCreditsCount',
          }" style="color: #ffffff" ></i>
        </div>
        <div class="font-content">
          <h2 class="fs-5"> {{ fieldTitles[item.key] }} </h2>
          <span *ngIf="item.key === 'withdrawnCredits'; else number">
            {{  item.value | numberFormat }}
          </span>
          <ng-template #number>
            <span>
                 {{  item.value | number }}
            </span>
         </ng-template>
        </div>
      </div>
</div>