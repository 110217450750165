<div class="justify-content-sm-center m-0 p-0 gap-2 contenedor">
      <div class="col-12 col-sm-11 col-md-9 m-0 p-0 bg-white col-lg-5 information {{isTablet?'tablet':''}}">

            <!-- Header de todos los payments -->
            <app-payment-header [stateImg]="currentViewNumber > 1 ? true : false"
                  [title]="currentViewNumber < 3 ? 'Hola, ' + dataAboutCustomer.customer.full_name: dataAboutCustomer.customer.full_name"
                  [smallIconUser] = "(currentViewNumber == 1)"
                  (backButtonEvent)="backCurrentViewNumber($event)"
                  *ngIf="currentViewNumber != 1"></app-payment-header>

            <div class="{{currentViewNumber === 1 ? 'height-completed' : 'height-parcial'}}" [ngClass]="">

                  <!-- Componente para consultar la cedula -->
                  <app-payment-link *ngIf="currentViewNumber === 1" (data)="setData($event)"></app-payment-link>

                  <!-- Componente para listar creditos viejos y nuevos -->
                  <app-payment-credits (eventOptionByType3)="optionByType3($event)" [itemsStep]="this.total_data_type3.listOldNew"
                        *ngIf="( currentViewNumber === 2)"></app-payment-credits>

                  <!-- Componente para listar deudas especificas, escoger cual pagar -->
                  <!-- Para la parte de credito viejo enviamos [choosedCreditOld] y recibimos (chooseOnlineCorrespondent) -->
                  <!-- Para la parte de credito nuevo enviamos [chooseTotalPay] -->
                  <app-payment-how-much [choosedCreditOld]="choosedCreditOld" [chooseTotalPay]="dataAboutCustomer"
                        (eventChooseOnlineCorrespondent)="chooseOnlineCorrespondent($event)"
                        *ngIf="( currentViewNumber === 3)"></app-payment-how-much>

                  <!-- Componente para vista 3, tipo de pago escogido /(abono, total o, minimo) -->
                  <!-- Tulizado para el credito viejo el Ouput(eventChooseCreditOldToPay) y el input(dataUserPayment)-->
                  <app-payment-view3 (eventChooseCreditOldToPay)="chooseCreditOldToPay($event)"
                        (eventClickPay)="clickButtonPay($event)" [dataUserPayment]="dataAboutCustomer"
                        *ngIf="( currentViewNumber === 4)"></app-payment-view3>

                  <!-- Componente vista 4, pago online or presential 
                  (************[ dataResponseId sirve para enviar todos los datos creditos viejos y consultar al api ]************)
                  (************( chageViewCorrespondents sirve para cambiar a vista  corresponsal )************) -->
                  <app-payment-view4 [choosedCreditOld]="choosedCreditOld"
                        (eventOption)="chageViewCorrespondents($event)"
                        *ngIf="( currentViewNumber === 5)"></app-payment-view4>

                  <!-- Para visualizar los corresponsales y los convenios que deben haber -->
                  <app-payment-correspondent [data]="choosedCreditOld"
                        *ngIf="( currentViewNumber === 6)"></app-payment-correspondent>

                  <app-payment-view5 [totalPayment]="returnTotalDebet()"
                        (eventOptionAbonoSend)="getOptionAbonoSend($event)"
                        *ngIf="( currentViewNumber === 7)"></app-payment-view5>

            </div>
      </div>
      <div *ngIf="!isTablet" class="componente-imagenes col">
            <div class="col-0 col-sm-4 col-md-7 p-0 m-0 d-none d-md-block">
                  <div class=" d-flex flex-column justify-content-between align-items-start gap-3 w-100 contenido">
                        <img class="img-fluid p-3 pt-4 ps-4 logo-ziro-header" style="width: 10rem"
                              src="/assets/img-news/logo-ilustration.svg" alt="Logo" />
                        <img class="img-fluid w-100 maletin-bottom" src="/assets/img-news/payment.svg" alt="Login" />
                  </div>
            </div>
      </div>
</div>