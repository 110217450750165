// time-format.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTimeFormat]'
})
export class TimeFormatDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Elimina caracteres no permitidos
    inputValue = inputValue.replace(/[^0-9:]/g, '');

    // Formatea el valor como HH:mm
    const formattedValue = this.formatTime(inputValue);

    // Actualiza el valor en el modelo del formulario
    this.ngControl.control?.setValue(formattedValue, { emitEvent: false });

    // Actualiza el valor en el input
    inputElement.value = formattedValue;
  }

  private formatTime(value: string): string {
    // Elimina los caracteres no permitidos
    value = value.replace(/[^0-9]/g, '');
  
    // Ajusta la lógica de formato según tus necesidades
    if (value.length <= 2) {
      return value;
    } else {
      const hours = value.slice(0, 2);
      const minutes = value.slice(2, 6);
      
      // Limita los minutos a 59
      const formattedMinutes = minutes.length > 2 ? minutes.slice(0, 2) : minutes;
  
      return hours + (formattedMinutes ? ':' + formattedMinutes : '');
    }
  }
  
}
