import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
        providedIn: 'root'
})
export class ComiunicateCustomersComponentsService {

        private updateCustomerListSource = new Subject<void>();

        // Observable al que los componentes se pueden suscribir
        updateCustomerList$ = this.updateCustomerListSource.asObservable();

        // Método para emitir el evento
        notifyUpdate() {
                this.updateCustomerListSource.next();
        }
}
