import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-state-badge',
  templateUrl: './state-badge.component.html',
  styleUrls: ['./state-badge.component.scss']
})
export class StateBadgeComponent {

  @Input() state: string | number;

  getBadgeClass(): string {
      return this.state === 'Activo' ? 'badge-success' : 'badge-danger';
  }
  
  getBadgeText(): string {
      return this.state === 'Activo' ? 'Activo' : 'Inactivo';
  }
}
