import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegisterCustomerInterface } from '../../Register/interfaces/register.interfaces';
import { ResponseSearch } from '../interfaces/register.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  /*************
   *  variables**
   *************/
  private baseUrl: string = environment.apiUrl + '/clientes';
  private baseUsuario: string = environment.apiUrl + '/usuarios';
  private baseUrlSucursal: string = environment.apiUrl + '/sucursales';
  private baseUrlMetamap: string = environment.apiUrl + '/metamap';
  private baseUrlForNever:string = environment.apiUrl
  private baseUrlApitude:string = environment.apiUrl + '/sync-face-id';
  private _refreshUsers$ = new Subject<void>();

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  /*************
   *  getters****
   *************/
  get _refreshUsers() {
    return this._refreshUsers$;
  }

  ngOnInit() {
    console.log('ngOnInit is running');
    this.route.url.subscribe((segments) => {
      const codigo = segments[segments.length - 1].path;
      this.verifyCodeProvider(codigo);
    });
  }

  /*************
   *  metodos****
   *************/

  verifyCedula(cedula: string) {
    const url = `${this.baseUrl}/existe/cedula/${cedula}`;
    return this.http.get<ResponseSearch>(url);
  }
  verifyCodeProvider(codigo: string) {
    console.log('Verifying code:', codigo);
    const url = `${this.baseUrlSucursal}/existe/codigo/${codigo}`;
    return this.http.get<ResponseSearch>(url);
  }

  verifyEmail(email: string,identification:string, stateRegister: string) {
    const url = `${this.baseUsuario}/existe/correo/${identification}/${email}/${stateRegister}`;
    return this.http.get<ResponseSearch>(url);
  }

  verifyNit(nit: string, identification:string, stateRegister:string) {
    const url = `${this.baseUrl}/existe/nit/${identification}/${nit}/${stateRegister}`;
    return this.http.get<ResponseSearch>(url);
  }

  verifyPhone(phone: string,identification:string, stateRegister:string ) {
    const url = `${this.baseUrl}/existe/celular/${identification}/${phone}/${stateRegister}`;
    return this.http.get<ResponseSearch>(url);
  }

  saveRegister(data: any) {
    const url = `${this.baseUrl}`;
    return this.http.post<RegisterCustomerInterface>(url, data);
  }

  generarToken() {
    const url = 'https://api.getmati.com/oauth';
    const data = 'grant_type=client_credentials';
    return this.http.post(url, data, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Basic NjMwZmM0ZDQ3NzM0ZjYwMDFjOTY5NzdiOkxWVUNPRDY3UUNESFVNNEFQVlU3QVE1Nk1PWklYQ1hM',
      },
    });
  }
  crearVerificacion(token: string) {
    const url = `https://api.getmati.com/v2/verifications`;
    const data =
      '{"metadata":{"plataforma":"ziro Api angular","urlAliado":"' +
      window.location.href +
      '"},"flowId":"6376ae47b2c008001c4277d7"}';
    return this.http.post(url, data, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      },
    });
  }
  sendRegisterMetamapSendInput(id: string, data: any, token: any) {
    const url = `https://api.getmati.com/v2/identities/${id}/send-input`;
    return this.http.post(url, data, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }
  getInformationUserMetamap(id: string, token: any) {
    const url = `https://api.getmati.com/v2/verifications/${id}`;
    return this.http.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }
  getinformationCustomerMetamap(id: string, token: string) {
    const body = {
      id,
      token,
    };
    const url = `${this.baseUrlMetamap}/validar-cliente`;
    return this.http.post<any>(url, body);
  }

  sendCodeOtp(celular: number) {
    const url = `${this.baseUrl}/regitro/codigo-otp/${celular}`;
    return this.http.get(url);
  }
  validateCodeOtpCustomerService(data: any) {
    const url = `${this.baseUrl}/regitro/validar/codigo-otp`;
    return this.http.post(url, data, {
      headers: {
        Accept: 'application/json',
      },
    });
  }
  // solicita un cupo de credito para el cliente o rechaza el cupo
  requestQuota(data: any) {
    console.log('data', data);
    const url = `${this.baseUrl}/registro/score?identification=${data.identification}&nit=${data.nit}&document_type_id=${data.document_type_id}&businness_type_id=${data.businness_type_id}`;
    return this.http.get(url, {
      headers: {
        Accept: 'application/json',
      },
    });
  }


  /**
   * !Consume el servicio del back para ver la respuesta de la validacion de email
   * @param email 
   * @returns 
   */
  public validatedEmailNeverBounce( email: string){
    const url = `${this.baseUrlForNever}/validate-email-bounce`;
    return this.http.post(url,{'email':email})
  }



  /**
   *  Metodo para validar validar las fotos por medio de apitude y dependiendo del los parametros
   * que se le pase hace una peticion
   * @param data => { type, img, checkId }
   * @param method  => POST | PATCH
   * @returns retorna un observable
   */
  public newValidateApitude( data:any, method:string){
    // Creamos un objecto para mapear cada metodo HTTP 
    const httpMethods: { [key:string]: (data?:any) => Observable<any>} = {
      POST: ( data )=> this.http.post<any>(`${this.baseUrlApitude}`, data),
      PATCH: ( data ) => this.http.patch<any>(`${this.baseUrlApitude}`, data),
    };

    // Usamos el method recibo como clave para obtener el metodo http
    const httpMethod = httpMethods[method];

    if( httpMethod ){
      // Si existe  llamamos a la funcion con los datos recibidos
      return httpMethod(data)
    } else {
      return throwError('Metodo http no soportado!')
    }

  }


  public createPipeline( data:any  ): Observable<any> { 
    const url = `${environment.apiUrl}/create-pipeline`;
    return this.http.post<any>(url, data)
    
  }

  public validateCellphonePrimary( cellphone: string ){
     return this.http.get(`${this.baseUrl}/celular-first-validation/${cellphone}`)
  }

  public validateAdvisor( identification: string ){
    return this.http.get(`${this.baseUrl}/regitro/validar-asesor/${identification}`)
  }





  searchAddressInCity(address: string, city: string) {
    const formattedAddress = `${address}, ${city}, CO`; // Formato de la dirección
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(formattedAddress)}&key=AIzaSyCxatr4i9kn0aT1kpKk676UunHwKaPyxrk`;

    return this.http.get(url);
  }


}
