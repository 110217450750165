import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberMoneyPipe } from './number-money.pipe';
import { ThousandsPipe } from './thousands.pipe';
import { DefaultIfEmptyPipe } from './defaultEmpty.pipe';
import { DateCustomerPipe } from './dateCustomer.pipe';
import { DefaultIfEmptyArrayPipe } from './defaultEmptyArray.pipe';


@NgModule({
  declarations: [
    NumberMoneyPipe,
    ThousandsPipe,
    DefaultIfEmptyPipe,
    DateCustomerPipe,
    DefaultIfEmptyArrayPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumberMoneyPipe,
    ThousandsPipe,
    DefaultIfEmptyPipe,
    DateCustomerPipe,
    DefaultIfEmptyArrayPipe
  ]
})
export class PipesModule { }
