import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/administrative/customers/services/customer-service';

@Component({
  selector: 'app-modal-finantial',
  templateUrl: './modal-finantial.component.html',
  styleUrls: ['./modal-finantial.component.scss']
})
export class ModalFinantialComponent {

  @Input() public customerId: any;

  public obligacionesTotales: any[] = [];
  public scoreTable: any[] = []; 
  public ruesTable: any[] = [];
  public isString: boolean = false;
  public isLoading: boolean = false;  
  public isNullRue: boolean = false;

  constructor(public activeModal: NgbActiveModal, 
    private customerService: CustomerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getInfoCustomerFinantial();
  }

  public getInfoCustomerFinantial() {
    this.isLoading = true;
    this.customerService.getInfoTransunionAndRues(this.customerId)
      .subscribe(
        (response) => {
          this.handleTransunionInfo(response.data.info_transunion);
          this.handleRuesInfo(response.data.info_rues);
          this.isLoading = false;
          
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          
        }
      );
  }

  private extractInformationTransunion(jsonObject: any) {
    // Información Score
    this.scoreTable = [{
      score: jsonObject.Tercero.Score.Puntaje,
      grupo: jsonObject.Tercero.Score.Politica,
      negativeReasonCode: jsonObject.Tercero.Score.IndicadorDefault,
      positiveReasonCode: jsonObject.Tercero.Score.CodigoScore || '',
      subpoblacion: jsonObject.Tercero.Score.SubPoblacion.join(', ') || ''
    }];

    console.log(this.scoreTable);

    // Resumen Endeudamiento
    const resumenPrincipal = jsonObject.Tercero.Consolidado.ResumenPrincipal.Registro;
    this.obligacionesTotales = resumenPrincipal.map((obligacion: any) => ({
      tipo: obligacion.PaqueteInformacion,
      cantidad: obligacion.NumeroObligaciones,
      saldoTotal: obligacion.TotalSaldo,
      cuota: obligacion.CuotaObligacionesDia
    }));
  }


  private extractInformationRues(jsonObject: any) {
    this.ruesTable = [{
      fechaMatricula: jsonObject.fecha_matricula,
      estadoMatricula: jsonObject.estado_matricula,
      fechaRenovacion: jsonObject.fecha_renovacion,
      ultimoAnoRenovacion: jsonObject.ultimo_ano_renovado,
    }]
    console.log(this.ruesTable)
  }


  private handleTransunionInfo(info_transunion: any) {
    const isArray = JSON.parse(info_transunion); 
    if (info_transunion === null || isArray.length === 0) {
      this.isString = true;
      console.log('info_transunion es string');
    } else {
      console.log('info_transunion no es string');
      const jsonObjectTransunion = JSON.parse(info_transunion);
      this.isString = false;
      this.extractInformationTransunion(jsonObjectTransunion);
    }
   
  }
  
  private handleRuesInfo(info_rues: any) {
    const isArray = JSON.parse(info_rues); 
    console.log(isArray);
    if (info_rues === null || isArray.length === 0) {
      this.isNullRue = true;
      console.log('info_rues es nulo');
    } else {
      console.log('info_rues no es nulo');
      const jsonObjectRues = JSON.parse(info_rues);
      this.isNullRue = false;
      this.extractInformationRues(jsonObjectRues);
    }
  }
}
