import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDateFormat' })
export class CustomDateFormatPipe implements PipeTransform {
        transform(value: any): string {

                const months = [
                        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
                        'septiembre', 'octubre', 'noviembre', 'diciembre'
                ];

                let date = value.split('-');
                const index_month = parseInt(date[1]) - 1;

                return  date[2] + " de " + months[index_month] + " " + date[0];
        }
}
