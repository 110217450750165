import { Component, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  CustomerHistorialCredit,
  CustomerHistorialInterface,
} from "src/app/administrative/customers/services/customer-historial-interface";
import { CustomerService } from "src/app/administrative/customers/services/customer-service";
import Swal from "sweetalert2";
import { WithdrawCreditComponent } from "../withdraw-credit/withdraw-credit.component";
import { CommercesService } from "src/app/administrative/commerces/services/commerces.service";
import { CommerceInterface } from "src/app/administrative/commerces/interfaces/commerce-interface";
import { LoaderService } from "src/app/shared/services/Loader.service";
import {
  formatCurrency,
  toggleLoader,
} from "src/app/shared/helpers/functions-helper.service";
import { AuthService } from "src/app/auth/login/services/auth.service";
import { UserInterface } from "src/app/administrative/users/interfaces/users.interface";
import { Router } from "@angular/router";
import { CustomerEditCupoComponent } from "../customer-edit-cupo/customer-edit-cupo.component";

@Component({
  selector: "app-customers-search",
  templateUrl: "./customers-search.component.html",
  styleUrls: ["./customers-search.component.scss"],
})
export class CustomersSearchComponent {
  /*****************
   ***** variables***
   ******************/
  //tomamos referencia del html que tiene modal d ebuscar cliente
  @ViewChild("modalCustomersSearch")
  public modalCustomersSearchContent!: TemplateRef<CustomersSearchComponent>;
  //modal para solicitar retiro de credito
  @ViewChild(WithdrawCreditComponent)
  withdrawCreditModal!: WithdrawCreditComponent;
  private modalRef!: NgbModalRef;
  customer: CustomerHistorialInterface | null;
  creditsHistorial: CustomerHistorialCredit[];
  totalProcessPending: number = 0;
  totalReject: number = 0;
  totalApprovedWithoutDisbursement: number = 0;
  totalActiveCredits: number = 0;
  totalCupoAprobado: number = 0;
  totalCupoDisponibleCompras: number = 0;
  mora: boolean = false;
  shopCommerces: any[] = [];
  closeResult = "";
  flagSearchCustomer = false;
  customerIdentification: string = "";
  shopsCommerces: CommerceInterface[] = []; //sucursales de proveedores
  shopCommerceId: number;
  user: UserInterface = this.authService.getUser();
  roleCanWithdrawCreditAction = false;

  //EDITAR CUPO MAS FACIL

  cupoCustomerId: number;
  cupoApproved: number;
  cupoActived: string;
  cupoCustomerIdentification: string;
  cupoCustomerEmail: string;
  cupoFullName: string;
  cupoNewApproved: number;

  cupoEditButton: boolean = false;
  role = this.authService.getUser().role_id;
  userId: number = JSON.parse(localStorage.getItem("user")!).id;

  /*****************
   ***** constructor***
   ******************/
  constructor(
    private modalService: NgbModal,
    private customerService: CustomerService,
    private shopCommerceService: CommercesService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private router: Router
  ) {}

  //ngOnInit
  ngOnInit(): void {
    this.roleCanWithdrawCredit();
  }

  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value.replace(
      /[^\d]/g,
      ""
    );
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    (event.target as HTMLInputElement).value = formattedValue;
    this.cupoNewApproved = parseFloat(inputValue);
    this.cupoEditButton = this.cupoApproved !== this.cupoNewApproved;
    this.cupoEditButton = inputValue != "";
  }

  /**
   * Verifica si el usuario actual tiene un rol administrativo.
   * Establece la propiedad roleIsAdmin basada en el resultado.
   */
  roleCanWithdrawCredit() {
    this.roleCanWithdrawCreditAction = this.authService.roleCanWithdrawCredit();
  }

  /*****************
   ***** metodos***
   ******************/

  getShopCommerces() {
    toggleLoader(this.loaderService, true);
    this.shopCommerceService.getShopCommercesList().subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        // Verificar si ok es false
        if (!response.ok) {
          Swal.fire(
            "Error",
            "Ocurrió un error al obtener el listado de sucrusales",
            "error"
          );
        } else {
          // Manejar la respuesta exitosa
          this.shopsCommerces = response.data;
          this.inicializateEnviroments();
          this.modalRef = this.modalService.open(
            this.modalCustomersSearchContent,
            {
              size: "lg", // Aquí se establece el tamaño del modal, en este caso, "lg" para grande
            }
          );
          this.modalRef.result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${reason}`;
            }
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        // Manejar el error
        Swal.fire(
          "Error",
          "Ocurrió un error al obtener el listado de sucrusales",
          "error"
        );
        console.error(error);
      }
    );
  }

  openModalCustomerSearch() {
    this.customerIdentification = "";
    this.getShopCommerces();
  }

  //cerrar el modal
  closeModalCustomerSearch() {
    this.modalRef.close();
  }

  //cerrar el modal dando click por fuera de el
  dismissModalCustomerSearch() {
    this.modalRef.dismiss();
  }

  searchCustomer() {
    if (!this.customerIdentification) {
      Swal.fire("Error", "La cédula es obligatoria", "error");
      return;
    }
    toggleLoader(this.loaderService, true);
    this.customerService
      .getCustomerInfoHistorial(this.customerIdentification)
      .subscribe(
        (response) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            console.log(response);
            this.customer = response.data.customer;
            this.creditsHistorial = response.data.credits;
            this.totalProcessPending = response.data.totalProcessPending;
            this.totalReject = response.data.totalReject;
            this.totalApprovedWithoutDisbursement =
              response.data.totalApprovedWithoutDisbursement;
            this.totalActiveCredits = response.data.totalActiveCredits;
            this.totalCupoAprobado = response.data.totalCupoAprobado;
            this.totalCupoDisponibleCompras =
              response.data.totalCupoDisponibleCompras;
            this.mora = response.data.mora;

            this.cupoCustomerId = this.customer.id;
            this.cupoApproved = this.totalCupoAprobado;
            this.cupoActived = this.customer.pagare_signed;
            this.cupoCustomerIdentification = this.customer.identification;
          } else {
            Swal.fire("Error", response.message, "error");
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          console.error(error);
          Swal.fire(
            "Error",
            error.error.message ? error.error.message : error.message,
            "error"
          );
        }
      );
  }

  customerEditCupo(info: any) {
    const modalRef = this.modalService.open(CustomerEditCupoComponent);
    modalRef.componentInstance.cupoCustomerId = info.id;
    modalRef.componentInstance.cupoApproved = info.total_approved_quota;
    modalRef.componentInstance.cupoActived = info.pagare_signed;
    modalRef.componentInstance.cupoCustomerIdentification = info.identification;
    modalRef.componentInstance.cupoCustomerEmail = info.email;
    modalRef.componentInstance.cupoFullName = `${info.name} ${info.last_name}`;
    // Encadenar la promesa para ejecutar searchCustomer después de cerrar el modal
    modalRef.result.then((result) => {
      this.searchCustomer();
    });
  }

  // customerBlockedCupo(info: any) {
  //   toggleLoader(this.loaderService, true);
  //   this.customerService.customerBlockedCupo(info.id).subscribe(
  //     (response) => {
  //       toggleLoader(this.loaderService, false);
  //       if (response) {
  //         console.log(response);
  //       } else {
  //         Swal.fire("Error", response, "error");
  //       }
  //     },
  //     (error) => {
  //       toggleLoader(this.loaderService, false);
  //       console.error(error);
  //       Swal.fire(
  //         "Error",
  //         error.error.message ? error.error.message : error.message,
  //         "error"
  //       );
  //     }
  //   );
  // }

  //inicializar variables
  inicializateEnviroments() {
    this.customer = null;
    this.totalProcessPending = 0;
    this.totalReject = 0;
    this.totalApprovedWithoutDisbursement = 0;
    this.totalActiveCredits = 0;
    this.totalCupoAprobado = 0;
    this.totalCupoDisponibleCompras = 0;
    this.mora = false;
  }

  //metodo para abrir el modal de retiro de credito
  confirmCreditWithdrawal(): void {
    if (typeof this.shopCommerceId === "undefined") {
      Swal.fire(
        "Error",
        "Debe seleccionar una susursal para retirar crédito",
        "error"
      );
      return;
    }

    //mensaje confirmación
    Swal.fire({
      title: "Confirmación retiro de crédito",
      text: `¿Está seguro de realizar el retiro de crédito al cliente ${
        this.customer?.identification
      }, 
            valor disponible compras: ${formatCurrency(
              this.totalCupoDisponibleCompras,
              true
            )}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleLoader(this.loaderService, true);
        this.dismissModalCustomerSearch();
        this.withdrawCreditModal.formWithdrawCredit.reset();
        this.withdrawCreditModal.flagShowCodeInput = false;
        this.withdrawCreditModal.flagConfirmCreditAction = false;
        this.withdrawCreditModal.openModalWithdrawCreditComponent(
          this.totalCupoDisponibleCompras,
          this.shopCommerceId,
          this.customer!
        );
      }
    });
  }

  //ver detalle de pago
  redirectToPaymentDetail(creditId: number) {
    // Cierra el modal actual
    this.dismissModalCustomerSearch();
    // Redirige a la ruta específica con el ID del crédito en la URL
    this.router.navigate(["/creditos/pago-detalle", creditId]);
  }
}
