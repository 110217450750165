import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, tap } from "rxjs";
import { environment } from "src/environments/environment";
import {
	CommerceInterface,
	CommercePaginatedInterface,
	CommerceResponseInterface,
} from "../interfaces/commerce-interface";
import { UserInterface, UserResponseInterface } from "../../users/interfaces/users.interface";
import { CommerceDetailResponseInterface } from "../interfaces/commerce-detail.interface";
import {  ShopListResponseInterface } from "./shop-list.interface";

@Injectable({
	providedIn: "root",
})
export class CommercesService {

    /**
     * Resultado al cerrar el modal.
    */
    closeResult: string;

    /**
     * URL base para realizar peticiones relacionadas con sucursales.
    */
    baseUrl = environment.apiUrl + "/sucursales";

    /**
     * Sujeto observado que se activa para refrescar la tabla
     * después de guardar o actualizar.
    */
    private _refreshShopsCommerces$ = new Subject<void>();


    /**
     * Constructor de la clase.
    */
	constructor(private http: HttpClient) {}


	/**
     * Obtiene el sujeto observado que se activa para refrescar la tabla.
     * @returns {Observable<void>}
     */
    get _refreshShopsCommerces() {
        return this._refreshShopsCommerces$;
    }


	/**
     * Obtiene la lista de tiendas desde el servidor.
     * @returns {Observable<ShopListResponseInterface>} Un observable con la respuesta de la lista de tiendas.
    */
    getShops(): Observable<ShopListResponseInterface> {
        const url = environment.apiUrl + "/proveedores/listado";
		return this.http.get<ShopListResponseInterface>(url);
	}

    /**
     * Obtiene una lista paginada de sucursales comerciales.
     * @param {number} page - El número de página para el cual se desea obtener las sucursales.
     * @returns {Observable<CommercePaginatedInterface>} Un observable que contiene las sucursales comerciales paginadas.
    */
    getShopCommerces(page:number,name: string): Observable<CommercePaginatedInterface> {
        const url = environment.apiUrl + "/sucursales/page?page="+page+"&name="+name;
		return this.http.get<CommercePaginatedInterface>(url);
	}

    /**
     * Obtiene una lista de sucursales comerciales.
     * @param {number} page - El número de página para el cual se desea obtener las sucursales. Actualmente no está siendo utilizado en la función.
     * @returns {Observable<CommerceResponseInterface>} Un observable que contiene la respuesta de las sucursales comerciales.
    */
	getShopCommercesList(): Observable<CommerceResponseInterface> {
		return this.http.get<CommerceResponseInterface>(this.baseUrl);
	}

	/**
     * Almacena una nueva sucursal comercial.
     * @param {CommerceInterface} shopcommerce - La información de la sucursal comercial a guardar.
     * @returns {Observable<CommerceResponseInterface>} Un observable que contiene la respuesta de la operación.
    */
	storeShopCommerce(shopcommerce: CommerceInterface) {
		return this.http
			.post<CommerceResponseInterface>(this.baseUrl, shopcommerce)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	/**
     * Obtiene los detalles de una sucursal comercial específica.
     * @param {number} id - El identificador de la sucursal comercial a consultar.
     * @returns {Observable<CommerceResponseInterface>} Un observable que contiene los detalles de la sucursal.
    */
	showShopCommerce(id: number) {
		return this.http.get<CommerceResponseInterface>(
			`${this.baseUrl}/ver/${id}`
		);
	}

	/**
     * Actualiza los detalles de una sucursal comercial específica.
     * @param {number} id - El identificador de la sucursal comercial a actualizar.
     * @param {CommerceInterface} shopcommerce - Objeto que contiene los detalles actualizados de la sucursal.
     * @returns {Observable<any>} Un observable que indica el resultado de la operación de actualización.
    */
	updateShopCommerce(
		id: number,
		shopcommerce: CommerceInterface
	): Observable<any> {
		return this.http
			.put<CommerceResponseInterface>(
				`${this.baseUrl}/${id}`,
				shopcommerce
			)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	/**
     * Elimina una sucursal comercial basándose en su identificador.
     * @param {number} id - El identificador de la sucursal comercial a eliminar.
     * @returns {Observable<any>} Un observable que indica el resultado de la operación de eliminación.
    */
	deleteShopCommerce(id: number): Observable<any> {
		return this.http
			.delete<CommerceResponseInterface>(`${this.baseUrl}/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	/**
     * Habilita una sucursal comercial basándose en su identificador.
     * @param {number} id - El identificador de la sucursal comercial a habilitar.
     * @returns {Observable<any>} Un observable que indica el resultado de la operación de habilitación.
    */
	enableShopCommerce(id: number): Observable<any> {
		return this.http
			.get<CommerceInterface>(`${this.baseUrl}/habilitar/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	/**
     * Deshabilita una sucursal comercial basándose en su identificador.
     * @param {number} id - El identificador de la sucursal comercial a deshabilitar.
     * @returns {Observable<any>} Un observable que indica el resultado de la operación de deshabilitación.
    */
	disableShopCommerce(id: number): Observable<any> {
		return this.http
			.get<CommerceInterface>(`${this.baseUrl}/desabilitar/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

    /**
     * Registra un usuario para una sucursal comercial específica.
     * @param {number} shopCommerceId - El identificador de la sucursal comercial donde se registrará el usuario.
     * @param {UserInterface} userShopCommerce - La información del usuario que se quiere registrar.
     * @returns {Observable<UserResponseInterface>} Un observable que devuelve la respuesta de la API al registrar el usuario.
    */
	registerUserShopCommerce(shopCommerceId:number,userShopCommerce: UserInterface) {
		return this.http
			.post<UserResponseInterface>(`${this.baseUrl}/guardar-usuario/${shopCommerceId}`, userShopCommerce)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

    /**
     * Obtiene el detalle de una sucursal comercial en particular.
     * @param {number} id - El identificador de la sucursal comercial que se quiere consultar.
     * @returns {Observable<CommerceDetailResponseInterface>} Un observable que devuelve la respuesta de la API con el detalle de la sucursal.
    */
    getDetailCommerce(id: number) {
        return this.http.get<CommerceDetailResponseInterface>(`${this.baseUrl}/ver/${id}`);
    }

	// TODO: SERVICIO PARA VALIDAR SI LA CEDULA EXISTE
	public validateIdentificationUser( identification:number ){
		return this.http.get(`${this.baseUrl}/validar-identificacion/${identification}`)
	}

	public updateUserCommerce( user: any, commerceId:number, userId:number ){
		return this.http.put(`${this.baseUrl}/editar-usuario/${commerceId}/${userId}`, user)
		.pipe(
			tap(() => {
				this._refreshShopsCommerces$.next();
			})
		)

	}

	public updateStateUserCommerce( commerceId:number, userId:number, state: string ){
		return this.http.put(`${this.baseUrl}/editar-state/${commerceId}/${userId}`, {state} ).pipe(
			tap(() => {
				this._refreshShopsCommerces$.next();
			})
		)
	}

	exportUsers(id: number) {
        return this.http.get<CommerceDetailResponseInterface>(`${this.baseUrl}/exportar-usuarios/${id}`);
    }
}
