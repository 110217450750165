<section  *ngIf="totalMonth" class="p-3 shadow-sm rounded section-today-main">
  <div class="card-today-main">
    <div class="card-content-title lh-1">
        <h1 class="fs-4">Ventas del Mes</h1>
        <span class="span-title"> Resumen de ventas del mes </span>
      </div>
      <div class="d-flex flex-wrap ">
        <div class="p-4 card-today pink shadow-sm">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-chart-simple" style="color: #000000"></i>
          </div>
          <h4> {{ totalMonth.current.total_sales | currency : "$" : "symbol" : "1.0-0" }}  </h4>
          <h5>Ventas totales</h5>
          <span>  {{ calculateChange(totalMonth.current.total_sales, totalMonth.previous.total_sales, 'ventas') }} </span>
        </div>
        <div class="p-4 card-today pink shadow-sm">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-box fa-sm" style="color: #000000"></i>
          </div>
          <h4> {{ totalMonth.current.total_orders }} </h4>
          <h5>Total de órdenes</h5>
          <span> {{ calculateChange(totalMonth.current.total_orders, totalMonth.previous.total_orders, 'órdenes') }}</span>
        </div>
        <div class="p-4 card-today pink shadow-sm">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-person" style="color: #222222"></i>
          </div>
          <h4> {{ totalMonth.current.new_customers }} </h4>
          <h5>Nuevos Clientes</h5>
          <span> {{ calculateChange(totalMonth.current.new_customers, totalMonth.previous.new_customers, 'clientes') }} </span>
        </div>
      </div>
  </div>
</section>
