import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import JSConfetti from 'js-confetti';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { PaymentServiceCustomer } from '../../service/payment-service.service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss'],
})
export class PaymentLinkComponent {
  form: FormGroup;
  @Output() data = new EventEmitter<any>();
  jsConfetti = new JSConfetti();

  constructor(
    private formBuilder: FormBuilder,
    private ModalServiceAlert: ModalServiceAlert,
    private paymentService: PaymentServiceCustomer,
    public loaderService: LoaderService
  ) {}

  // Al iniciar la vista, vamos a declarar los atributos del formulario
  ngOnInit() {
    this.form = this.formBuilder.group({
      identificationCustomer: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
    });
  }

  // Petición al servicio para traer datos del usuario
  getIdentification() {
    toggleLoader(this.loaderService, true);
    // Se valida que el numero de documento este lleno
    if (!this.form.value.identificationCustomer) {
      toggleLoader(this.loaderService, false);
      this.ModalServiceAlert.openModal(
        'Alerta!',
        'El número de cédula es obligatorio.!',
        'warning'
      );
      return;
    }

    //Se hace la petición al servicio y se valida que no viene con errores
    this.paymentService
      .getCustomerBydentification(this.form.value.identificationCustomer)
      .subscribe(
        (response: any) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            response.data.customer_whitout_credits
              ? this.succesPay()
              : this.data.emit(response.data);
          } else {
            this.ModalServiceAlert.openModal(
              '¡Alerta!',
              response.message,
              'warning'
            );
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          this.ModalServiceAlert.openModal(
            'Error!',
            error.error.message ||
              'Hubo un problema al conectar con el servidor',
            'error'
          );
        }
      );
  }

  succesPay() {
    this.ModalServiceAlert.openModal(
      '¡Alerta!',
      'No tiene creditos pendientes',
      'success'
    );
    this.jsConfetti.addConfetti();
  }
}
