import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-payment-view3',
  templateUrl: './payment-view3.component.html',
  styleUrls: ['./payment-view3.component.scss']
})
export class PaymentView3Component {
  @Output() eventClickPay = new EventEmitter<string>();
  @Output() eventChooseCreditOldToPay = new EventEmitter<any>();
  @Input() dataUserPayment: any;
  dataList: any = [];

  ngOnInit() {
    if(this.dataUserPayment.type == 1 ) {
      this.dataUserPayment.credits_old.forEach((credit: any) => {
        this.dataList.push({
          img_url: credit.shop_commerce_url_log,
          shop_commerce_name: credit.shop_name,
          total_credit: credit.total_balance,
          delivery_date: credit.delivery_date,
          order_number: credit.order_number,
          credit_delivered: credit.credit_state,
          deadline: credit.deadline,
          type: this.dataUserPayment.type,
          code_pay: credit.code_pay
        });
      });
    }  else if(this.dataUserPayment.type == 2) {
      this.dataUserPayment.credits_new.forEach((credit: any) => {
        this.dataList.push({
          img_url: credit.shop_commerce_url_log,
          shop_commerce_name: credit.shop_name,
          total_credit: credit.total_balance,
          delivery_date: credit.delivery_date,
          order_number: credit.order_number,
          credit_delivered: credit.credit_state,
          deadline: credit.deadline,
          type: this.dataUserPayment.type,
          code_pay: credit.code_pay
        });
      });
    }
  }
  
  chooseCreditOldToPay(credit_choosed: any) {
    this.eventChooseCreditOldToPay.emit(credit_choosed);
  }

  showPrice() {
    if(this.dataUserPayment.type == 1) {
      return this.dataUserPayment.type == 'total' ? this.dataUserPayment.total_payment : this.dataUserPayment.min_payment;
    } else if(this.dataUserPayment.type == 2) {
      if(this.dataUserPayment.payTotMin == 0) {
        return this.dataUserPayment.min_payment;
      } else if(this.dataUserPayment.payTotMin == 1) {
        return this.dataUserPayment.total_payment
      } else {
        return 150000
      }
    } 
  }
  
  
    pay() {
      this.eventClickPay.emit('click');
    }
}
