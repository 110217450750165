import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.scss']
})
export class ListGroupComponent {
  @Input() items: any = [];
  @Input() dateStatus: null | string = null;
  @Input() iconState: boolean = false;
  @Output() eventOption = new EventEmitter<string>();
  @Output() eventChooseCreditOldToPay = new EventEmitter<any>();

  ngOnInit () {
  }

  sendOption(id: string, pay_min_zero: boolean = false) {
        if( !pay_min_zero ) {
                this.eventOption.emit(id);
        }
  }

  chooseCreditToPay(credit_choosed: any, type: number, index:number) {
    if(type == 1) {
        credit_choosed.index = index
        this.eventChooseCreditOldToPay.emit(credit_choosed);
    }
  }
}