import { Component, Input, OnInit } from '@angular/core';
import { CommerceDashboardDataInterface, CommerceDashboardResponseInterface, DataMiniCards, FieldTitles } from '../../interfaces/commerce-dashboard.interface';

@Component({
  selector: 'app-mini-cards-dashboard',
  templateUrl: './mini-cards-dashboard.component.html',
  styleUrls: ['./mini-cards-dashboard.component.scss']
})
export class MiniCardsDashboardComponent  {

  // Mapeo de nombres de campos a titulos deseados
  public fieldTitles: FieldTitles = {
    totalCustomers: 'Total usuarios aprobados',
    withdrawnCredits: 'Total créditos retirados',
    withdrawnCreditsCount: 'Numero de créditos retirados',
    creditsRequestsCount: 'Total usuarios registrados'
  };


  // Colores para ponerselos diferentes a cada div:
  public colorClasses = {
    'withdrawnCredits': 'yellow',
    'totalCustomers': 'purple',
    'creditsRequestsCount': 'pink',
    'withdrawnCreditsCount' : 'blue',
  };
  
  
  @Input() public commerceDashboardCard:DataMiniCards
  
  // ngOnInit(): void {
  //  console.log(this.commerceDashboardCard)
  // }


}
