import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, map, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { CreditPaymentDetailResponseInterface } from "../interfaces/credits-payments-detal";
import { CreditWithArrearPaginatedInterface } from "../interfaces/credits-with-arrears-interface";
import {
  CreditPaginatedInterface,
  CreditResponseInterface,
  QuoteInterface,
  SendCodeOtpResponseInterface,
} from "../interfaces/credits.interface";
import { CommerceDashboardDataInterface } from "../interfaces/credist-more-information.interface";

@Injectable({
  providedIn: "root",
})
export class CreditsService {
  private baseUrl: string = environment.apiUrl + "/creditos";
  private _refreshCredits$ = new Subject<void>();

  //constructor
  constructor(private http: HttpClient) {}



  /*************
   *  getters****
   *************/
  get _refreshCredits() {
    return this._refreshCredits$;
  }

  /*************
   *  metodos****
   *************/

    /**
     * Obtiene los créditos haciendo una solicitud GET a la URL base.
     * @returns Un observable que emite un objeto de tipo CreditPaginatedInterface.
     */
    getCredits() {
        return this.http.get<CreditPaginatedInterface>(this.baseUrl);
    }

    /**
     * Realiza una búsqueda de créditos utilizando los parámetros proporcionados.
     * @param formData Objeto que contiene los parámetros de búsqueda.
     * @returns Un observable que emite un objeto de tipo CreditPaginatedInterface.
    */
    searchCredits(formData: any): Observable<CreditPaginatedInterface> {
        // Convierte los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams({ fromObject: formData });
    
        // Construye la URL completa para la búsqueda.
        const url = `${this.baseUrl}/buscar`;
    
        // Realiza una solicitud GET con los parámetros de búsqueda.
        return this.http.get<CreditPaginatedInterface>(url, { params });
    }

    /**
     * Obtiene información sobre créditos con detalles de morosidad paginados.
     * @param page Número de página para la paginación de resultados.
     * @returns Un observable que emite un objeto de tipo CreditWithArrearPaginatedInterface.
    */
    getCreditsWithArrearsInfo(page: number, formData?: any): Observable<CreditWithArrearPaginatedInterface> {
        // Verifica si formData no se proporciona y asigna un objeto vacío por defecto.
        formData = formData || {};
    
        // Modifica formData para agregar el parámetro 'page'.
        formData.page = page;

       
        // Convierte los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams({ fromObject: formData });
    
        // Construye la URL completa para la búsqueda de créditos con detalles de morosidad.
        let url = `${this.baseUrl}/info-mora`;

       
    
        // Realiza una solicitud GET con los parámetros de búsqueda.
        return this.http.get<CreditWithArrearPaginatedInterface>(url, { params });
    }
    
  
    /**
     * Realiza una búsqueda de créditos con detalles de morosidad utilizando los parámetros proporcionados.
     * @param formData Objeto que contiene los parámetros de búsqueda.
     * @returns Un observable que emite un objeto de tipo CreditWithArrearPaginatedInterface.
     */
    searchCreditsWithArrearsInfo(formData: any, page: number): Observable<CreditWithArrearPaginatedInterface> {
        // Modifica formData para agregar el parámetro 'page'.
        formData.page = page;
    
        // Convierte los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams({ fromObject: formData });
    
        // Construye la URL completa para la búsqueda de créditos con detalles de morosidad.
        const url = `${this.baseUrl}/info-mora/buscar`;
    
        // Realiza una solicitud GET con los parámetros de búsqueda.
        return this.http.get<CreditWithArrearPaginatedInterface>(url, { params });
    }
  
    /**
     * Calcula el valor de la cuota para un crédito específico.
     * @param numberCuotesCredit Número de cuotas del crédito.
     * @param valueCredit Valor del crédito.
     * @param creditLine Línea de crédito.
     * @returns Un observable que emite un objeto de tipo QuoteInterface con el valor de la cuota calculado.
    */
    getQuoteValue(
        numberCuotesCredit: number,
        valueCredit: number,
        creditLine: number
    ): Observable<QuoteInterface> {
        // Construye la URL completa para calcular el valor de la cuota.
        const url = `${this.baseUrl}/calcular-valor-cuota`;
    
        // Crea un objeto HttpParams y agrega los parámetros necesarios.
        let params = new HttpParams();
        params = params.append("numberCuotesCredit", numberCuotesCredit.toString());
        params = params.append("valueCredit", valueCredit.toString());
        params = params.append("creditLine", creditLine.toString());
    
        // Realiza una solicitud GET con los parámetros de cálculo.
        return this.http.get<QuoteInterface>(url, { params });
    }
    

    /**
     * Envía un código OTP (One-Time Password) para confirmar y procesar una transacción de crédito.
     * @param order_number Número de orden asociado a la transacción.
     * @param numberCuotesCredit Número de cuotas del crédito.
     * @param valueCredit Valor total del crédito.
     * @param valueCuote Valor de cada cuota.
     * @param customerId Identificación del cliente.
     * @param shopCommerceId Identificación del comercio.
     * @param creditLine Línea de crédito utilizada.
     * @returns Un observable que emite un objeto de tipo SendCodeOtpResponseInterface con la respuesta del servicio.
    */
    sendCodeOtp(
        order_number: string,
        numberCuotesCredit: number,
        valueCredit: number,
        valueCuote: number,
        customerId: number,
        shopCommerceId: number,
        creditLine: number
    ): Observable<SendCodeOtpResponseInterface> {
        // Construye la URL completa para enviar el código OTP.
        const url = `${this.baseUrl}/enviar-codigo-opt`;
    
        // Crea un objeto HttpParams y agrega los parámetros necesarios.
        let params = new HttpParams();
        params = params.append("order_number", order_number.toString());
        params = params.append("numberCuotesCredit", numberCuotesCredit.toString());
        params = params.append("valueCredit", valueCredit.toString());
        params = params.append("valueCuote", valueCuote.toString());
        params = params.append("customerId", customerId.toString());
        params = params.append("shopCommerceId", shopCommerceId.toString());
        params = params.append("creditLine", creditLine.toString());
    
        // Realiza una solicitud GET con los parámetros para enviar el código OTP.
        return this.http.get<SendCodeOtpResponseInterface>(url, { params });
    }
  

    /**
     * Valida un código OTP (One-Time Password) para confirmar una transacción de crédito.
     * @param code Código OTP ingresado por el usuario.
     * @param creditId Identificación única del crédito asociado al código OTP.
     * @returns Un observable que emite un objeto de tipo SendCodeOtpResponseInterface con la respuesta de la validación.
    */
    validateCodeOtp(code: number, creditId: number): Observable<SendCodeOtpResponseInterface> {
        // Construye la URL completa para validar el código OTP.
        const url = `${this.baseUrl}/validar-codigo-opt`;
    
        // Realiza una solicitud POST con el código y la identificación del crédito.
        return this.http.post<SendCodeOtpResponseInterface>(url, { code, creditId });
    }
  

    /**
     * Aplica un crédito identificado por su ID, completando el proceso de solicitud.
     * @param creditId Identificación única del crédito a aplicar.
     * @returns Un observable que emite un objeto de tipo SendCodeOtpResponseInterface con la respuesta del proceso de aplicación.
    */
    applyCredit(creditId: number): Observable<SendCodeOtpResponseInterface> {
        // Construye la URL completa para aplicar el crédito.
        const url = `${this.baseUrl}`;
    
        // Realiza una solicitud POST con la identificación del crédito.
        return this.http.post<SendCodeOtpResponseInterface>(url, { creditId }).pipe(
        // Realiza una operación adicional después de la solicitud (en este caso, notifica la actualización de créditos).
        tap(() => {
            this._refreshCredits.next();
        })
        );
    }
  
    /**
     * Actualiza la información de un crédito identificado por su ID.
     * @param creditId Identificación única del crédito a actualizar.
     * @param data Datos actualizados del crédito.
     * @returns Un observable que emite un objeto de tipo CreditResponseInterface con la respuesta de la actualización.
    */
    updateCredit(creditId: number, data: any): Observable<CreditResponseInterface> {
        // Construye la URL completa para actualizar el crédito.
        const url = `${this.baseUrl}/${creditId}`;
    
        // Realiza una solicitud PUT con la identificación del crédito y los datos actualizados.
        return this.http.put<CreditResponseInterface>(url, data).pipe(
        // Realiza una operación adicional después de la solicitud (en este caso, notifica la actualización de créditos).
        tap(() => {
            this._refreshCredits.next();
        })
        );
    }
  
    /**
     * Obtiene los detalles de pago de un crédito identificado por su ID.
     * @param creditId Identificación única del crédito.
     * @returns Un observable que emite un objeto de tipo CreditPaymentDetailResponseInterface con los detalles de pago.
    */
    getDetailPaymentCredit(creditId: number): Observable<CreditPaymentDetailResponseInterface> {
        // Construye la URL completa para obtener los detalles de pago del crédito.
        const url = `${this.baseUrl}/detalle-pago/${creditId}`;
    
        // Realiza una solicitud GET con la identificación del crédito.
        return this.http.get<CreditPaymentDetailResponseInterface>(url);
    }
  
    /**
     * Registra el pago de cuotas de un crédito mediante un plan de pagos.
     * @param creditId Identificación única del crédito.
     * @param date Fecha del pago de cuotas.
     * @param payValue Monto del pago.
     * @returns Un observable que emite un objeto de tipo CreditResponseInterface con la respuesta del registro de pago.
    */
    storeCreditPaymentByQuotes(creditId: number, date: Date, payValue: number, comment: string): Observable<CreditResponseInterface> {
        // Construye la URL completa para registrar el pago de cuotas mediante un plan.
        const url = `${this.baseUrl}/pago-cuotas/plan`;
    
        // Crea el cuerpo de la solicitud con la información necesaria.
        const body = {
        creditId: creditId,
        paymentAmount: payValue,
        datePayment: date,
        comment: comment,
        };
    
        // Realiza una solicitud POST con el cuerpo de la solicitud.
        return this.http.post<CreditResponseInterface>(url, body);
    }
  
    /**
     * Registra el pago de cuotas de un crédito utilizando un monto específico.
     * @param creditId Identificación única del crédito.
     * @param payValue Monto del pago.
     * @param date Fecha del pago de cuotas.
     * @returns Un observable que emite un objeto de tipo CreditResponseInterface con la respuesta del registro de pago.
    */
    storeCreditPaymentByValue(creditId: number, payValue: number, date: Date): Observable<CreditResponseInterface> {
        // Construye la URL completa para registrar el pago de cuotas utilizando un monto específico.
        const url = `${this.baseUrl}/pago-cuotas/valor`;
    
        // Crea el cuerpo de la solicitud con la información necesaria.
        const body = {
        creditId: creditId,
        paymentAmount: payValue,
        datePayment: date,
        };
    
        // Realiza una solicitud POST con el cuerpo de la solicitud.
        return this.http.post<CreditResponseInterface>(url, body);
    }
  
    /**
     * Realiza una búsqueda de créditos con detalles de morosidad para exportar, utilizando los parámetros proporcionados.
     * @param formData Objeto que contiene los parámetros de búsqueda.
     * @returns Un observable que emite un objeto de tipo any con la información de los créditos para exportar.
    */
    searchCreditsForExport(formData: any): Observable<any> {
        // Convierte los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams({ fromObject: formData });
    
        // Construye la URL completa para la búsqueda de créditos con detalles de morosidad.
        const url = `${this.baseUrl}/info-mora/buscar`;
        
        // Realiza una solicitud GET con los parámetros de búsqueda.
        return this.http.get<any>(url, { params });
    }


    searchCreditsForExportTransunion(date:string) {
        // Construye los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams().set('date', date);

        // Construye la URL completa para la búsqueda de créditos con detalles de morosidad.
        const url = `${this.baseUrl}/transunion/reporte`;

        // Realiza una solicitud GET con los parámetros de búsqueda.
        return this.http.get<any>(url, { params });
    }
    
    /**
     * Calcula la mora para un crédito en una fecha específica.
     * @param creditId Identificación única del crédito.
     * @param date Fecha para la cual se desea calcular la mora (formato: 'YYYY-MM-DD').
     * @returns Un observable que emite un objeto de tipo any con el resultado del cálculo de mora.
    */
    calculateMoraWithDate(creditId: number, date: string) {
        const params = new HttpParams().set("creditId", creditId).set("date", date);

        const url = `${this.baseUrl}/calcular/cuota`;
        return this.http.get<any>(url, { params });
    }

    /**
     * Realiza un pago forzado para un crédito específico en una fecha determinada.
     * @param creditId Identificación única del crédito.
     * @param date Fecha en la que se realizará el pago (objeto Date).
     * @param payValue Monto del pago a forzar.
     * @returns Un observable que emite un objeto de tipo CreditResponseInterface con la respuesta del pago forzado.
    */
    forcePayment(creditId: number, date: Date, payValue: number) {
        const url = `${this.baseUrl}/forzar/pago`;
        const body = {
        creditId: creditId,
        value: payValue,
        date: date,
        };
        return this.http.post<CreditResponseInterface>(url, body);
    }

    // cancelCredit(creditId: number) {
    //   const url = `${this.baseUrl}/cancelar/${creditId}`;
    //   return this.http.put<CreditResponseInterface>(url, {});
    // }

    /**
     * Cancela un crédito identificado por su ID.
     * @param creditIdDelete Identificación única del crédito a cancelar.
     * @returns Un observable que emite un evento de actualización después de la cancelación.
    */
    cancelCredit(creditIdDelete: number) {
        const url = `${this.baseUrl}/cancelar/${creditIdDelete}`;
    
        const headers = {
        "Content-Type": "application/json",
        };
    
        const options = {
        headers: new HttpHeaders(headers), // Coloca las cabeceras en las opciones
        };
    
        return this.http.put(url, {}, options).pipe(
        tap(() => {
            this._refreshCredits.next(); // Emitir evento de actualización
        })
        );
    }
  
    /**
     * Descarga el recibo de pago generado para una transacción de pago específica.
     * @param payment Información de la transacción de pago para generar el recibo.
     * @returns Un observable que emite un objeto Blob representando el recibo de pago.
    */
    downloadGenerateReceiptPay(payment: any): Observable<Blob> {
        const url = `${this.baseUrl}/recibo-pago`;

        const options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
        responseType: "blob" as "json", // Indicar que la respuesta es un blob (archivo binario)
        };

        return this.http.post<Blob>(url, payment, options);
    }

    getMoreInformation(){
        const url  = `${environment.apiUrl}/comercio/reportes/inicio`
    }

    importCreditsDeliveryDate(file: File): Observable<any> {
        const url = `${this.baseUrl}/importar/fecha-entrega`;
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(url, formData);
    }

    exportReportBancolombia() {
        return this.http.get(this.baseUrl + '/exporte/recaudo/bancolombia')
    }

    saveRegisterAudit() {
        return this.http.get(environment.apiUrl + '/register-audit/consultation/credit');
    }

    verifyOrderNumberNotExists(orderNumber: string) {
        const url = `${this.baseUrl}/verificar-orden?order_number=${orderNumber}`;
        return this.http.get(url);
    }
}
