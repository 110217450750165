import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-dashboard',
  templateUrl: './skeleton-dashboard.component.html',
  styleUrls: ['./skeleton-dashboard.component.scss']
})
export class SkeletonDashboardComponent {

  @Input() public isLoading: boolean = false;


}
