
<div *ngIf="dataOfCity.data.length > 0; else noSales"  >
    <canvas id="chart"> {{ chart }} </canvas>
</div>
<ng-template #noSales>
    <div class="no-sales-message">
      <div class="icon-container">
        <i class="fa fa-chart-bar"></i>
      </div>
      <h2>Todavía no tiene ventas en ciudades.</h2>

    </div>
  </ng-template>