<div class="contenido m-0 p-0">
    <img [ngClass]="stateImg ? '' : 'hidden-item'"   src="/assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    <div class="imgen">
        <span (click)="backButon()" class="cursor">
            <i class="fa-solid fa-circle-arrow-left"></i>
        </span>
    </div>
    <div class="card">
        <div class="body p-3">
            <i class="fa-solid text-primaryy fa-circle-user me-3 {{smallIconUser?'': 'small-icon'}} "></i> 
            <p class="pt-3 text-primaryy"> {{ title }} </p>
        </div>
    </div>
</div>