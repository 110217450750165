<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex p-4 align-items-center">
        <div class="d-flex flex-column contenido">
                <header>
                        <h2 class="fs-2 m-0 pb-2">Paga tu crédito</h2>
                        <p class="fs-6 m-0 pb-3">
                                ¡Paga tus cuotas en línea ingresando tu
                                <span>documento</span> de identidad. <br />
                                ¡Es <span>rápido</span>, <span>fácil </span> y
                                <span>seguro!</span>!
                        </p>
                </header>

                <form (ngSubmit)="getIdentification()" [formGroup]="form">
                        <div class="d-flex flex-column gap-2">
                                <app-inpit-document formControlName="identificationCustomer"></app-inpit-document>
                                <div class="d-flex justify-content-end">
                                        <span>
                                                <a class="link" href="https://wa.link/jk2a0n" target="_blank"
                                                        rel="noopener noreferrer">¿Necesitas ayuda?</a>
                                        </span>
                                </div>
                                <button class="btn-payment" type="submit">Buscar</button>
                        </div>
                </form>

                <div class="d-flex align-items-center gap-3">
                        <div class="line"></div>
                        O
                        <div class="line"></div>
                </div>
                <div class="d-flex flex-column gap-2 w-100">
                        <a [routerLink]="'/tu-credito'" class="btn-register" type="submit">Registrarme</a>
                        <a [routerLink]="'/iniciar-sesion'" class="btn-pay" type="submit">Iniciar Sesión</a>
                </div>
        </div>
        <!-- Terminos y condiciones -->
        <div class="d-flex justify-content-between gap-3 p-4 pb-md-0 terms-conditions">
                <span><a class="link-terms" href="https://somosziro.com/terminos-y-condiciones/" target="_blank"
                                rel="noopener noreferrer">Términos y Condiciones</a></span>
                <span><a class="link-terms" href="https://somosziro.com/" target="_blank" rel="noopener noreferrer">©
                                somosziro</a></span>
        </div>
</div>