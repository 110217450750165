import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { LoaderService } from '../services/Loader.service';
import Swal from 'sweetalert2';
import { ErrorResponse } from '../interfaces/response-default.interface';

export function numberDecimalValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Permitir campo vacío si es opcional
    }

    const isValid = /^\d+(\.\d{1,2})?$/.test(value);
    return isValid ? null : { numberDecimal: true };
  };
}

export function checkDateRangeValidator(
  startDateKey: string,
  endDateKey: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get(startDateKey)?.value;
    const endDate = control.get(endDateKey)?.value;

    if (endDate && startDate && endDate < startDate) {
      return { dateRangeError: true };
    }

    return null;
  };
}

export function differentValuesValidator(
  control1Name: string,
  control2Name: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(control1Name);
    const control2 = control.get(control2Name);

    if (!control1 || !control2) {
      return null; // No validation if either control is not found
    }

    const control1Value = control1.value;
    const control2Value = control2.value;

    if (control1Value !== control2Value) {
      return null; // Values are different, no error
    }

    return { differentValues: true }; // Values are the same, return an error
  };
}

export function phone2Validator(
  control: AbstractControl
): ValidationErrors | null {
  const phone2Value = control.value as string;

  if (phone2Value) {
    if (phone2Value.length < 7) {
      return {
        minlength: {
          requiredLength: 7,
          actualLength: phone2Value.length,
        },
      };
    }

    if (phone2Value.length > 11) {
      return {
        maxlength: {
          requiredLength: 11,
          actualLength: phone2Value.length,
        },
      };
    }

    if (!numericValidator()(control)) {
      return { invalidPhone2: true };
    }
  }

  return null;
}

// valida que los campos sean numericos
export function numericValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isNumeric =
      !isNaN(parseFloat(control.value)) && isFinite(control.value);
    return isNumeric ? null : { nonNumeric: { value: control.value } };
  };
}

export function onlyNumbers(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasNonNumeric = new RegExp('[^0-9]').test(value);
    return hasNonNumeric ? { nonNumeric: true } : null;
  };
}

export function removeNonNumeric(control: AbstractControl) {
  if (!control || !control.value) {
    return;
  }

  const newValue = control.value.replace(/[^0-9]/g, '');
  control.setValue(newValue);
}

export function validateCampo(form: FormGroup, campo: string) {
  const control = form.controls[campo];
  if (control.errors && control.touched) {
    const errors: ValidationErrors | null = control.errors;
    if (errors && errors['required']) {
      return 'El campo es requerido';
    } else if (errors && errors['minlength']) {
      return `El campo debe tener al menos ${errors['minlength'].requiredLength} caracteres`;
    } else if (errors && errors['maxlength']) {
      return `El campo debe tener como máximo ${errors['maxlength'].requiredLength} caracteres`;
    } else if (errors && errors['numberDecimal']) {
      return 'El campo debe ser un número decimal válido';
    } else if (errors && errors['dateRangeError']) {
      return 'La fecha de inicio debe ser menor a la fecha de fin';
    } else if (errors && errors['differentValues']) {
      return 'Los valores no pueden ser iguales';
    } else if (errors && errors['email']) {
      return 'El campo debe ser un correo electrónico válido';
    } else if (errors && errors['nonNumeric']) {
      return 'El campo debe contener solo números';
    } else if ( errors && errors['minAge']) {
      return 'Por favor ingrese una fecha válida';
    } else if (errors && errors['invalidFormat']) { // Agregar esta línea
      return 'Debe iniciar con un numero';
    }   
    // return 'Error en el campo';
  }

  return null;
}

export function numberFollowedByLetterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      // Verifica si el valor comienza con un número seguido de letras opcionalmente
      const isValid = /^[0-9]+[a-zA-Z]*$/.test(value);
      return isValid ? null : { 'invalidFormat': { value: control.value } };
  };
}

export function formatDatePickerField(field: any): string {
  const date: NgbDateStruct = field.value;
  if (date) {
    const formattedDate = `${date.year}-${date.month
      .toString()
      .padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
    return formattedDate;
  }
  return '';
}

export function convertToNgbDate(date: string): NgbDateStruct {
  // Divide la fecha en sus componentes (año, mes, día)
  const [year, month, day] = date.split('-');

  // Crea una instancia de NgbDateStruct con los componentes de fecha
  const ngbDate: NgbDateStruct = { year: +year, month: +month, day: +day };

  return ngbDate;
}

export function getStateRequestDescription(stateId: number) {
  switch (stateId) {
    case 0 | 1:
      return 'Solicitud';
    case 2:
      return 'Estudio';
    case 3:
      return 'Aprobado';
    case 4:
      return 'Rechazado';
    case 5 | 7:
      return 'Aprobado con desembolso';
    case 6:
      return 'Anulado';
    default:
      return '---';
  }
}

export function getStateRequestColor(stateId: number) {
  switch (stateId) {
    case 0 | 1:
      return 'warning';
    case 2:
      return 'info';
    case 3:
      return 'success';
    case 4:
      return 'danger';
    case 5 | 7:
      return 'primary';
    case 6:
      return 'danger';
    default:
      return 'danger';
  }
}

export function getStateCodeCustomer(code: number) {
  switch (code) {
    case 1:
      return 'validado';
    case 2:
      return 'Código expirado';
    case 0:
    default:
      return 'Sin validar';
  }
}

export function getStateCodeColorCustomer(state: string) {
  switch (state) {
    case 'Confirmado':
      return 'success';
    case 'Pendiente':
      return 'warning';
    case 'Expirado':
      return 'danger';
    default:
      return 'danger';
  }
}

export function toggleLoader(
  loaderService: LoaderService,
  value: boolean,
  message?: string
) {
  loaderService.isLoading$.next(value);
  const defaultMessage = 'Cargando...';
  loaderService.message$.next(message || defaultMessage);
}

function getValueByPath(obj: any, path: string): any {
    return path
      .split('.')
      .reduce((accum: any, key: any) => (accum ? accum[key] : null), obj);
  }
  
  export function exportToExcel(
    data: any[],
    fileName: string,
    sheetName: string,
    columns: { header: string; key?: string; value?: any }[],
    adjustToContent: boolean = false
  ) {
    const getValue = (item: any, column: { key?: string; value?: any }) => {
      if (column.key) {
        return getValueByPath(item, column.key);
      } else if (column.value !== undefined && column.value !== null) {
        return column.value;
      }
      return null;
    };
  
    const filteredData = data.map((item) => {
      const filteredItem: any[] = [];
      columns.forEach((column) => {
        const value = getValue(item, column);
        filteredItem.push(value);
      });
      return filteredItem;
    });
  
    const worksheetData = [
      columns.map((column) => column.header),
      ...filteredData,
    ];
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    worksheet['!cols'] = columns.map((column, index) => ({
      wch: column.header.length,
    }));
    if (adjustToContent) {
      const MAX_COLUMN_WIDTH = 50;
      const MIN_COLUMN_WIDTH = 7.5;
    
      if (filteredData.length > 0) {
        const columnWidths = filteredData[0].map(() => MIN_COLUMN_WIDTH);
    
        filteredData.forEach(row => {
          row.forEach((cellValue: any, colIndex: number) => {
            const cellLength = cellValue ? cellValue.toString().length : 0;
            columnWidths[colIndex] = Math.max(Math.min(cellLength, MAX_COLUMN_WIDTH), columnWidths[colIndex]);
          });
        });
    
        worksheet['!cols'] = columnWidths.map(width => ({ wch: width }));
      }
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  }
  
export function getCurrentTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

export function formatCurrency(
  value: number | null | undefined,
  showSymbol: boolean = true
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const formattedValue = value
    .toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    })
    .replace(/\./g, ',');

  return showSymbol ? `$${formattedValue}` : formattedValue;
}

export function formatCurrencyInput(
  form: FormGroup,
  fieldName: string,
  event: Event,
  showSymbol: boolean
): void {
  const inputElement = event.target as HTMLInputElement;
  const numericValue = inputElement.value.replace(/[^0-9]/g, '');
  const formattedValue = formatCurrency(Number(numericValue), showSymbol);

  // Actualizar el campo especificado en el formulario
  form.patchValue({ [fieldName]: formattedValue });
}

export function getNumericOnly(value: string): number {
  const numericValue = value.replace(/\D/g, '');
  return Number(numericValue);
}

export function getInitials(name: string, lastName: string) {
  return name[0] + lastName[0];
}

export function handleErrorResponseOk(response:any, messageError = "Error al realizar la solicitud") {
    const message = Array.isArray(response.message) 
        ? response.message.join("<br>") 
        : response.message || messageError;

    Swal.fire(
        "Alerta",
        message,
        "warning"
    );
}

export function handleErrorResponse(error:ErrorResponse, defaultErrorMessage = "Error al realizar la solicitud") {
    const errorMessage = error.error?.message;

    let message;
    if (Array.isArray(errorMessage)) {
        message = errorMessage.join("<br>");
    } else {
        message = errorMessage || defaultErrorMessage;
    }

    Swal.fire("Alerta", message, "warning");
}

export function truncateText(text: string): string {
  const MAX_LENGTH = 32767;
  return text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH) : text;
}

export function generateMultiplesViewExcel(
  sheets: { sheetName: string, data: any[], columns: { header: string, key: string }[] }[],
  fileName: string
) {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  sheets.forEach(sheet => {
    const sheetData = sheet.data.map((item: any) => {
      const formattedItem: any = {};
      sheet.columns.forEach(column => {
        formattedItem[column.header] = item[column.key];
      });
      return formattedItem;
    });
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    
    // Aplicar los colores de fila
    sheet.data.forEach((item: any, index: number) => {
      const rowIndex = index + 2; // +2 porque los datos comienzan en la fila 2 (1-indexed)
      if (item.rowColor) {
        sheet.columns.forEach((column, colIndex) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
          if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: 's', v: '' };
          if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
          worksheet[cellAddress].s.fill = {
            patternType: 'solid',
            fgColor: { rgb: item.rowColor.replace('#', '') }
          };
        });
      }
    });

    // Ajustar el ancho de las columnas
    const colWidths = sheet.columns.map(col => ({ wch: Math.max(col.header.length + 2, 15) }));
    worksheet['!cols'] = colWidths;

    XLSX.utils.book_append_sheet(wb, worksheet, sheet.sheetName);
  });

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function convertFileToBase64( file: File ): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = ( reader.result as String).split(',')[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  })
}


