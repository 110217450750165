<div class="notifications-container mb-2">
    <div class="alert">
      <div class="flex">
        <div class="flex-shrink-0">
            <i class="fa-solid fa-circle-info" style="color: #1FE679;;"></i>
        </div>
        <div class="alert-prompt-wrap">
          <p class="text-sm text-yellow-700 mr-2">
             <!-- {{ infoError }} -->
             {{ infoTextError  }} 
          </p>
      </div>
    </div>
    </div>
</div>