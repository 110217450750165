import { Component, Input } from '@angular/core';
import { TotalMonthInterface } from '../../interfaces/commerce-dashboard.interface';
import { CommerceDashboardService } from '../../services/commerce-dashboard.service';

@Component({
  selector: 'app-month-sales-dashboard',
  templateUrl: './month-sales-dashboard.component.html',
  styleUrls: ['./month-sales-dashboard.component.scss']
})
export class MonthSalesDashboardComponent {

  // TODO: @Input
  @Input() public totalMonth: TotalMonthInterface


  constructor( private commerceDashboardService: CommerceDashboardService ){}


    /**
   * Este metodo nos sirve para 
   * calcular el porcentaje depeniendo de las ventas actuales y anteriores
   * @param currentValue : Valor actual ( dia )
   * @param previousValue : Valor anterior ( dia )
   * @param type : Valor tipo si es cliente o ventas o pedidos
   * @returns { returns } : Retorna el mensaje dependiendo el tipo 
   */
    public calculateChange(currentValue: number, previousValue: number, type: string): string{

      return this.commerceDashboardService.calculateChange(currentValue, previousValue, type, 'mes')
      
    }

}
