<app-breadcrumb
  [title]="'Inicio'"
  [items]="['Dashboard']"
  [active_item]="'Default'"
></app-breadcrumb>
<div class="container-fluid default-dash container-dashboard-main">
  <!-- Section de cards  ⭣ -->
  <app-skeleton-dashboard [isLoading]="isLoading" ></app-skeleton-dashboard>
  

  <section class="d-flex flex-wrap p-2 justify-content-around">
    <app-mini-cards-dashboard class="d-flex flex-wrap justify-content-around cards" [commerceDashboardCard]="dataOfminiCards" ></app-mini-cards-dashboard>
  </section>

  <section class="mt-5 section-ventas d-flex flex-wrap justify-content-around">
    <!-- Section total ventas:  ⭣ -->
    <article class="content-ventas-test">
      <app-today-sales-dashboard
        [totalDay]="dataOftotalDay"
      ></app-today-sales-dashboard>
      <app-month-sales-dashboard
        [totalMonth]="dataOftotalMonth"
        class="content-ventas-mes"
      ></app-month-sales-dashboard>
    </article>

    <!-- Section de grafico -->
    <article class="article-grafic" >
      <!-- Componente de cupos -->
      <app-coupon-dashboard [dataCoupon]="dataOfCoupon"  [date]="date"></app-coupon-dashboard>
      
      <div class="chart-container"   >
        
        <app-spinner-components [state]="stateGraphic" ></app-spinner-components>
        

        <app-graphic-dashboard  *ngIf="stateGraphic" [dataOfCity]="dataSendCity" ></app-graphic-dashboard>

        <div class="content-buttons" *ngIf="stateGraphic">
          <button
            class="btn btn-primary shadow-sm btn-ziro fw-bold me-2"
            *ngIf="currentPageCity > 1"
            (click)="changeCitysBypagination(currentPageCity - 1)"
          >
          <i class="fa-solid fa-caret-left " style="color: #000000;"></i> Anterior
          </button>
          <button
            class="btn btn-primary shadow-sm btn-ziro  fw-bold me-2"
            *ngIf="currentPageCity < limitCity"
            (click)="changeCitysBypagination(currentPageCity + 1)"
          >
            Siguiente <i class=" fa-solid fa-caret-right " style="color: #000000;"></i>
          </button>
        </div>
      </div>

      <!-- <ng-template #noSales>
        <div class="no-sales-message">
          <h2>Todavía no tiene ventas en ciudades.</h2>
        </div>
      </ng-template> -->
    </article>

  </section>

</div>
