<div class="container-skeleton container-fluid" *ngIf="isLoading">
  <div class="d-flex flex-wrap justify-content-around">
    <div class="item-prueba" *ngFor="let item of [1, 2, 3, 4]">
      <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
      <div>
        <ngx-skeleton-loader count="2" appearance="line" [theme]="{ width: '200px', height: '20px' }" animation="progress-dark" ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

    <div class="mt-20 d-flex content-ventas flex-wrap">
      <div  >
        <div class="items-ventas">
          <ngx-skeleton-loader  count="1" [theme]="{ 'border-radius': '0', height: '10px', width: '100px' }" ></ngx-skeleton-loader>
          <ngx-skeleton-loader class="item-br" count="3" appearance="circle" [theme]="{ width: '200px', height: '180px', 'border-radius': '10px'}"></ngx-skeleton-loader>
       
        </div>
  
        <div class="items-ventas">
          <ngx-skeleton-loader  count="1" [theme]="{ 'border-radius': '0', height: '10px', width: '100px' }" ></ngx-skeleton-loader>
          <ngx-skeleton-loader class="item-br" count="3" appearance="circle" [theme]="{ width: '200px', height: '180px', 'border-radius': '10px'}"></ngx-skeleton-loader>
        </div>
      </div>

    <div >
      <div>
        <div class="d-flex flex-wrap justify-content-around">
          <div class="item-prueba" *ngFor="let item of [1, 2]">
            <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
            <div>
              <ngx-skeleton-loader count="2" appearance="line" [theme]="{ width: '200px', height: '20px' }" animation="progress-dark" ></ngx-skeleton-loader>
            </div>
          </div>
        </div>

        <div>
          <ngx-skeleton-loader count="2" appearance="line" [theme]="{ width: '300px', height: '320px' }" animation="progress-dark" ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>


</div>