<div class="cardd p-4">
        <div class="body bg-ziro p-4">
                <p class="text-light fs-6">¿Necesitas ayuda?</p>
                <span class="text-light">Comunícate con uno de nuestros asesores</span>
                <div class="group-buttons">
                        <a href="https://wa.me/3142051091" target="_blank">
                                <button class="btn btn-light">
                                        <img class="logo-whatsapp"
                                                src="https://zirotemplates.s3.sa-east-1.amazonaws.com/images/wp-color.png"
                                                alt="phone">
                                        Escríbenos
                                </button>
                        </a>
                        <a href="tel:+573336025709" target="_blank">
                                <button class="btn btn-light">
                                        <img class="tel-verde"
                                                src="https://zirotemplates.s3.sa-east-1.amazonaws.com/images/tel-verde.png"
                                                alt="logo_whatsapp">
                                        LLámanos
                                </button>
                        </a>
                </div>
        </div>
</div>