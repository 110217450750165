<div class="one_page">
  <div class="one_page_content">
    <div class="img_figure">
      <img src="../../../../assets/img-news/figuras.svg" alt="Figuras" />
    </div>
    <div class="welcome">
      <div class="welcome-info">
        <h1>Bienvenid&#64;s a</h1>
        <img
          src="../../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
        <h2>¡Ups!</h2>
        <p class="text-center fs-6 p-0 fw-semibold lh-sm">
          Estamos en plena actualización para ofrecerte un mejor servicio. Te
          invitamos a volver a intentar el Martes 24 de septiembre. ¡Agradecemos
          tu comprensión y paciencia!
        </p>
      </div>
    </div>
    <div class="ilustration">
      <img
        src="../../../../assets/img-news/Ilustracion.svg"
        alt="Ilustración"
      />
    </div>
  </div>
</div>
