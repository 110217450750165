<div *ngIf="dataCoupon" class=" card-grafic-content">
  <div class="card-grafic shadow-sm">
    <div class="ball d-flex justify-content-center align-items-center">
      <i class="fa-solid fa-money-bill fa-lg" style="color: #ffffff;"></i>
    </div>
    <div class="mt-2">
      <h4>
        Total cupo aprobado <br>
        <span class="price-span"> {{ converterNumber( dataCoupon.shop_total_approved_quota ) | currency : "$" : "symbol" : "1.0-0"  }}  </span>
      </h4>
    </div>
  </div>

  <div class="card-grafic shadow-sm">
    <div class="ball color-two d-flex justify-content-center align-items-center">
      <i class="fa-solid fa-money-bill fa-lg" style="color: #ffffff;"></i>
    </div>
    <div class="mt-2">
      <h4>
        Total cupo disponible <br>
        <span class="price-span"> {{ converterNumber(dataCoupon.shop_total_available_quota ) | currency : "$" : "symbol" : "1.0-0"  }}  </span>
      </h4>
    </div>
  </div>



</div>


<!-- <div class="card-grafic shadow-sm">
  <h3 class="d-flex"> <div class="span-ball  color-ball-blue d-inline-flex justify-content-center align-items-center"> <i class="fa-solid fa-money-bill fa-2xs" style="color: #ffffff;"></i> </div> Total cupo aprobado </h3>
  <span class="d-block">  ${{ dataCoupon.shop_total_approved_quota}}  </span>
</div>

<div class="card-grafic shadow-sm">
  <h3 class="d-flex align-items-center "> <div class="span-ball color-ball-yellow  d-inline-flex justify-content-center align-items-center "> <i class="fa-solid fa-money-bill fa-2xs " style="color: #ffffff;"></i> </div> Total cupo disponible </h3>
  <span class="d-block"> ${{ dataCoupon.shop_total_available_quota }} </span>
</div> -->