import { Component, Renderer2 } from "@angular/core";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";
import { PaymentServiceCustomer } from "../service/payment-service.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";

@Component({
        selector: "app-credit-payment",
        templateUrl: "./credit-payment.component.html",
        styleUrls: ["./credit-payment.component.scss"],
})
export class CreditPaymentNewComponent {
        dataAboutCustomer: any;
        currentViewNumber: number = 1;            //Indica el index de la vista en la que me encuentro acutalmente
        choosedCreditOld: any = {};
        index_path: any = [1];                               //Almacenar el orden de los index por los que anda
        total_data_type3: any = null;
        isTablet: boolean = false;


        constructor(private paymentService: PaymentServiceCustomer, private ModalServiceAlert: ModalServiceAlert, private renderer: Renderer2, private loaderService: LoaderService) { }

        ngOnInit() {
                if ('ontouchstart' in window || navigator.maxTouchPoints) {
                        if (window.innerWidth > 768) {
                                this.isTablet = true;
                        }
                }
        }

        //Cuando recive la data de la persona, va a setear los valores e ir al siguiente paso
        setData(data: any) {
                this.dataAboutCustomer = data;
                data.type = this.dataAboutCustomer.type_payment;
                // Datos unicamente de creditos viejos
                if (data.type == 1) {
                        this.index_path.push(4);
                        this.currentViewNumber = 4;
                } else if (data.type == 2) {
                        this.dataAboutCustomer.options = [
                                {
                                        type: 0,
                                        description: 'Pago mínimo',
                                        description2: 'Para estar al día',
                                        price_sm: this.dataAboutCustomer.min_payment
                                }, {
                                        type: 1,
                                        description: 'Pago total',
                                        description2: 'Deuda total hoy para liberar el cupo',
                                        price_sm: this.dataAboutCustomer.total_payment
                                }, {
                                        type: 2,
                                        title_sm: 'Abonar a mi deuda',
                                        description2: 'Personaliza el control de tus pagos a tu manera',
                                        abono: true
                                }
                        ]
                        this.index_path.push(3);
                        this.currentViewNumber = 3;
                } else {
                        this.total_data_type3 = data;
                        this.total_data_type3.listOldNew = [
                                {
                                        type: 0,
                                        title: "Pago de obligaciones anteriores",
                                        description2: "Paga tus obligaciones que fueron retiradas antes del 2 de noviembre"
                                },
                                {
                                        type: 1,
                                        title: "Pago de nuevas obligaciones",
                                        price: data.total_payment,
                                        description2: "Tu cuota para estar al día"
                                }
                        ];
                        this.currentViewNumber = 2;
                }

        }

        optionByType3(option: string) {
                var data;
                if (option == '0') {
                        data = {
                                customer: this.total_data_type3.customer,
                                credits_old: this.total_data_type3.credits_old,
                                customer_whitout_credits: this.total_data_type3.customer_whitout_credits,
                                type_payment: 1
                        }
                } else {
                        data = {
                                customer: this.total_data_type3.customer,
                                customer_whitout_credits: this.total_data_type3.customer_whitout_credits,
                                next_due_date: this.total_data_type3.next_due_date,
                                min_payment: this.total_data_type3.min_payment,
                                total_payment: this.total_data_type3.total_payment,
                                type_payment: 2
                        }
                }
                this.setData(data)
        }

        backCurrentViewNumber(evento: string) {
                if (this.total_data_type3 && this.total_data_type3.type_payment == 3 && this.index_path.length == 2) {
                        this.currentViewNumber = 2;
                        this.index_path.pop();
                } else if (this.total_data_type3 && this.total_data_type3.type_payment == 3 && this.index_path.length == 1) {
                        this.currentViewNumber = 1;
                } else {
                        if (this.dataAboutCustomer.type == 1) {
                                switch (this.index_path.length) {
                                        case 2:
                                                this.choosedCreditOld = {};
                                                this.currentViewNumber = 1;
                                                break;
                                        case 3:
                                                this.choosedCreditOld.payment_method = null;
                                                this.currentViewNumber = 4;
                                                break;
                                        case 4:
                                                this.choosedCreditOld.payment_method = null;
                                                this.currentViewNumber = 3;
                                                this.choosedCreditOld._payment_method = null;
                                                break;
                                        case 5:
                                                if (this.choosedCreditOld._payment_method == 2) {
                                                        this.currentViewNumber = 7;
                                                } else {
                                                        this.choosedCreditOld.payment_method = null;
                                                        this.currentViewNumber = 5;
                                                }
                                                break;
                                        case 6:
                                                this.currentViewNumber = 5;
                                                break;
                                }
                                this.index_path.pop()
                        } else if (this.dataAboutCustomer.type == 2) {
                                switch (this.index_path.length) {
                                        case 2:
                                                this.currentViewNumber = 1;
                                                break;
                                        case 3:
                                                this.currentViewNumber = 3;
                                                break;
                                        case 4:
                                                if (this.choosedCreditOld._payment_method == 2) {
                                                        this.currentViewNumber = 7;
                                                } else {
                                                        this.currentViewNumber = 4;
                                                }
                                                break;
                                        case 5:
                                                this.currentViewNumber = 5;
                                }
                                this.index_path.pop();
                        }
                }
        }

        // Recibe el credito ecogido viejo que va a pagar
        chooseCreditOldToPay(credit_choosed: any) {
                console.log(credit_choosed);
                this.choosedCreditOld = {
                        ...credit_choosed, ...{
                                options: [
                                        {
                                                type: 0,
                                                description: 'Pago mínimo',
                                                description2: 'Para estar al día',
                                                price_sm: credit_choosed.total_credit
                                        }, {
                                                type: 1,
                                                description: 'Pago total',
                                                description2: 'Deuda total hoy para liberar el cupo',
                                                price_sm: credit_choosed.total_credit
                                        }, {
                                                type: 2,
                                                title_sm: 'Abonar a mi deuda',
                                                description2: 'Personaliza el control de tus pagos a tu manera',
                                                abono: true
                                        }
                                ],
                                payment_method: null,
                                amount_pay: null
                        }
                }
                this.index_path.push(3);
                this.currentViewNumber = 3;
        }

        // recibo si es total o minimo y estructurar para Saber si es pago en linea o en corresponsal
        chooseOnlineCorrespondent(credit_choosed: any) {
                if (this.dataAboutCustomer.type == 1) {
                        if (credit_choosed == 2 && this.choosedCreditOld.abono == undefined) {
                                this.currentViewNumber = 7;
                                this.index_path.push(7);
                        } else {
                                this.choosedCreditOld = {
                                        ...this.choosedCreditOld, ...{
                                                ...{ _credit_choosed: credit_choosed },
                                                ...credit_choosed, ...{
                                                        option_method: [
                                                                {
                                                                        type: 0,
                                                                        title_sm: 'Pago digital',
                                                                        description2: 'Paga desde tu cuenta bancaria o billetera digital'
                                                                }, {
                                                                        type: 1,
                                                                        title_sm: 'Pago por corresponsal',
                                                                        description2: 'Conoce nuestros corresponsales autorizados y la referencia para pago'
                                                                },
                                                        ]
                                                }, ...{ customer: this.dataAboutCustomer.customer }, ...{ credit: this.dataAboutCustomer }
                                        }
                                }
                                this.currentViewNumber = 5;
                                this.index_path.push(5);
                        }
                } else if (this.dataAboutCustomer.type == 2) {
                        if (credit_choosed == 2 && this.choosedCreditOld.abono == undefined) {
                                this.currentViewNumber = 7;
                                this.index_path.push(7);
                        } else {
                                if (credit_choosed != 2) {
                                        toggleLoader(this.loaderService, true);
                                        this.choosedCreditOld = {
                                                ...{
                                                        option_method: [
                                                                {
                                                                        type: 0,
                                                                        title_sm: 'Pago digital',
                                                                        description2: 'Paga desde tu cuenta bancaria o billetera digital'
                                                                }, {
                                                                        type: 1,
                                                                        title_sm: 'Pago por corresponsal',
                                                                        description2: 'Conoce nuestros corresponsales autorizados y la referencia para pago'
                                                                },
                                                        ]
                                                }, ...{ customer: this.dataAboutCustomer.customer }, ...{ credit: this.dataAboutCustomer }
                                        }
                                        this.dataAboutCustomer.payTotMin = credit_choosed;
                                        this.paymentService.getDebetsByTotalOrMin(this.dataAboutCustomer.customer.external_id, (credit_choosed == 0 ? 'minimo' : 'total')).subscribe((res: any) => {
                                                toggleLoader(this.loaderService, false);
                                                if (res == null) {
                                                        this.ModalServiceAlert.openModal("¡Alerta!", "No existen datos de creditos para pagar por el metodo " + (credit_choosed == 0 ? 'mínimo' : 'total'), "warning");
                                                        return;
                                                }
                                                if (res.ok) {
                                                        this.dataAboutCustomer.credits_new = res.data;
                                                        this.currentViewNumber = 4;
                                                        this.index_path.push(4);
                                                } else {
                                                        this.ModalServiceAlert.openModal("¡Alerta!", res.message, "warning");
                                                }
                                        });
                                } else {
                                        this.choosedCreditOld = {
                                                ...this.choosedCreditOld,
                                                ...{ _credit_choosed: credit_choosed },
                                                ...{
                                                        option_method: [
                                                                {
                                                                        type: 0,
                                                                        title_sm: 'Pago digital',
                                                                        description2: 'Paga desde tu cuenta bancaria o billetera digital'
                                                                }, {
                                                                        type: 1,
                                                                        title_sm: 'Pago por corresponsal',
                                                                        description2: 'Conoce nuestros corresponsales autorizados y la referencia para pago'
                                                                },
                                                        ]
                                                }, ...{ customer: this.dataAboutCustomer.customer }, ...{ credit: this.dataAboutCustomer }

                                        }
                                        this.currentViewNumber = 5;
                                        this.index_path.push(5);
                                }
                        }
                }
        }

        clickButtonPay(click: string) {
                this.currentViewNumber = 5;
                this.index_path.push(5);
        }

        // Cambiar a la vista de corresponsal
        chageViewCorrespondents(click: string) {
                this.currentViewNumber = 6;
                this.index_path.push(6);
        }

        getOptionAbonoSend(option_abono: any) {
                this.choosedCreditOld._payment_method = 2;
                if (this.dataAboutCustomer.type == 1) {
                        if (option_abono.option == 1) {
                                this.choosedCreditOld.abono = option_abono.value;
                                this.chooseOnlineCorrespondent(2);
                        } else {
                                this.chooseOnlineCorrespondent(1);
                        }
                } else if (this.dataAboutCustomer.type == 2) {
                        if (option_abono.option == 1) {
                                this.choosedCreditOld.abono = option_abono.value;
                                this.chooseOnlineCorrespondent(2);
                        } else {
                                this.chooseOnlineCorrespondent(1);
                        }
                }

        }

        returnTotalDebet() {
                if (this.dataAboutCustomer.type == 1) {
                        return this.choosedCreditOld.total_credit
                } else if (this.dataAboutCustomer.type == 2) {
                        return this.dataAboutCustomer.total_payment
                }
        }
}