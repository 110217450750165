import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-trasunion',
  templateUrl: './table-trasunion.component.html',
  styleUrls: ['./table-trasunion.component.scss']
})
export class TableTrasunionComponent {

  @Input() public obligacionesT: any[] = [];  
  @Input() public scoreTable: any[] = [];

}
