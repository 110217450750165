import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
	CustomerInterface,
	CustomerPaginatedInterface,
	CustomerResponseInterface,
	CustomerResponseListInterface,
    DataTransUnionAndRues,
} from "../interfaces/customer-interface";
import { CustomerHistorialResponseInterface } from "./customer-historial-interface";
import { Observable, Subject, tap } from "rxjs";
import { CustomerShowHistorialResponseInterface } from "../interfaces/customer-show.interface";
import {
	CustomerPaymentResponseInterface,
	CustomerPaymentStoreResponseInterface,
} from "../interfaces/customer-payment.interface";

@Injectable({
	providedIn: "root",
})

export class CustomerService {

	/*************
	 *  variables**
    *************/
	private baseUrl: string = environment.apiUrl + "/clientes";
	private _refreshUsers$ = new Subject<void>();
	private searchCustomerSubject = new Subject<void>();
	searchCustomer$ = this.searchCustomerSubject.asObservable();

	/*************
	 *  constructor****
	*************/
	constructor(private http: HttpClient) {}

	/*************
	 *  getters****
	*************/
	get _refreshUsers() {
		return this._refreshUsers$;
	}

	/*************
	 *  metodos****
	 *************/

	/**
     * Actualiza el nuevo total de cupo aprobado para un cliente específico.
     * @param customerId Identificador del cliente para el cual se actualizará el total del cupo.
     * @param newTotalQuota Nuevo total de cupo aprobado que se asignará al cliente.
     * @returns Observable con la respuesta de la solicitud HTTP.
    */
    newTotalCupo(customerId: number, newTotalQuota: number): Observable<any> {
        // Se definen las cabeceras HTTP
        const headers = { "Content-Type": "application/json" };

        // Se crea el cuerpo de la solicitud con el customerId y el nuevo total de cupo
        const body = {
            customer_id: customerId,
            new_total_approved_quota: newTotalQuota,
        };

        // Se realiza una solicitud PUT al servicio web con la URL y el cuerpo definidos
        return this.http.put(`${this.baseUrl}/nuevo/cupo`, body, { headers }).pipe(
            // Ejecuta una acción después de que la solicitud HTTP ha tenido éxito
            tap(() => {
                // Emite un evento para notificar a los suscriptores sobre la actualización
                this._refreshUsers.next();
            })
        );
    }

    /**
     * Obtiene una lista paginada de clientes desde el servidor.
     * @param page Número de página para la paginación.
     * @returns Observable con la respuesta del servidor que contiene la lista paginada de clientes.
    */
    getCustomers(page: number): Observable<CustomerPaginatedInterface> {
        // Construye la URL de la solicitud incluyendo el número de página
        const url = `${this.baseUrl}?page=${page}`;

        // Realiza una solicitud GET al servicio web utilizando la URL construida
        return this.http.get<CustomerPaginatedInterface>(url);
    }

	/**
     * Obtiene la información detallada de un cliente.
     * @param id Identificador único del cliente.
     * @returns Observable que emite la información detallada del cliente en formato CustomerResponseInterface.
     */
    getCustomerInfo(id: number): Observable<CustomerResponseInterface> {
        // Realiza una solicitud HTTP GET para obtener la información detallada del cliente
        return this.http.get<CustomerResponseInterface>(
            `${this.baseUrl}/${id}`
        );
    }

	/**
     * Obtiene el historial de tienda de un cliente mediante su número de identificación.
     * @param identification Número de identificación del cliente.
     * @returns Observable que emite el historial de tienda del cliente en formato CustomerHistorialResponseInterface.
    */
    getCustomerInfoHistorial(identification: string): Observable<CustomerHistorialResponseInterface> {
        // Construye la URL para la solicitud HTTP GET basada en el número de identificación del cliente
        const url = `${this.baseUrl}/historial-tienda/cedula/${identification}`;
        
        // Realiza una solicitud HTTP GET para obtener el historial de tienda del cliente
        return this.http.get<CustomerHistorialResponseInterface>(url);
    }

	/**
     * Obtiene el historial de un cliente mediante su ID.
     * @param id ID del cliente.
     * @returns Observable que emite el historial del cliente en formato CustomerShowHistorialResponseInterface.
    */
    getCustomerHistorial(id: number): Observable<CustomerShowHistorialResponseInterface> {
        // Construye la URL para la solicitud HTTP GET basada en el ID del cliente
        const url = `${this.baseUrl}/historial/${id}`;
        
        // Realiza una solicitud HTTP GET para obtener el historial del cliente
        return this.http.get<CustomerShowHistorialResponseInterface>(url);
    }

	/**
     * Descarga el contrato de un cliente mediante su ID.
     * @param id ID del cliente para el cual se desea descargar el contrato.
     * @returns Observable que emite un objeto Blob, que representa el contenido del contrato.
    */
    downloadCustomerContract(id: number): Observable<Blob> {
        // Construye la URL para la solicitud HTTP GET basada en el ID del cliente
        const url = `${this.baseUrl}/historial/download/contract/${id}`;
        
        // Realiza una solicitud HTTP GET con responseType "blob" para obtener el contrato como un objeto Blob
        return this.http.get(url, { responseType: "blob" });
    }


	/**
     * Obtiene una lista paginada de clientes aplicando filtros.
     * @param formData Objeto que contiene los filtros para la búsqueda de clientes.
     * @param page Número de página para la paginación.
     * @returns Observable que emite una interfaz que representa la lista paginada de clientes.
    */
    getCustomersFilter(formData: any, page: number) {
        // Modifica formData para agregar el parámetro 'page'.
        formData.page = page;
    
        // Convierte los parámetros de búsqueda en un objeto HttpParams.
        const params = new HttpParams({ fromObject: formData });
    
        // Construye la URL completa para la búsqueda de créditos con detalles de morosidad.
        const url = `${this.baseUrl}/buscar`;

        // Realiza una solicitud HTTP GET con los parámetros de consulta para obtener la lista paginada de clientes
        return this.http.get<CustomerPaginatedInterface>(url, { params });
    }


	/**
     * Exporta clientes aplicando filtros y devuelve una lista de clientes para exportación.
     * @param formData Objeto que contiene los filtros para la exportación de clientes.
     * @returns Observable que emite una interfaz que representa la lista de clientes para exportación.
    */
    getCustomersExportFilter(formData: any) {
        // Convierte los filtros del formulario en parámetros de consulta HTTP
        const params = new HttpParams({ fromObject: formData });
        
        // Construye la URL para la solicitud HTTP GET
        const url = `${this.baseUrl}/buscar`;
        
        // Realiza una solicitud HTTP GET con los parámetros de consulta para obtener la lista de clientes para exportación
        return this.http.get<CustomerResponseListInterface>(url, { params });
    }


	/**
     * Obtiene información sobre los pagos pendientes de un cliente.
     * @param id Identificador único del cliente.
     * @returns Observable que emite una interfaz que representa la información de pagos pendientes del cliente.
    */
    getCreditsPendintsCustomer(id: number) {
        // Construye la URL para la solicitud HTTP GET utilizando el identificador único del cliente
        const url = `${environment.apiUrl}/clientes-pagos/${id}`;
        
        // Realiza una solicitud HTTP GET para obtener la información de pagos pendientes del cliente
        return this.http.get<CustomerPaymentResponseInterface>(url);
    }


	/**
     * Registra un pago pendiente para un cliente.
     * @param customerId Identificador único del cliente para el cual se registra el pago pendiente.
     * @param paymentAmount Monto del pago pendiente a registrar.
     * @param datePayment Fecha del pago pendiente.
     * @returns Observable que emite una interfaz que representa la respuesta del servidor al registro del pago pendiente.
    */
    storeCreditsPendintsCustomer(
        customerId: number,
        paymentAmount: number,
        datePayment: Date, 
        comment: string
    ) {
        // Construye la URL para la solicitud HTTP POST
        const url = `${environment.apiUrl}/clientes-pagos`;
        
        // Realiza una solicitud HTTP POST para registrar el pago pendiente utilizando los parámetros proporcionados
        return this.http.post<CustomerPaymentStoreResponseInterface>(url, {
            customerId,
            paymentAmount,
            datePayment,
            comment
        });
    }


	/**
     * Elimina un cliente específico.
     * @param customerId Identificador único del cliente que se va a eliminar.
     * @returns Observable que emite una interfaz que representa la respuesta del servidor a la eliminación del cliente.
    */
    deleteCustomer(customerId: number) {
        // Construye la URL para la solicitud HTTP DELETE
        const url = `${environment.apiUrl}/clientes/eliminar/${customerId}`;
        
        // Realiza una solicitud HTTP DELETE para eliminar el cliente con el identificador proporcionado
        return this.http.delete<CustomerPaymentStoreResponseInterface>(url);
    }


	/**
     * Actualiza la información de un cliente existente.
     * @param customer Objeto que contiene la información actualizada del cliente.
     * @param customerId Identificador único del cliente que se va a actualizar.
     * @returns Observable que emite una interfaz que representa la respuesta del servidor a la actualización del cliente.
    */
    updateCustomer(customer: CustomerInterface, customerId: number): Observable<any> {
        // Construye la URL para la solicitud HTTP PUT
        const url = `${this.baseUrl}/actualizar/${customerId}`;
        
        // Realiza una solicitud HTTP PUT para actualizar la información del cliente con el identificador proporcionado
        return this.http.put<CustomerResponseInterface>(url, customer);
    }


    /**
     * Método para rechazar a un cliente en el servidor.
     * @param customerId - El ID del cliente que se va a rechazar.
     * @returns Observable con la respuesta del servidor.
     */
    rejectCustomer(customerId: number): Observable<any> {
        // Construye la URL para la solicitud HTTP PUT
        const url = `${this.baseUrl}/rechazar/${customerId}`;
        
        // Este método espera un objeto CustomerResponseInterface como respuesta del servidor.
        return this.http.get<CustomerResponseInterface>(url, {});
    }

    enableCustomer(customerId: number): Observable<any> {
        // Construye la URL para la solicitud HTTP PUT
        const url = `${this.baseUrl}/habilitar/${customerId}`;
        
        // Este método espera un objeto CustomerResponseInterface como respuesta del servidor.
        return this.http.get<CustomerResponseInterface>(url, {});
    }

    pazSalvoCustomer(customerId: number): Observable<any> {
        const url = `${this.baseUrl}/paz-salvo/${customerId}`;

        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
            responseType: "blob" as "json", // Indicar que la respuesta es un blob (archivo binario)
        };
        
        return this.http.post<Blob>(url, {}, options);
    }
    
    /**
     * Este método obtiene la información de TransUnion y Rues de un cliente.
     * @param id  customerId
     * @returns un Observable con la información de TransUnion y Rues
     */
    public getInfoTransunionAndRues(id: string): Observable<DataTransUnionAndRues>{
        const url = `${environment.apiUrl}/clientes-info-finantial/${id}`;
        return this.http.get<DataTransUnionAndRues>(url);
    }

    getAccountStatement(identification: number | string) {
        return this.http.get(`${environment.apiUrl}/download/account-statement/${identification}`);
    }


    public downloadFileDeceval( customer:any ){

        const baseUrl = environment.apiUrl
        return this.http.post(`${baseUrl}/search-file-deceval`, { customer })

    }

    public downloadPDFContract( customerId:any ): Observable<HttpResponse<Blob>>{
        const baseUrl = environment.apiUrl;
        return this.http.get(`${baseUrl}/file-contract/${customerId}`, { responseType: 'blob', observe: 'response' });
    }

    sendWhatsappLinkDeceval( customer_id: number ) {
        return this.http.get(`${environment.apiUrl}/send-whatsapp-link-deceval/${customer_id}`);
    }


    public generateNewLinkPagare(identification: string){
        const baseUrl = environment.apiUrl
        return this.http.post(`${baseUrl}/regenerate-token`, {identification} )
    }

}
