import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';
import { PagareInfoCustomerInterface } from '../../interfaces/info-customer-pagare.interface';
import { FirmaPagareService } from '../../services/firma-pagare.service';
import { switchMap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pagare-send-code',
  templateUrl: './pagare-send-code.component.html',
  styleUrls: ['./pagare-send-code.component.scss'],
})
export class PagareSendCodeComponent {
  customerInfo: PagareInfoCustomerInterface;
  @Output() changeStepAndCustomerId: EventEmitter<{
    step: number;
    customerId: number;
  }> = new EventEmitter();

  public myInputFormNumber:FormGroup = this.formBuilder.group({
    phone: ['', [Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
  })


  /**
   * Constructor para inicializar las dependencias.
   */
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private firmaPagareService: FirmaPagareService,
    private modalService: ModalServiceAlert,
    private formBuilder: FormBuilder
  ) {}

  /**
   * Método del ciclo de vida del componente que se ejecuta al inicializar el componente.
   * Realiza las siguientes acciones:
   */
  ngOnInit(): void {
    toggleLoader(this.loaderService, true);
    this.getInfoCustomer();
  }

  /**
   * Obtiene la información del cliente basada en el ID proporcionado en los parámetros de la ruta.
   *
   * La función inicia escuchando cambios en los parámetros de la ruta. Cuando detecta un cambio,
   * intenta obtener el ID del cliente y luego hace una solicitud para obtener la información detallada
   * del cliente usando ese ID. Si la solicitud es exitosa, asigna la información del cliente a
   * `this.customerInfo`. Si ocurre un error, se muestra un mensaje de error.
   *
   * @returns {void} No devuelve ningún valor.
   */
  getInfoCustomer() {
    this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        const token = params.get('token');
        return this.firmaPagareService.validateToken(token);
      }),
      switchMap((response: any) => {
         console.log(response)
        if( response && response.identification ){
          const customerId = response.identification;
          return this.firmaPagareService.getInfoCustomer(customerId);
        } else {
          throw new Error('Token no valido')
        }
      })
    )
    .subscribe(
      (response )=> {
        toggleLoader(this.loaderService, false);
        if(response.ok){
          this.customerInfo = response.data
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false, 
          }).then((result) => {
            if( result.isConfirmed ){
              this.router.navigate(['/iniciar-sesion'])
            }
          });
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false)
        Swal.fire({
          title: 'Error',
          text: error.error.message || error.error.error,
          icon: 'error',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false, 
        }).then((result ) => {
          if(result.isConfirmed){
            this.router.navigate(['/iniciar-sesion'])
          }
        })
        console.log(error);
      }
    )
  }

  /**
   * Envia un código OTP para el pagare al cliente correspondiente.
   *
   * Al iniciar la solicitud, muestra un loader indicando que está "Enviando código OTP".
   * Una vez que la solicitud se completa, se oculta el loader y se muestra un mensaje de éxito o error
   * según la respuesta del servidor.
   *
   * @function
   * @returns {void} No devuelve ningún valor.
   */
  sendCodeOtpPagare() {
    if (this.myInputFormNumber.invalid) {
      Swal.fire({
        title: 'Error',
        text: 'Por favor, ingrese un número de teléfono válido.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    console.log(this.myInputFormNumber.value.phone)
    toggleLoader(this.loaderService, true, 'Enviando código');
    const customerId = this.customerInfo.id;
    this.firmaPagareService.senCodeOtpPagareSms(customerId, this.myInputFormNumber.value.phone).subscribe({
      next: ( response:any ) => {
        this.changeStepAndCustomerId.emit({
          step: 2,
          customerId: this.customerInfo.id,
        });
        this.modalService.openModal(
          'Exitoso',
          response.message ,
          'success'
        );

      },
      error: ( error:any ) => {
        toggleLoader(this.loaderService, false, );
        console.error(error.error.message);
        this.modalService.openModal(
          'error',
          error.error.message ,
          'error'
        );


      },
      complete:( ) => {
        toggleLoader(this.loaderService, false);
      }
    })
  }

  getPhoneNumberErrorMessage() {
    const phoneControl = this.myInputFormNumber.get('phone');
    if (phoneControl!.hasError('required')) {
      return 'El número de teléfono es obligatorio.';
    }
    if (phoneControl!.hasError('pattern')) {
      return 'El número de teléfono debe tener exactamente 10 dígitos.';
    }
    
    return '';
  }


  
}
