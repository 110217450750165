<div class="third_page">
  <div class="important_six">
    <span (click)="decrementar()"
      ><i class="fa-solid fa-circle-arrow-left"></i
    ></span>
    <img src="../../../../assets/img-news/nubes-sol.svg" alt="Nubes Sol" />
  </div>
  <div class="info_content">
    <div class="info_card">
      <div class="info">
        <h4>
          ¡Nuestro objetivo es <b>ayudar a crecer tu negocio!💚💚</b>!  
        </h4>
      </div>
      <h2>
        Somos un método de pago para abastecer tu negocio desde <b>0% interés</b>. <br><br>

        <div class="pass-list">
          <h5> Para solicitar tu crédito necesitas: </h5>
          <ol>
            <li> Cédula física, no se acepta PPT </li>
            <li> Una factura física de otro proveedor  </li>
          </ol>
        </div>

        <br>
        <p>Aplica únicamente con tus proveedores aliados a zíro</p>
        <a
          target="_blank"
          href="https://somosziro.com/terminos-y-condiciones/"
          rel="noopener noreferrer"
        >
          Términos y Condiciones
        </a>
      </h2>
    </div>
  </div>
  <div class="info_footer">
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
    <button type="button" (click)="incrementar()">Siguiente</button>
  </div>
</div>
