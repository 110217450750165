<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex justify-content-center px-4 align-items-center ">
        <div class="d-flex flex-column">
                <header>
                        <h2 class="fs-2 m-0 pb-2">
                                {{ date_status == 'credits_old' ? 'Seleccione el credito que desea pagar' : '¿Cuánto
                                desea pagar?' }}
                        </h2>
                        <div *ngIf="date_status != 'credits_old' && chooseTotalPay.next_due_date" class="notification">
                                <p>Fecha límite para pago mínimo: </p> <span> {{chooseTotalPay.next_due_date |
                                                customDateFormat}} </span>
                        </div>
                </header>

                <app-list-group (eventOption)="setOptionsChoose($event)" (eventSendValueAbono)="sendValueAbono($event)"
                        [dateStatus]="date_status" [items]="itemsStep"></app-list-group>

                <app-terms-conditions></app-terms-conditions>

        </div>
</div>