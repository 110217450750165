<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex justify-content-center px-4 align-items-center ">
    <div class="d-flex flex-column">
        <header>
            <h2 class="fs-2 m-0 pb-2">
                ¿Cómo quieres pagar?
            </h2>
        </header>

        <app-list-group (eventOption)="setOptionsChoose($event)" [items]="itemsStep"></app-list-group>

        <app-terms-conditions></app-terms-conditions>
        <app-payment-modal (eventCloseModal)="closeModal()" [data]="returnDataModal()" *ngIf="showModal"></app-payment-modal>
    </div>
</div>