<app-modal-alert></app-modal-alert>
<app-register-upss *ngIf="stepNew === 25"></app-register-upss>
<app-one-page *ngIf="stepNew === 1"></app-one-page>
<!-- <app-browser-default *ngIf="stepNew === 2"></app-browser-default> -->
<!-- <app-second-page *ngIf="stepNew === 2"></app-second-page> -->
<app-third-page *ngIf="stepNew === 2"></app-third-page>

<form [formGroup]="allyForm">
  <!-- NÚMERO DE CELULAR -->
  <div class="four_page" *ngIf="stepNew === 3">
    <div class="important">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span> Paso 1 </span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="four_page_content">
      <div class="info_content">
        <div class="content-prueba">
          <span class="span-what">
            <img
              src="https://img.icons8.com/?size=100&id=QkXeKixybttw&format=png&color=000000"
              width="60"
              height="60"
              alt=""
            />
          </span>
          <h2 class="text-start">
            Para ofrecerte un mejor servicio verificaremos tu número de
            WhatsApp.
          </h2>
        </div>

        <div class="input_phone">
          <div
            class="container_phone"
            [ngStyle]="{
              borderColor: validateCampo(allyForm, 'phone_1') ? '#F66B7E' : null
            }"
          >
            <input
              type="text"
              placeholder="Ingresa tu número de celular"
              type="number"
              id="phone_1"
              value=""
              formControlName="phone_1"
              required
            />
            <span
              *ngIf="validateCampo(allyForm, 'phone_1')"
              class="input_phone_error"
              ><i class="fa-solid fa-circle-xmark"></i
            ></span>
          </div>
          <span
            *ngIf="validateCampo(allyForm, 'phone_1') as error"
            class="message_error"
            >{{ error }}</span
          >
        </div>

        <div class="button_phone">
          <button type="button" (click)="validarCelular()">
            Enviar código
          </button>
          <p>
            Recibirás un código a tu <b>WhatsApp</b> para continuar con el
            proceso
          </p>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <app-five-page *ngIf="stepNew === 4"></app-five-page>
  <!-- FOTO FRONTAL -->
  <div class="six_page" *ngIf="stepNew === 6">
    <div class="important_six">
      <span (click)="sureYouWantToGoBack()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma la <b>foto frontal</b> de tu documento de manera
            <b>HORIZONTAL</b>, <b>centrada</b> y con buena <b>iluminación</b>.
            Asegurate que no se vean los hologramas y que la
            <b>foto de la cédula sea visible</b>.
          </h2>
        </div>

        <div class="six_screen">
          <label for="fileInput">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-frontal-1.jpg"
              alt="FOTO FRONTAL"
              *ngIf="!imagenFrontalVisible"
            />
          </label>

          <img
            [src]="documentFrontImage"
            *ngIf="imagenFrontalVisible"
            class="image-screen"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenFrontalVisible">
          <label for="fileInput" *ngIf="verificacionMetamap === false">
            <input
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenFrontalVisible">
          <label for="fileInput" class="button-return">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br />
      <br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>
  <!-- FOTO REVERSO -->
  <div class="six_page" *ngIf="stepNew === 7">
    <div class="important_six">
      <span (click)="sureYouWantToGoBack()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma la <b>foto del reverso</b> de tu documento de manera
            <b>HORIZONTAL</b>, centrada y con buena iluminación.
          </h2>
        </div>

        <div class="six_screen" id="cameraOutput">
          <label for="fileInput2">
            <input
              type="file"
              id="fileInput2"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-trasera-1.jpg"
              alt="FOTO TRASERA"
              *ngIf="!imagenTraseraVisible"
            />
          </label>

          <img
            [src]="documentBackImage"
            *ngIf="imagenTraseraVisible"
            class="image-screen"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenTraseraVisible">
          <label for="fileInput2" *ngIf="verificacionMetamap === false">
            <input
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenTraseraVisible">
          <label for="fileInput" class="button-return">
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br /><br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- FOTO SELFIE -->
  <div class="six_page" *ngIf="stepNew === 5">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma una foto de tu rostro con buena <b> iluminación</b>, de manera
            <b>VERTICAL</b>, Sin <b> gafas, gorras o tapabocas</b>.
          </h2>
        </div>

        <div class="six_screen" id="cameraOutput">
          <label for="fileInput3">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <div class="w-100 p-4 d-flex">
              <img
                src="../../../../assets/img-news/Erroneo.svg"
                alt="FOTO SELFIE"
                *ngIf="!imagenSelfieVisible"
                class="six_selfie"
              />
              <img
                src="../../../../assets/img-news/Correcto.svg"
                alt="FOTO SELFIE"
                *ngIf="!imagenSelfieVisible"
                class="six_selfie ms-4"
              />
            </div>
          </label>

          <img
            [src]="selfieImage"
            *ngIf="imagenSelfieVisible"
            class="image-selfie"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenSelfieVisible">
          <label for="fileInput3" *ngIf="verificacionMetamap === false">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenSelfieVisible">
          <label (click)="backToAgain()" for="fileInput" class="button-return">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br />
      <br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- Paso 4: CODIGO DEL PROVEEDOR -->
  <div class="six_page" *ngIf="stepNew === 8">
    <div class="important_six">
      <span (click)="sureYouWantToGoBack()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span>Paso 3</span>
      <img src="../../../../assets/img-news/fondo-auto.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="code_proveedor">
          <div class="info_code_proveedor">
            <span><i class="fa-solid fa-tag"></i></span>
            <h2>Código de tu proveedor.</h2>
          </div>

          <div class="input_code_proveedor">
            <div
              class="container_code_proveedor"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'code_shop')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="d-flex pe-2">
                <input
                  type="number"
                  id="code_shop"
                  placeholder="Ingresa el código"
                  formControlName="code_shop"
                  class="w-75"
                  required
                  [(ngModel)]="code"
                />
              </div>
              <span
                *ngIf="validateCampo(allyForm, 'code_shop')"
                class="input_code_proveedor_error"
                ><i class="fa-solid fa-circle-xmark"></i
              ></span>
            </div>
            <span
              *ngIf="validateCampo(allyForm, 'code_shop') as error"
              class="message_error"
              >{{ error }}</span
            >
          </div>

          <b></b>

          <div class="info_code_proveedor">
            <span><i class="fa-solid fa-user"></i></span>
            <h2>Código de tu vendedor.</h2>
          </div>

          <div class="input_code_proveedor">
            <div
              class="container_code_proveedor"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'advisor_identification')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="d-flex pe-2">
                <input
                  type="number"
                  id="advisor_identification"
                  placeholder="Ingresa la cédula de tu vendedor"
                  formControlName="advisor_identification"
                  class="w-75"
                  required
                />
              </div>
              <span
                *ngIf="validateCampo(allyForm, 'advisor_identification')"
                class="input_code_proveedor_error"
                ><i class="fa-solid fa-circle-xmark"></i
              ></span>
            </div>
            <span
              *ngIf="validateCampo(allyForm, 'advisor_identification') as error"
              class="message_error"
              >{{ error }}</span
            >
          </div>

          <div class="button_code">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>
              Si no tienes el código diligenciado,
              <b>por favor solicita el código a tu proveedor</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>
  <!--  NOMBRE Y APELLIDO  -->
  <div class="six_page" *ngIf="stepNew === 9">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span>Paso 4</span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content"></div>

    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-address-card"></i></span>
            <h2>Verifiquemos tus datos, tu nombre y tu cédula.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification_type')
                    ? '#F66B7E'
                    : null
                }"
              >
                <select
                  id="identification_type"
                  required
                  formControlName="identification_type"
                  readonly
                >
                  <option value="">Seleccionar</option>
                  <option value="CC">Cédula de ciudadanía</option>
                  <option value="CE">Cédula de extranjería</option>
                  <option value="PPT">PPT</option>
                </select>
                <span
                  *ngIf="validateCampo(allyForm, 'identification_type')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'identification_type') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="identification"
                  required
                  readonly
                  formControlName="identification"
                  placeholder="Introduce tu número de cédula"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'identification')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'identification') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>
        </div>

        <div class="info_personal content-name">
          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'name')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="name"
                  required
                  readonly
                  placeholder="Nombres"
                  formControlName="name"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'name')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'name') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'last_name')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="last_name"
                  required
                  readonly
                  formControlName="last_name"
                  placeholder="Apellidos"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'last_name')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'last_name') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <!-- <p>
                Por favor, verifica que los datos proporcionados sean correctos y
                coincidan con tu información <b>real</b>
              </p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- CORREO Y PASSWORD -->
  <div class="six_page" *ngIf="stepNew === 10">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-envelope"></i></span>
            <h2>Ingresa tu correo electrónico.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'email')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="email"
                  id="email"
                  required
                  name="email"
                  formControlName="email"
                  data-nb
                  placeholder="Introduce tu correo electrónico"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'email')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'email') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="info_personal_one">
            <span><i class="fa-solid fa-lock"></i></span>
            <h2>Crea una contraseña.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'password')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="password"
                  id="password"
                  required
                  formControlName="password"
                  (input)="onPasswordInput()"
                  maxlength="4"
                  placeholder="Ingresa 4 dígitos numéricos"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'password')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'password') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>
              Esta información registrada te dará acceso a tu cuenta en zíro
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- DIRECCION -->
  <div class="six_page" *ngIf="stepNew === 11">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-location-dot"></i></span>
            <h2>Ingresa tu dirección de residencia.</h2>
          </div>

          <div class="div-city">
            <label class="custom-label" for="">Ciudad o municipio</label>
            <div style="display: flex; align-items: center">
              <div class="span-errores">
                <ng-select
                  class="custom-select ng-selects ct-select"
                  formControlName="city_id2"
                  [items]="cities"
                  bindLabel="name"
                  bindValue="name"
                  [searchable]="true"
                  [clearable]="false"
                  placeholder="Cartagena de Indias"
                  (change)="onCitySelected($event)"
                  style="margin-right: 10px; flex: 1"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.name ? item.name : 'Ciudad' }}
                  </ng-template>
                </ng-select>
                <span
                  *ngIf="validateCampo(allyForm, 'city_id2') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
              <button
                class="btn btn-primary btn-reset"
                [ngClass]="{
                  'btn-margin': validateCampo(allyForm, 'city_id2')
                }"
                (click)="refreshData('cities')"
              >
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
          <div class="div-barrio">
            <label class="custom-label" for="neighborhood">Barrio</label>
            <div
              class="container_info_personal"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'neighborhood')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="span-errores">
                <input
                  type="text"
                  id="neighborhood"
                  required
                  class="input-group-text"
                  formControlName="neighborhood"
                  placeholder="Barrio"
                  style="width: 100%"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'neighborhood') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
            </div>
          </div>
          <div class="div-tipo-calle">
            <label class="custom-label" for="street">Tipo de Calle</label>
            <div style="display: flex; align-items: center">
              <!-- Select para Tipo de Calle -->
              <div style="flex: 1; margin-right: 10px">
                <ng-select
                  class="custom-select ng-selects"
                  formControlName="streetType"
                  [items]="streetTypes"
                  [searchable]="true"
                  [clearable]="false"
                  placeholder="Tipo de calle"
                  (change)="onStreetTypeSelected($event)"
                  style="width: 100%"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item ? item : 'Tipo de calle' }}
                  </ng-template>
                </ng-select>
                <span
                  *ngIf="validateCampo(allyForm, 'streetType') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>

              <!-- Input para la Calle (Santander) -->
              <div class="container_info_personal" style="flex: 1">
                <div style="flex: 1; margin-right: 10px">
                  <input
                    type="text"
                    id="street"
                    required
                    class="input-group-text"
                    formControlName="street"
                    placeholder="Dirección"
                    style="width: 100%"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'street') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="div-numero">
            <label class="custom-label" for="numberStreet">Número</label>

            <div style="display: flex; align-items: center">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'numberStreet')
                    ? '#F66B7E'
                    : null
                }"
                style="margin-right: 5px; flex: 1"
              >
                <div class="span-errores">
                  <input
                    type="number"
                    id="numberStreet"
                    required
                    formControlName="numberStreet"
                    class="input-group-text input-number"
                    placeholder="#"
                    [disabled]="isStreetFieldsDisabled"
                    [ngClass]="{ isFalseInput: isStreetFieldsDisabled }"
                    style="text-align: center"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'numberStreet') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                </div>
              </div>

              <span style="margin: 0 5px">–</span>

              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'numberStreet2')
                    ? '#F66B7E'
                    : null
                }"
                style="flex: 1"
              >
                <div class="span-errores">
                  <input
                    id="numberStreet2"
                    required
                    formControlName="numberStreet2"
                    class="input-group-text input-number"
                    [disabled]="isStreetFieldsDisabled"
                    [ngClass]="{ isFalseInput: isStreetFieldsDisabled }"
                    (input)="onNumberStreet2Input($event)"
                    style="text-align: center"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'numberStreet2') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                  <span *ngIf="validateCharacter" class="message_error"
                    >Debe iniciar con un número.</span
                  >
                </div>
              </div>

              <div class="content-input-checkbox" style="margin-left: 10px">
                <label>
                  <input
                    type="checkbox"
                    class="check-buss"
                    formControlName="hasNoStreetNumber"
                    (change)="toggleStreetFields($event)"
                  />
                  No tengo número
                </label>
              </div>
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- VISTA INTERMEDIA -->

  <!-- NIT  -->
  <!-- <div class="six_page" *ngIf="stepNew === 13">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-user-tag"></i></span>
            <h2>Ingresa tu NIT o cédula.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'nit') ? '#F66B7E' : null
                }"
              >
                <input
                  type="text"
                  id="nit"
                  required
                  formControlName="nit"
                  placeholder="Ingresa tu NIT o número de cédula"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'nit')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'nit') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Verificar <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Sin dígito de verificación</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>
 -->

  <!-- TIPO DE NEGOCIO Y NOMBRE DEL NEGOCIO -->
  <div class="six_page" *ngIf="stepNew === 12">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-store"></i></span>
            <h2>Cuéntanos sobre tu negocio.</h2>
          </div>
          <div class="div-city">
            <label class="custom-label" for="">Tipo de negocio</label>
            <div style="display: flex; align-items: center">
              <div class="span-errores">
                <ng-select
                  formControlName="customer_business_types_id"
                  [items]="businessTypes"
                  bindLabel="name"
                  bindValue="id"
                  [searchable]="true"
                  [clearable]="false"
                  required
                  id="ng-select"
                  class="custom"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.name ? item.name : 'Tipo de negocio' }}
                  </ng-template>
                </ng-select>
                <span
                  *ngIf="
                    validateCampo(
                      allyForm,
                      'customer_business_types_id'
                    ) as error
                  "
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
              <button
                class="btn btn-primary btn-reset"
                [ngClass]="{
                  'btn-margin': validateCampo(
                    allyForm,
                    'customer_business_types_id'
                  )
                }"
                (click)="refreshData('business')"
              >
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>

          <div class="div-barrio">
            <label class="custom-label" for="business_name"
              >Nombre del negocio</label
            >
            <div
              class="container_info_personal"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'business_name')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="span-errores">
                <input
                  type="text"
                  id="business_name"
                  required
                  class="input-group-text"
                  formControlName="business_name"
                  placeholder="Nombre de tu negocio"
                  style="width: 100%"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'business_name') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
            </div>
          </div>

          <div class="div-barrio">
            <label class="custom-label" for="business_name"
              >¿Tu negocio está registrado en cámara de Comercio?</label
            >
            <div
              *ngFor="let option of registeredChamberoptions"
              class="checkbox-wrapper"
            >
              <input
                type="radio"
                [id]="option.value"
                [value]="option.value"
                formControlName="registered_chamber_commerce"
                class="custom-checkbox"
              />
              <label class="label-check" [for]="option.value">{{
                option.name
              }}</label>
            </div>
            <span
              *ngIf="
                validateCampo(allyForm, 'registered_chamber_commerce') as error
              "
              class="message_error"
              >{{ error }}</span
            >
            <div class="div-barrio nit-div" *ngIf="showNitField | async">
              <label class="custom-label" for="nit">NIT de tu negocio</label>
              <input
                type="text"
                id="nit"
                formControlName="nit"
                class="input-group-text"
                placeholder="Ingresa el NIT de tu negocio"
              />
              <span
                *ngIf="validateCampo(allyForm, 'nit') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- COMPRAS AL MES -->
  <div class="six_page" *ngIf="stepNew === 14">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-wallet"></i></span>
            <h2>¿Cuánto cupo quieres solicitar a zíro?</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'value_request')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="value_request"
                  required
                  formControlName="value_request"
                  (input)="formatInputToCurrency($event)"
                  placeholder="Indica el monto que deseas solicitar"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'value_request')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>
              <label class="mt-1" for="">
                Mínimo $200.000 - Máximo $3.000.000
              </label>

              <span
                *ngIf="validateCampo(allyForm, 'value_request') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- CIUDAD Y DIRECCION DEL NEGOCIO -->
  <div class="six_page" *ngIf="stepNew === 13">
    <div class="important_six">
      <span (click)="decrementarBussinnes()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-location-dot"></i></span>
            <h2>Ingresa la dirección de tu negocio.</h2>
          </div>

          <label>
            <input
              formControlName="checkBussinnes"
              type="checkbox"
              class="check-buss"
              (change)="toggleSameAddress($event)"
            />
            ¿Tu dirección de residencia es la misma de tu negocio?
          </label>

          <div class="div-city">
            <label class="custom-label" for="">Ciudad o municipio</label>
            <div style="display: flex; align-items: center">
              <div class="span-errores">
                <ng-select
                  class="custom-select ng-selects ct-select"
                  formControlName="city_id"
                  [items]="cities"
                  bindLabel="name"
                  bindValue="id"
                  [searchable]="true"
                  [clearable]="false"
                  [readonly]="readonlyOrNo"
                  placeholder="Cartagena de Indias"
                  [ngClass]="{ isDisabled: readonlyOrNo }"
                  (change)="onCitySelected($event)"
                  style="margin-right: 10px; flex: 1"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.name ? item.name : 'Ciudad' }}
                  </ng-template>
                </ng-select>
                <span
                  *ngIf="validateCampo(allyForm, 'city_id') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
              <button
                class="btn btn-primary btn-reset"
                [ngClass]="{ 'btn-margin': validateCampo(allyForm, 'city_id') }"
                (click)="refreshData('cities')"
              >
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
          <div class="div-barrio">
            <label class="custom-label" for="neighborhood">Barrio</label>
            <div
              class="container_info_personal"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'neighborhood')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="span-errores">
                <input
                  type="text"
                  id="neighborhood"
                  required
                  class="input-group-text"
                  formControlName="neighborhood"
                  [readonly]="readonlyOrNo"
                  [ngClass]="{ isDisabled: readonlyOrNo }"
                  placeholder="Barrio"
                  style="width: 100%"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'neighborhood') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>
            </div>
          </div>
          <div class="div-tipo-calle">
            <label class="custom-label" for="street">Tipo de Calle</label>
            <div style="display: flex; align-items: center">
              <!-- Select para Tipo de Calle -->
              <div style="flex: 1; margin-right: 10px">
                <ng-select
                  class="custom-select ng-selects"
                  formControlName="streetType"
                  [items]="streetTypes"
                  [searchable]="true"
                  [clearable]="false"
                  [readonly]="readonlyOrNo"
                  placeholder="Tipo de calle"
                  [ngClass]="{ isDisabled: readonlyOrNo }"
                  (change)="onStreetTypeSelected($event)"
                  style="width: 100%"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item ? item : 'Tipo de calle' }}
                  </ng-template>
                </ng-select>
                <span
                  *ngIf="validateCampo(allyForm, 'streetType') as error"
                  class="message_error"
                  >{{ error }}</span
                >
              </div>

              <!-- Input para la Calle (Santander) -->
              <div class="container_info_personal" style="flex: 1">
                <div style="flex: 1; margin-right: 10px">
                  <input
                    type="text"
                    id="street"
                    required
                    class="input-group-text"
                    formControlName="street"
                    [readonly]="readonlyOrNo"
                    [ngClass]="{ isDisabled: readonlyOrNo }"
                    placeholder="Dirección"
                    style="width: 100%"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'street') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="div-numero">
            <label class="custom-label" for="numberStreet">Número</label>

            <div style="display: flex; align-items: center">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'numberStreet')
                    ? '#F66B7E'
                    : null
                }"
                style="margin-right: 5px; flex: 1"
              >
                <div class="span-errores">
                  <input
                    type="number"
                    id="numberStreet2"
                    required
                    formControlName="numberStreet"
                    class="input-group-text input-number"
                    placeholder="#"
                    [readonly]="readonlyOrNo"
                    [disabled]="isStreetFieldsDisabled"
                    [ngClass]="{
                      isFalseInput: isStreetFieldsDisabled,
                      isDisabled: readonlyOrNo
                    }"
                    style="text-align: center"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'numberStreet') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                </div>
              </div>

              <span style="margin: 0 5px">–</span>

              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'numberStreet2')
                    ? '#F66B7E'
                    : null
                }"
                style="flex: 1"
              >
                <div class="span-errores">
                  <input
                    type="text"
                    id="numberStreet2"
                    required
                    formControlName="numberStreet2"
                    class="input-group-text input-number"
                    [readonly]="readonlyOrNo"
                    [disabled]="isStreetFieldsDisabled"
                    [ngClass]="{
                      isFalseInput: isStreetFieldsDisabled,
                      isDisabled: readonlyOrNo
                    }"
                    (input)="onNumberStreet2Input($event)"
                    style="text-align: center"
                  />
                  <span
                    *ngIf="validateCampo(allyForm, 'numberStreet2') as error"
                    class="message_error"
                    >{{ error }}</span
                  >
                  <span *ngIf="validateCharacter" class="message_error"
                    >Debe iniciar con un número.</span
                  >
                </div>
              </div>

              <div class="content-input-checkbox" style="margin-left: 10px">
                <label>
                  <input
                    type="checkbox"
                    class="check-buss"
                    formControlName="hasNoStreetNumber"
                    (change)="toggleStreetFields($event)"
                  />
                  No tengo número
                </label>
              </div>
              <div></div>
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- <app-alert-info
    class="six_page"
    *ngIf="stepNew === 20"
    titulo="Autorización de tratamiento de datos personales"
    descripcion="Autorizo de manera voluntaria e informada a la empresa Somos zíro S.A.S con NIT 901.592.209-1, en su calidad de responsable de tratamiento de datos, para que recolecte, almacene, utilice y procese mis datos personales con las siguientes finalidades:

    1.        Consultar mi información en centrales de riesgos
    2.        Envío de información promocional e información sobre los productos o servicios que maneja zíro
    3.        Gestión de mi cuenta de cliente y para transferir mis datos personales a terceros para fines comerciales
    
    Entiendo que la información proporcionada incluirá datos personales, como mi nombre, dirección, número de teléfono, dirección de correo electrónico, y otros datos relevantes que puedan ser necesarios para las finalidades mencionadas.

    Esta autorización es válida desde la fecha en que autorizó y estará en vigor mientras sea necesario para cumplir con las finalidades mencionadas anteriormente o hasta que sea revocada por mí.
    
    Puede consultar más sobre nuestra política de tratamiento de datos personales en el siguiente link"
    link="https://somosziro.com/politica-de-privacidad/"
    textoBoton="Acepto"
    [isSuccess]="0"
  ></app-alert-info> -->

  <!-- VISTA ALERTA -->

  <div class="six_page" *ngIf="stepNew === 15">
    <div class="important_six">
      <span (click)="decrementar()">
        <i class="fa-solid fa-circle-arrow-left"></i>
      </span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma una <b>foto de una factura</b> que tengas con otro
            <b>proveedor</b> y que esté a nombre tuyo.
          </h2>
        </div>

        <div class="six_screen" id="cameraOutput">
          <label for="fileInput3">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'factura')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-factura.jpg"
              alt="FOTO FACTURA"
              *ngIf="!imagenFacturaVisible"
              class="six_selfie"
            />
          </label>

          <div class="main-factura">
            <img [src]="facturaImage" *ngIf="imagenFacturaVisible" />
          </div>
        </div>

        <div class="button_screen" *ngIf="!imagenFacturaVisible">
          <label for="fileInput3">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'factura')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenFacturaVisible">
          <label for="fileInput" class="button-return">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'factura')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br />
      <br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <div class="alert-warning-background" *ngIf="stepNew === 16">
    <div class="alert-warning">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-xmark"></i
      ></span>

      <div class="warning-content">
        <img
          src="../../../../assets/img-news/logo-ilustration.svg"
          alt="Fondo auto"
          class="mx-auto d-block img-fluid pb-2"
          width="120"
          height="120"
        />

        <h1>Autorizaciones</h1>

        <div class="warning">
          <h4 class="text-start">
            <b
              >Paso 1: Tratamiento de datos y verificación en centrales de
              riesgo:</b
            >
          </h4>
          <strong class="pb-2 fw-normal">Selecciona la opción:</strong>
          <div class="d-flex gap-3">
            <div class="checksbox">
              <label for="authorization_treatment_data">
                <input
                  type="checkbox"
                  id="authorization_treatment_data"
                  class="ui-checkbox"
                  [ngClass]="{ 'ct-test': isCheckAuth }"
                  (change)="
                    checkboxChanged('authorization_treatment_data', $event)
                  "
                  name="authorization_treatment_data"
                  formControlName="authorization_treatment_data"
                />
              </label>
            </div>
            <label for="authorization_treatment_data">
              Autorizo a Somos Zíro SAS a consultar mi información en centrales
              de riesgo y a enviarme información. También acepto los términos de
              la 
              <b
                ><a
                  style="color: rgb(0, 89, 255); text-decoration: underline"
                  href="https://somosziro.com/politica-de-privacidad/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Política de Tratamiento de Datos Personales.</a
                ></b
              >
            </label>
          </div>
          <br />
          <h4 class="text-start">
            <b>Paso 2: Autorizo a Somos Ziro SAS a contactarme via:</b>
          </h4>

          <div class="checksbox">
            <label for="comunication_all">
              <input
                type="checkbox"
                id="comunication_all"
                class="ui-checkbox ct-test"
                value="comunication_all"
                name="comunication_all"
                formControlName="comunication_all"
                checked
                onclick="return false;"
                onkeydown="return false;"
              />

              Whatsapp y llamadas telefónicas al número celular registrado en mi
              perfil
            </label>
            <label for="comunication_email">
              <input
                type="checkbox"
                id="comunication_email"
                class="ui-checkbox ct-test"
                value="comunication_email"
                name="comunication_email"
                formControlName="comunication_email"
                checked
                onclick="return false;"
                onkeydown="return false;"
              />
              Correo
            </label>
            <label for="comunication_message">
              <input
                type="checkbox"
                id="comunication_message"
                class="ui-checkbox ct-test"
                value="mensajeTexto"
                (change)="checkboxChanged('comunication_message', $event)"
                name="comunication_message"
                formControlName="comunication_message"
                checked
                onclick="return false;"
                onkeydown="return false;"
              />
              Mensaje de texto
            </label>
          </div>

          <label class="lh-base pt-2 autorizo negrita">
            <b
              >*Comprendo que WhatsApp y correo se utilizarán para
              confirmaciones de pedidos y códigos, y las llamadas telefónicas
              para validar información personal y asuntos relacionados con
              compras, incluyendo posibles cobros</b
            >
          </label>
        </div>
      </div>

      <div class="input-button">
        <button type="button" (click)="register()">Acepto</button>
      </div>
    </div>
  </div>

  <!-- <div class="six_page bl-warning" >
    <div class="container-fluid">
      <div class="row">
        
      </div>
    </div>
  </div> -->

  <!-- <app-alert-success-warning
    class="six_page"
    *ngIf="stepNew === 20"
    titulo="¡Espera unos minutos mientras evaluamos tu solicitud!"
    descripcion="Pronto recibirás un enlace a tu WhatsApp y correo para completar tu registro y activar tu cupo"
  ></app-alert-success-warning> -->
</form>

<app-modal-alert></app-modal-alert>
