import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreditHistoryResponseInterface } from '../interfaces/credits-historyCreditsCurtomer-interface';
import { CreditRequestResponse } from '../interfaces/credits-request-interfaces';
import {
      CreditRequestListPaginatedInterface,
      CreditRequestListResponseInterface,
} from '../interfaces/credits-request-list-interface';


@Injectable({
      providedIn: 'root',
})
export class CreditRequestService {
      /*************
       *  variables**
       *************/
      private baseUrl: string = environment.apiUrl + '/solicitudes-credito';
      private baseUrlNote: string = environment.apiUrl;

      public arlAction: string = 'Crear';
      //refrescar tabla al guardar o actuailzar
      private _refreshCreditsRequests$ = new Subject<void>();
      filterSelecteds: any;

      constructor(private http: HttpClient) { }

      /*************
       *  getters****
       *************/
      get _refreshCreditsRequests() {
            return this._refreshCreditsRequests$;
      }

      /*************
       *  metodos****
       *************/

      //obtener registros
      getCreditsRequests() {
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta`);
      }

      //obtener registros
      getCreditsRequestCardReceived(page: number, params: string = '') {
            // Creamos los HttpParams para los parámetros
            let queryParams = new HttpParams().set('page', page.toString());

             // Iteramos sobre el objeto params para añadir los demás parámetros
            Object.keys(params).forEach((key: any) => {
                  if (params[key] !== null && params[key] !== undefined) {
                  queryParams = queryParams.set(key, params[key]);
                  }
            });

             // Hacemos la solicitud HTTP con los parámetros correctos
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta/received`, { params: queryParams });
      }

      //obtener registros
      getCreditsRequestCardStudy(page: number, params: string = '') {
            // Creamos los HttpParams para los parámetros
            let queryParams = new HttpParams().set('page', page.toString());

             // Iteramos sobre el objeto params para añadir los demás parámetros
            Object.keys(params).forEach((key: any) => {
                  if (params[key] !== null && params[key] !== undefined) {
                  queryParams = queryParams.set(key, params[key]);
                  }
            });
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta/study`, { params: queryParams });
      }

      //obtener registros
      getCreditsRequestCardPreaproved(page: number, params: string = '') {
            // Creamos los HttpParams para los parámetros
            let queryParams = new HttpParams().set('page', page.toString());

             // Iteramos sobre el objeto params para añadir los demás parámetros
            Object.keys(params).forEach((key: any) => {
                  if (params[key] !== null && params[key] !== undefined) {
                  queryParams = queryParams.set(key, params[key]);
                  }
            });
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta/preaproved`, { params: queryParams });
      }

      //obtener registros
      getCreditsRequestCardSmanaged(page: number, params: string = '') {
            // Creamos los HttpParams para los parámetros
            let queryParams = new HttpParams().set('page', page.toString());

             // Iteramos sobre el objeto params para añadir los demás parámetros
            Object.keys(params).forEach((key: any) => {
                  if (params[key] !== null && params[key] !== undefined) {
                  queryParams = queryParams.set(key, params[key]);
                  }
            });
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta/smanaged`, { params: queryParams });   
      }

      //obtener registros lista
      //   getCreditsRequestsList(filterSelecteds: any | null) {
      //     if (filterSelecteds === null) {
      //       return this.http.get<CreditRequestListResponse>(
      //         `${this.baseUrl}/comercio/estado/aprobadas?identification&userIdCommerce&codeCommerce&dateStart&dateEnd`
      //       );
      //     }
      //     console.log('filterSelecteds', filterSelecteds);
      //     return this.http.get<CreditRequestListResponse>(
      //       `${this.baseUrl}/comercio/estado/${filterSelecteds.estado}?identification=${filterSelecteds.identification}&userIdCommerce&codeCommerce&dateStart=${filterSelecteds.dateStart}&dateEnd=${filterSelecteds.dateEnd}`
      //     );
      //   }

      getCreditsRequestsList(page: number) {
            const url = `${this.baseUrl}?page=${page}`;
            return this.http.get<CreditRequestListPaginatedInterface>(url);
      }

      //obtener registros
      passToStudy(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrl}/pasar-estudio`;
            const body = { creditRequestId: creditRequestId };
            return this.http.post(url, body);
      }

      passToPreaproved(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrl}/pasar-preaprobado`;
            const body = { creditRequestId: creditRequestId };
            return this.http.post(url, body);
      }

      passToApproved(
            creditRequestId: number,
            valueApproved: number
      ): Observable<any> {
            const url = `${this.baseUrl}/pasar-aprobado`;
            const body = {
                  creditRequestId: creditRequestId,
                  valueApproved: valueApproved,
            };
            return this.http.post(url, body);
      }

      passToRejected(
            creditRequestId: number,
            reasonReject: string
      ): Observable<any> {
            const url = `${this.baseUrl}/pasar-rechazado`;
            const body = {
                  creditRequestId: creditRequestId,
                  reasonReject: reasonReject,
            };
            return this.http.post(url, body);
      }

      returnToStudy(creditRequestId: number, comment: string): Observable<any> {
            const url = `${this.baseUrl}/regresar-estudio`;
            const body = {
                  creditRequestId: creditRequestId,
                  comment: comment,
            };
            this.getCreditsRequests();
            return this.http.post(url, body);
      }
      saveCustomerNote(creditRequestId: number, note: string): Observable<any> {
            const url = `${this.baseUrlNote}/notas`;
            const body = {
                  creditRequestId: creditRequestId,
                  note: note,
            };
            return this.http.post(url, body);
      }

      saveCommentsCreditRequest(
            creditRequestId: number,
            comment: string,
            type: string
      ): Observable<any> {
            const url = `${this.baseUrl}/agregar/comentario`;
            const body = {
                  type: type,
                  comment: comment,
                  credit_request_id: creditRequestId,
            };
            return this.http.post(url, body);
      }

      getCommentsCreditRequest(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrl}/ver/comentarios/${creditRequestId}`;
            return this.http.get(url);
      }

      getCustomerNotes(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrlNote}/notas/solicitud-credito/${creditRequestId}`;
            return this.http.get(url);
      }
      getCustomerDocuments(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrlNote}/documentos/solicitud-credito/${creditRequestId}`;
            return this.http.get(url);
      }

      getHistoryCreditsCustomers(creditCustomerId: number): Observable<any> {
            const url = `${this.baseUrlNote}/creditos/clientes/info-mora/${creditCustomerId}`;
            return this.http.get<CreditHistoryResponseInterface>(url);
      }


      //traer información del registro
      //   show(id: number) {
      //     return this.http.get<ArlResponse>(`${this.baseUrl}/${id}`);
      //   }

      //filtros solicitudes lista
      //buscar solicitudespaginado
      searchCreditsRequests(formData: any, page: number) {
            const params = new HttpParams({ fromObject: formData });
            const url = `${this.baseUrl}/buscar?page=${page}`;
            return this.http.get<CreditRequestListPaginatedInterface>(url, { params });
      }

      //buscar solicitudessin paginar
      searchCreditsRequestsForExport(formData: any) {
            const params = new HttpParams({ fromObject: formData });
            const url = `${this.baseUrl}/buscar`;
            return this.http.get<CreditRequestListResponseInterface>(url, { params });
      }

      // guardar documentos en la solicitud de credito
      saveCustomerDocument(formdata: any): Observable<any> {
            const url = `${this.baseUrl}/guardar/documentos`;
            // const body = {
            //   credit_request_id: creditRequestId,
            //   file: document,
            // };
            return this.http.post(url, formdata);
      }
      returnToInRequest(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrl}/regresar-a-en-solicitud`;
            const body = {
                  creditRequestId: creditRequestId,
            };
            this.getCreditsRequests();
            return this.http.post(url, body);
      }

      updateInfoCustomer(customer: any) {
            return this.http.post<any>(`${this.baseUrlNote}/clientes/update-customer`, customer);
      }

      getHistoryRequestCredit(creditRequestId: number): Observable<any> {
            return this.http.get<any>(`${this.baseUrl}/history/${creditRequestId}`);
      }

      sendPagareLink(creditRequestId: number): Observable<any> {
            const url = `${this.baseUrl}/enviar-pagare/${creditRequestId}`;
            return this.http.post(url, {});
      }

      public downloadFile(filePath: string) {
            return this.http.get(`${this.baseUrl}/download-file/${filePath}`, { responseType: 'blob' })
      }

      verifyPromissoryNoteSignature(customer_id: number) {
            return this.http.get(`${this.baseUrl}/tarjeta/verify-promissory-note-signature/${customer_id}`)
      }
}
