import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentServiceCustomer {
  /**
   * URL base del servicio de autenticación.
   */
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  identification(identification: number) {
    // Construye la URL completa para enviar la solicitud HTTP GET
    const url = `${this.baseUrl}/general/fastpayment/${identification}`;
    
    // Realiza la solicitud HTTP GET a la URL construida
    return this.http.get(url);
  }

  createLink(object: any) {
    return this.http.post<any>(this.baseUrl + "/v1/create/link", object);
  }

  getCorrespondents() {
    return this.http.get<any>(this.baseUrl + "/corresponsales");
  }

  getCustomerBydentification(identification: number) {
    return this.http.get<any>(this.baseUrl + "/proceso-pagos/consultar-cliente/" + identification);
  }

  getDebetsByTotalOrMin(identification: string, type:string) {
      return this.http.get<any>(this.baseUrl + "/proceso-pagos/cliente/creditos/" + identification + "/" + type);
  }

}
