<div class="container">
        <header class="first">
                <p class="first-text">Valor a pagar {{ abono }}: </p>
                <h1 class="price"> {{ returnValueToPay() | numberFormat }} </h1>
        </header>
        <div class="body">
                <div class="card">
                        <div class="card-body">
                                <div class="view1">
                                        <p *ngIf="currentPage == 1" class="first-text fw-bold mb-2">Seleccione el
                                                corresponsal:</p>
                                        <p *ngIf="currentPage == 1" class="second-text mb-5">Genera referencia de pago y
                                                número de convenio:</p>
                                        <div (click)="changeView('bbva', 0)" class="target">
                                                <h4>Corresponsal bancario BBVA</h4>
                                                <div class="image">
                                                        <img *ngIf="correspondents[0]" [src]="correspondents[0].url_img"
                                                                [alt]="correspondents[0].name">
                                                </div>
                                                <span>></span>
                                        </div>

                                        <div *ngIf="message == 'bbva'" class="info view2">
                                                <p class="message mb-3"> Pregunta en cualquiera de estos corresponsales
                                                        por el convenio BBVA y suministra la siguiente información: </p>
                                                <div class="reference">
                                                        <p><span class="fw-bold">Número de convenio: </span> {{
                                                                choosedCorrespondent.agreement_number }} </p>
                                                        <p><span class="fw-bold mb-3">Referencia de pago: </span> {{
                                                                data.customer.external_id }} </p>
                                                </div>
                                                <a href="https://wa.me/3142051091">
                                                        <button>
                                                                <p class="principal-text">Enviar comprobante de pago</p>
                                                                <span> > </span>
                                                        </button>
                                                </a>
                                        </div>


                                        <div (click)="changeView('bancolombia', 1)" class="target Bancolombia">
                                                <h4>Corresponsal Bancolombia</h4>
                                                <div class="image">
                                                        <img *ngIf="correspondents[1]" [src]="correspondents[1].url_img"
                                                                [alt]="correspondents[1].name">
                                                </div>
                                                <span>></span>
                                        </div>
                                        <div *ngIf="message == 'bancolombia'" class="view2">
                                                <p class="message mb-3"> Para realizar el pago por corresponsal Bancolombia suministra la siguiente información: </p>
                                                <div class="reference">
                                                        <p><span class="fw-bold">Número de convenio: </span> {{
                                                                choosedCorrespondent.agreement_number }} </p>
                                                        <p><span class="fw-bold mb-3">Referencia de pago: </span> {{
                                                                showNumberForBancolombia(data.customer.external_id) }} </p>
                                                        <!-- <table class="table">
                                                                <thead>
                                                                        <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Valor</th>
                                                                                <th scope="col">Referencia</th>
                                                                        </tr>
                                                                </thead>
                                                                <tbody>
                                                                        <tr
                                                                                *ngFor="let item of payment_reference; let i=index">
                                                                                <th> {{ i +1 }} </th>
                                                                                <td> {{ item.total_balance |
                                                                                        numberFormat }} </td>
                                                                                <td> {{ item.code_pay }} </td>
                                                                        </tr>
                                                                </tbody>
                                                        </table> -->
                                                </div>
                                                <a href="https://wa.me/3142051091">
                                                        <button>
                                                                <p class="principal-text">Enviar comprobante de pago</p>
                                                                <span> > </span>
                                                        </button>
                                                </a>
                                        </div>
                                </div>
                        </div>
                </div>
                <app-terms-conditions></app-terms-conditions>
        </div>
</div>
