import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/login/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
        constructor(private authService: AuthService, private router: Router) { }

        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
                const token = this.authService.getToken();

                if (request.url.endsWith('/auth')) {
                        // Si la URL termina con '/auth', la petición se maneja sin modificación
                        return next.handle(request);
                }

                // Comprobar si la URL de la petición comienza con la URL de la API deseada
                if (token && (request.url.startsWith('https://zirov2back.somosziro.com/api') || request.url.includes('https://desarrollo.somosziro.com:8000/api') || request.url.includes('http://127.0.0.1:8000') || request.url.includes('localhost'))
                        || request.url.includes('http://64.23.161.235')) {
                        request = request.clone({
                                setHeaders: {
                                        Authorization: `Bearer ${token}`
                                }
                        });
                }

                return next.handle(request).pipe(
                        catchError((error) => {
                                if (error.status === 401 && error.error && error.error.message === 'No autenticado.') {
                                        // Eliminar todo del LocalStorage
                                        localStorage.clear();
                                        // Redirigir al usuario a la página de inicio de sesión
                                        this.router.navigate(['/iniciar-sesion']);
                                }
                                return throwError(error);
                        })
                );
        }
}
