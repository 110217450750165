import { Component, Input } from '@angular/core';
import { TotalShopQuotaInterface } from '../../interfaces/commerce-dashboard.interface';

@Component({
  selector: 'app-coupon-dashboard',
  templateUrl: './coupon-dashboard.component.html',
  styleUrls: ['./coupon-dashboard.component.scss']
})
export class CouponDashboardComponent {

  // TODO: Input
  @Input() public dataCoupon: TotalShopQuotaInterface
  @Input() public date: string


  public converterNumber(number: string | null | undefined): number {
    if (!number) {
      return 0; // o cualquier valor predeterminado que consideres adecuado
    }
    return parseFloat(number.replace(/,/g, ''));
  }

}
