<ng-template #modalCustomersSearch let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Busca el estado actual del cliente!
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cancelar')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 mb-3">
        <label for="customerIdentification">Escribe la cédula del cliente</label>
        <div class="d-flex gap-1">
          <input autofocus class="form-control" placeholder="Cédula del cliente" [(ngModel)]="customerIdentification" />
          <div class="input-group-append">
            <button class="btn btn-primary h-100" type="button" (click)="searchCustomer()">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="flagSearchCustomer && !customer" class="w-100">
        <h3 class="text-center text-info">
          El cliente aún no está en el sistema
        </h3>
      </div>

      <div *ngIf="customer">
        <div class="row">
          <div class="col-12 mb-2">
            <h4>Información del Cliente:</h4>
          </div>
        </div>

        <!-- total cupo aprobado -->
        <div class="d-flex flex-column">
          <div class="d-flex gap-2">
            <div class="card mb-2 w-100 border border-success border-opacity-25 rounded position-relative">
              <div class="card-body p-3">
                <h4 class="card-title">Cupo Aprobado</h4>
                <h2>${{ totalCupoAprobado | number }}</h2>
                <div>
                  <p class="card-text">¡Total cupo aprobado del cliente!</p>
                  <p *ngIf="mora" class="fw-bold text-danger">
                    Cupo bloqueado por mora
                  </p>
                </div>
              </div>
              <div class="position-absolute end-0 top-0 pe-2" *ngIf="role === 1">
                <button type="button" (click)="customerEditCupo(customer)"
                  class="btn btn-info btn-xs w-100 mt-2 py-2 d-flex align-items-center justify-content-center"
                  [disabled]="customer.pagare_signed === 'No' || mora" placement="top" ngbTooltip="Editar cupo">
                  <i class="fa-solid fa-wand-magic-sparkles"></i>
                </button>
              </div>
            </div>
            <div class="card mb-2 w-100 border border-success border-opacity-25 rounded position-relative">
              <!-- <div
                class="position-absolute end-0 top-0 pe-2"
                *ngIf="role === 1"
              >
                <button
                  type="button"
                  (click)="customerBlockedCupo(customer)"
                  class="btn btn-danger btn-xs w-100 mt-2 py-2 d-flex align-items-center justify-content-center"
                  placement="top"
                  ngbTooltip="Bloquear cupo"
                >
                <i class="fa-solid fa-ban"></i>
                </button>
              </div> -->
              <div class="card-body p-3">
                <h4 class="card-title">Cupo Disponible</h4>
                <h2>${{ totalCupoDisponibleCompras | number }}</h2>
                <div>
                  <p class="card-text">¡Total cupo disponible para compras!</p>
                  <p *ngIf="mora" class="fw-bold text-danger">
                    Cupo bloqueado por mora
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2 w-100 border border-success border-opacity-25 rounded"
            *ngIf="customer.pagare_signed === 'No'">
            <div class="d-flex justify-content-between gap-2">
              <div class="card-body p-3">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="card-title text">&nbsp;Cupo no activado</h4>
                </div>
                <div>
                  <p class="card-text">
                    &nbsp; El cliente aún no ha firmado el pagaré.
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center p-3">
                <i class="fa fa-exclamation-circle mr-2 text-danger" style="font-size: 30px"></i>
              </div>
            </div>
          </div>

          <div class="card mb-2 w-100 border border-success border-opacity-25 rounded"
            *ngIf="customer.pagare_signed == 'Si' && customer.state == 'Inactivo'">
            <div class="d-flex justify-content-between gap-2">
              <div class="card-body p-3">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="card-title text">&nbsp;Firma completada, activación pendiente</h4>
                </div>
                <div>
                  <p class="card-text">
                    &nbsp; El cliente ha firmado el pagaré, pero la activación está pendiente.
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center p-3">
                <i class="fa fa-exclamation-circle mr-2 text-warning" style="font-size: 30px"></i>
              </div>
            </div>
          </div>


          <!-- datos del cliente -->
          <div class="d-flex flex-column gap-1 w-100 border border-success border-opacity-25 p-3 rounded">
            <div class="row mb-2">
              <div class="col-8">
                <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Nombre del Cliente:</strong>
              </div>
              <div class="col-4">
                <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ customer.name }} / {{ customer.id }}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-8">
                <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Solicitudes en Proceso:</strong>
              </div>
              <div class="col-4">
                <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totalProcessPending }}
                </span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-8">
                <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Solicitudes Rechazadas:</strong>
              </div>
              <div class="col-4">
                <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totalReject }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Créditos Activos:</strong>
              </div>
              <div class="col-4">
                <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totalActiveCredits }}
                </span>
              </div>
            </div>
          </div>

          <div class="row mb-3 mt-2" 
            *ngIf="
              totalCupoDisponibleCompras>0 &&
              !mora &&
              customer.pagare_signed == 'Si' &&
              roleCanWithdrawCreditAction &&
              customer.state == 'Activo'
            "
          >
            <label for="shopCommerce">Selecciona una sucursal</label>
            <div class="d-flex gap-1 w-100">
              <div class="col-7">
                <!-- <select
                class="form-control"
                id="shopCommerce"
                [(ngModel)]="shopCommerceId"
              >
                <option
                  *ngFor="let shopCommerce of shopsCommerces"
                  [value]="shopCommerce.id"
                >
                  {{ shopCommerce.name }}
                </option>
              </select> -->
                <ng-select [items]="shopsCommerces" bindLabel="name" bindValue="id" id="shopCommerce"
                  [(ngModel)]="shopCommerceId" placeholder="Seleccionar Sucursal">
                </ng-select>
              </div>
              <button class="btn btn-primary w-100 h-100" (click)="confirmCreditWithdrawal()">
                <i class="fas fa-coins"></i> Solicitar retiro de crédito
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Cancelar')"
    >
      <i class="fa fa-times"></i> Cerrar
    </button>
  </div> -->
</ng-template>

<!-- modal retiro credito -->
<app-withdraw-credit />