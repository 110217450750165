import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommerceDashboardResponseInterface } from '../interfaces/commerce-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class CommerceDashboardService {

    /**
     * La URL base para las solicitudes relacionadas con los clientes en la API.
    */
    private readonly baseUrl = environment.apiUrl + '/comercio';
    
   /**
     * Constructor de la clase que recibe una instancia de HttpClient para realizar solicitudes HTTP.
     *
     * @param {HttpClient} http - Instancia de HttpClient utilizada para las solicitudes HTTP.
    */
    constructor(private http: HttpClient) { }


    /**
     * Obtiene los datos del panel de control del cliente desde el servidor.
     *
     * @returns {Observable<CommerceDashboardResponseInterface>} 
    */
    getDataDashboard() {
        const url = `${this.baseUrl}/reportes/inicio`;
        return this.http.get<CommerceDashboardResponseInterface>(url);
    }


     /**
   * Este metodo nos sirve para 
   * calcular el porcentaje depeniendo de las ventas actuales y anteriores
   * @param currentValue : Valor actual ( dia )
   * @param previousValue : Valor anterior ( dia )
   * @param type : Valor tipo si es cliente o ventas o pedidos
   * @returns { returns } : Retorna el mensaje dependiendo el tipo 
   */
    public calculateChange(currentValue: number, previousValue: number, type: string, period: string): string {
      const difference = currentValue - previousValue;
      const percentageChange = previousValue !== 0 ? (difference / previousValue) * 100 : 0;
  
      const sign = percentageChange >= 0 ? '+' : '-';
      const absolutePercentageChange = Math.abs(percentageChange).toFixed(2);
  
      const noActivityMessage = 'No ha habido actividad';
  
      switch (type) {
        case 'clientes':
          switch (period) {
            case 'dia':
              return currentValue === 0 && previousValue === 0 
                ? 'No ha habido nuevos clientes' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% desde ayer.`;
            case 'mes':
              return currentValue === 0 && previousValue === 0 
                ? 'No ha habido nuevos clientes este mes' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% del mes anterior.`;
            default:
              return 'Periodo no reconocido';
          }
        case 'órdenes':
          switch (period) {
            case 'dia':
              return currentValue === 0 && previousValue === 0 
                ? 'No hubo pedidos hoy ni ayer' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% desde ayer.`;
            case 'mes':
              return currentValue === 0 && previousValue === 0 
                ? 'No hubo pedidos este mes' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% este mes.`;
            default:
              return 'Periodo no reconocido';
          }
        case 'ventas':
          switch (period) {
            case 'dia':
              return currentValue === 0 && previousValue === 0 
                ? 'No hubo ventas hoy ni ayer' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% desde ayer.`;
            case 'mes':
              return currentValue === 0 && previousValue === 0 
                ? 'No hubo ventas este mes' 
                : percentageChange === -100
                  ? noActivityMessage
                  : `${sign}${absolutePercentageChange}% este mes.`;
            default:
              return 'Periodo no reconocido';
          }
        default:
          return 'Tipo de calculo no reconocido';
      }
  }
  

   
}
