import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CreditLineInterface } from "src/app/administrative/credit-lines/interfaces/credits-lines-interface";
import { CreditLineService } from "src/app/administrative/credit-lines/services/credit-line.service";
import { CreditsService } from "src/app/administrative/credits/services/credits.service";
import { PayFrequiencieInterface } from "src/app/administrative/parameters/submodules/pays-frequencies/interfaces/pays-frequiencies-interface";
import { formatCurrency, getNumericOnly, toggleLoader, validateCampo } from "src/app/shared/helpers/functions-helper.service";
import { ParemetersService } from "src/app/shared/services/parameters.service";
import Swal from "sweetalert2";
import { CustomerHistorialInterface } from "src/app/administrative/customers/services/customer-historial-interface";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { formatCurrencyInput } from '../../../helpers/functions-helper.service';

@Component({
	selector: "app-withdraw-credit",
	templateUrl: "./withdraw-credit.component.html",
	styleUrls: ["./withdraw-credit.component.scss"],
})
export class WithdrawCreditComponent {
	/*****************
	 ***** variables***
	 ******************/
	//tomamos referencia del html que tiene #modalRequestWithdrawalComponent
	@ViewChild("modalRequestWithdrawal") public modalRequestWithdrawalContent!: TemplateRef<WithdrawCreditComponent>;
	private modalRef!: NgbModalRef;
	closeResult = "";
	validateCampo = validateCampo;
	formWithdrawCredit: FormGroup;
	//tipos de frecuencias de pago
	paidFrequencies: PayFrequiencieInterface[] = [];
	//lineas de credito
	creditsLinesShop: CreditLineInterface[] = [];
	numberCuotesOptions: number[] = [];
	shopCommerceId: number;
	customer: CustomerHistorialInterface;
	creditId: number = 0;
	flagShowCodeInput = false;
    flagConfirmCreditAction=false;
    flagReadonly=false;
    formatCurrencyInput = formatCurrencyInput;
    formatCurrency = formatCurrency;
    getNumericOnly = getNumericOnly;
	//constructor
	constructor(
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private parameterService: ParemetersService,
		private creditLineService: CreditLineService,
		private creditService: CreditsService,
        private loaderService: LoaderService
	) {}

	//ngOnInit
	ngOnInit(): void {
		this.initForm();
		// this.getFrecuenciasPago();
		// this.getCreditsLinesByShop();
		// Detectar cambios en la selección de la línea de crédito
		// this.setupFormCreditLineChanges();
	}

	//metodo para inicializar el formulario
	initForm(): void {
		this.formWithdrawCredit = this.formBuilder.group({
            order_number : ['', Validators.required],
			creditLine: ["", Validators.required],
			valueCredit: ["", Validators.required],
			// numberCuotesCredit: ["", Validators.required],
			valueCuote: ["", Validators.required],
            codeSendToCustomer: ['', Validators.required],
		});
	}


    formatCurrencyInputAction(event: Event, showSymbol: boolean): void {
        formatCurrencyInput(this.formWithdrawCredit, 'valueCredit', event, showSymbol);
    }


	//metodo para obtener las frecuencias de pago
	getFrecuenciasPago(): void {
		this.parameterService.getPaysFrequencies().subscribe(
			(response) => {
				if (response.ok) {
					this.paidFrequencies = response.data;
					// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
				} else {
					Swal.fire(
						"Error",
						"Ocurrió un error al obtener las frecuencias de pago",
						"error"
					);
				}
				// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
			},
			(error) => {
				const errorMessage =
					"Ocurrió un error al obtener las frecuencias de pago: " +
					error;
				console.log(errorMessage);
				Swal.fire("Error", errorMessage, "error");
			}
		);
	}

	//metodo para obtener las lineas de credito por tienda
	getCreditsLinesByShop(shopCommerceId:number): void {
		this.creditLineService.getCreditsLinesByShop(shopCommerceId).subscribe(
			(response) => {
				if (response.ok) {
					this.creditsLinesShop = response.data;
					// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
				} else {
					Swal.fire(
						"Error",
						"Ocurrió un error al obtener las linéas de crédito",
						"error"
					);
				}
				// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
			},
			(error) => {
				const errorMessage =
					"Ocurrió un error al obtener las linéas de crédito: " +
					error;
				console.log(errorMessage);
				Swal.fire("Error", errorMessage, "error");
			}
		);
	}

	//metodo para abrir el modal
	openModalWithdrawCreditComponent(
		valueCredit: number,
		shopCommerceId: number,
		customer: CustomerHistorialInterface
	) {
        toggleLoader(this.loaderService, true);
		this.shopCommerceId = shopCommerceId;
		this.customer = customer;
		this.formWithdrawCredit.get("valueCredit")?.setValue(formatCurrency(valueCredit, false));
        this.flagConfirmCreditAction=false;
        this.creditLineService.getCreditsLinesByShop(shopCommerceId).subscribe(
			(response) => {
                toggleLoader(this.loaderService, false);
				if (response.ok) {
                    // this.setupFormCreditLineChanges();
					this.creditsLinesShop = response.data;
                    this.modalRef = this.modalService.open(
                        this.modalRequestWithdrawalContent,
                        {
                            size: "lg", // Aquí se establece el tamaño del modal, en este caso, "lg" para grande
                        }
                    );
                    this.modalRef.result.then(
                        (result) => {
                            this.closeResult = `Closed with: ${result}`;
                        },
                        (reason) => {
                            this.closeResult = `Dismissed ${reason}`;
                        }
                    );
					// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
				} else {
                    toggleLoader(this.loaderService, false);
					Swal.fire(
						"Error",
						"Ocurrió un error al obtener las linéas de crédito",
						"error"
					);
				}
				// Aquí puedes realizar cualquier otra lógica con los datos obtenidos
			},
			(error) => {
                toggleLoader(this.loaderService, false);
				const errorMessage =
					"Ocurrió un error al obtener las linéas de crédito: " +
					error;
				console.log(errorMessage);
				Swal.fire("Error", errorMessage, "error");
			}
		);
	}


    //cerrar el modal
    closeModalWithdrawCredit() {
        this.modalRef.close();
    }

    //cerrar el modal dando click por fuera de el
    dismissModalWithdrawCredit() {
        this.modalRef.dismiss()
    }

	setupFormCreditLineChanges(): void {
		this.formWithdrawCredit
			.get("creditLine")
			?.valueChanges.subscribe(() => {
				this.handleCreditLineChange();
			});
		this.handleCreditLineChange();
	}


	//metodo para generar las opciones de cuotas
	handleCreditLineChange(): void {
        toggleLoader(this.loaderService, true);
		const selectedCreditLineId =
			this.formWithdrawCredit.get("creditLine")?.value;
		const selectedCreditLine = this.creditsLinesShop.find(
			(creditLine) => creditLine.id == selectedCreditLineId
		);

		if (selectedCreditLine && selectedCreditLine.total_months) {
			this.generateNumberCuotesOptions(selectedCreditLine.total_months);

			if (selectedCreditLine.total_months === 1) {
				this.formWithdrawCredit.patchValue({
					numberCuotesCredit: 1,
				});
			} else {
				this.formWithdrawCredit.patchValue({
					numberCuotesCredit: null,
				});
			}
            toggleLoader(this.loaderService, false);
		} else {
			this.clearNumberCuotesOptions();
			this.formWithdrawCredit.patchValue({
				numberCuotesCredit: null,
			});
            toggleLoader(this.loaderService, false);
		}
	}

	//metodo para generar las opciones de cuotas
	generateNumberCuotesOptions(totalMonths: number): void {
		this.numberCuotesOptions = Array.from(
			{ length: totalMonths },
			(_, i) => i + 1
		);
	}

	//metodo para limpiar las opciones de cuotas
	clearNumberCuotesOptions(): void {
		this.numberCuotesOptions = [];
	}

	/**
     * Calcular el valor de la cuota del crédito.
    */
    calculateQuote(): void {
        const { valueCredit, creditLine } = this.formWithdrawCredit.value;
        const numberCuotesCredit = 1; // Número de cuotas del crédito

        // Validar que todos los campos estén llenos
        if (!numberCuotesCredit || !valueCredit || !creditLine) {
            Swal.fire("Error", "Por favor, complete todos los campos", "error");
            return; // Salir de la función si faltan campos por llenar
        }

        // Mostrar el indicador de carga
        toggleLoader(this.loaderService, true);

        // Llamar al servicio para obtener el valor de la cuota
        if (numberCuotesCredit && valueCredit && creditLine) {
            this.creditService.getQuoteValue(numberCuotesCredit, valueCredit, creditLine).subscribe(
                (response) => {
                    // Ocultar el indicador de carga
                    toggleLoader(this.loaderService, false);

                    if (response.ok) {
                        // Asignar el valor de la cuota al formulario
                        this.formWithdrawCredit.patchValue({
                            valueCuote: response.data,
                        });
                    } else {
                        Swal.fire("Error", "Ocurrió un error al obtener el valor de la cuota", "error");
                    }
                },
                (error) => {
                    // Ocultar el indicador de carga
                    toggleLoader(this.loaderService, false);

                    Swal.fire("Error", "Ocurrió un error en la solicitud", "error");
                }
            );
        }
    }


	/**
	 * Verifica el número de orden y envía el código OTP.
	 * 
	 * Este método primero valida que todos los campos necesarios estén llenos y que los valores de crédito y cuota sean iguales.
	 * Si la bandera `flagShowCodeInput` está activada, envía el código OTP sin verificar el número de orden.
	 * De lo contrario, verifica que el número de orden no exista antes de enviar el código OTP.
	 */
	verifyOrderNumberAndSendCodeOtp(): void {
		const {
			valueCredit,
			valueCuote,
			creditLine,
            order_number
		} = this.formWithdrawCredit.value;
        const numberCuotesCredit=1;
		// Validar que todos los campos estén llenos
		if (
			!numberCuotesCredit ||
			!valueCredit ||
			!valueCuote ||
			!creditLine ||
            !order_number
		) {
			Swal.fire("Error", "Por favor, complete todos los campos", "error");
			return; // Salir de la función si faltan campos por llenar
		}

        if (getNumericOnly(valueCredit) != getNumericOnly(valueCuote)) {
            Swal.fire("Error", "Los valores de crédito y cuota deben ser iguales, de click en calcular cuota", "error");
            return; // Salir de la función si los valores no son iguales
        }

		if (this.flagShowCodeInput) {
			// Enviar el código OTP sin verificar el número de orden
			this.sendCodeOtp(order_number, numberCuotesCredit, valueCredit, valueCuote, creditLine);
			return;
		}
        
        const messageError="Ya existe una solicitud de crédito con el número de orden ingresado";
		
        toggleLoader(this.loaderService, true, 'Verificando número de orden...');
		// Verificar que el número de orden no exista
		this.creditService.verifyOrderNumberNotExists(order_number).subscribe({
			next: (response) => {
				// Llamar al servicio para enviar el código OTP
				this.sendCodeOtp(order_number, numberCuotesCredit, valueCredit, valueCuote, creditLine);
			},
			error: (error) => {
				toggleLoader(this.loaderService, false);
				Swal.fire("Error", error.error.message || messageError, "error");
			},
		});
	}

	/**
	 * Envía un código OTP para la verificación del crédito.
	 * 
	 * Este método envía una solicitud para generar y enviar un código OTP al cliente.
	 * Muestra un loader mientras se procesa la solicitud y maneja las respuestas y errores adecuadamente.
	 * 
	 * @param order_number - El número de orden del crédito.
	 * @param numberCuotesCredit - El número de cuotas del crédito.
	 * @param valueCredit - El valor total del crédito.
	 * @param valueCuote - El valor de cada cuota del crédito.
	 * @param creditLine - La línea de crédito.
	 */
	sendCodeOtp(order_number: any, numberCuotesCredit: any, valueCredit: any, valueCuote: any, creditLine: any): void {
		const messageError="Ocurrió un error al enviar el código OTP";
	
		toggleLoader(this.loaderService, true, 'Enviando código OTP...');

		this.creditService
		.sendCodeOtp(
			order_number,
			numberCuotesCredit,
			valueCredit,
			valueCuote,
			this.customer.id,
			this.shopCommerceId,
			creditLine
		)
		.subscribe(
			(response) => {
				toggleLoader(this.loaderService, false);
				if (response.ok) {
					this.flagShowCodeInput = true;
					//credito id
					this.creditId = response.data.id;
					Swal.fire(
						"Éxito",
						"Código OTP enviado correctamente",
						"success"
					);
				} else {
					Swal.fire(
						"Error",
						response.message || messageError,
						"error"
					);
				}
			},
			(error) => {
				toggleLoader(this.loaderService, false);
				Swal.fire(
					"Error",
					error.error.message || messageError,
					"error"
				);
			}
		);
	}

    //metodo para validar el codigo otp
	validateCodeOtp(): void {
		const { codeSendToCustomer } = this.formWithdrawCredit.value;
		// Validar que todos los campos estén llenos
		if (codeSendToCustomer == '' || codeSendToCustomer == null) {
            Swal.fire("Error", "Por favor, complete el código", "error");
            return; // Salir de la función si el campo está vacío o es null
        }
        
		if (this.creditId == 0) {
			Swal.fire("Error", "Error al validar codigo OTP", "error");
			return; // Salir de la función si faltan campos por llenar
		}
        toggleLoader(this.loaderService, true); 
		// Llamar al servicio para enviar el código OTP
        const messageError="Ocurrió un error al validar el código OTP";

		this.creditService
        .validateCodeOtp(codeSendToCustomer, this.creditId)
        .subscribe(
            (response) => {
                toggleLoader(this.loaderService, false); 
                if (response.ok) {
                    this.flagConfirmCreditAction = true;
                    Swal.fire("Éxito", "Códigos validados correctamente, ya puedes confirmar el crédito", "success");
                } else {
                    Swal.fire("Error", response.message || messageError, "error");
                }
            },
            (error) => {
                toggleLoader(this.loaderService, false); 
                Swal.fire(
                    "Error",
                    error.error.message || messageError,
                    "error"
                );
            }
        );
	}

    //metodo para aplicar el credito
    applyCredit() {
		if (this.creditId == 0) {
			Swal.fire("Error", "Por al realizar el crédito", "error");
			return; // Salir de la función si faltan campos por llenar
		}
        toggleLoader(this.loaderService, true); 
        const messageError="Ocurrió un error al aplicar el crédito";

		// Llamar al servicio para enviar el código OTP
		this.creditService
        .applyCredit(this.creditId)
        .subscribe(
            (response) => {
                toggleLoader(this.loaderService, false); 
                if (response.ok) {
                    const { valueCredit } = this.formWithdrawCredit.value;
                    this.closeModalWithdrawCredit();
                    Swal.fire('Éxito', `El crédito del cliente ${this.customer.identification} por valor: ${valueCredit}, ha sido aplicado correctamente`, 'success');
                } else {
                    Swal.fire("Error", response.message || messageError, "error");
                }
            },
            (error) => {
                toggleLoader(this.loaderService, false); 
                Swal.fire(
                    "Error",
                    error.error.message || messageError,
                    "error"
                );
            }
        );
    }
}
