<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex justify-content-center px-4 align-items-center ">
    <div class="d-flex flex-column" >
        <header>
            <h2 class="fs-2 m-0 pb-2"> ¿Cuánto desea pagar? </h2>
        </header>

        <app-list-group (eventOption)="setOptionsChoose($event)" [items]="itemsStep"></app-list-group>

        <div *ngIf="showInputAbono" class="my-2">
            <form (ngSubmit)="eventSendValueAbono()" [formGroup]="form">
                <app-inpit-document icon="fa-solid fa-file-invoice-dollar" label="" [price]="true" formControlName="abono"></app-inpit-document>
                <button type="submit" class="btn-pay">Iniciar Pago</button>
            </form>
        </div>


        
        <app-terms-conditions></app-terms-conditions>

    </div>
</div>
