<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex justify-content-center px-4 align-items-center ">
      <div class="d-flex flex-column" >
            <header class="first" *ngIf="dataUserPayment.type == 1">
                <p class="last-text"> Selecciona credito a pagar </p>
            </header>
    <div class="d-flex flex-column" >
        <header class="first" *ngIf="dataUserPayment.type == 2">
            <p class="first-text">Valor pago total ordenes entregadas:</p>
            <h1 class="price"> {{  showPrice() | numberFormat }} </h1>
            <p *ngIf="dataUserPayment.payTotMin === 0" class="second-text" > Fecha límite de pago: </p>
            <p *ngIf="dataUserPayment.payTotMin === 0" class="last-text"> {{ dataUserPayment.next_due_date != '' ? (dataUserPayment.next_due_date | customDateFormat) : '12 de Marzo 2024'  }} </p>

            <button (click)="pay()" class="pagar">
                <p>Pagar</p>
                <i class="fas fa-chevron-right"></i>
            </button>
        </header>

        <header class="second" *ngIf="dataUserPayment.type == 'abono'">

        </header>

        <app-list-group (eventChooseCreditOldToPay)="chooseCreditOldToPay($event)" [iconState]="true" [items]="dataList"></app-list-group>
        
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
