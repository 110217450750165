import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payment-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() stateImg: boolean = true;
  @Input() smallIconUser: boolean = true;
  @Input() title: string = '';
  @Output() backButtonEvent = new EventEmitter<string>() ;
  
  backButon() {
    this.backButtonEvent.emit('back');
  }
}
