import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { environment } from 'src/environments/environment';
import { PaymentServiceCustomer } from '../../service/payment-service.service';

@Component({
        selector: 'app-payment-view4',
        templateUrl: './payment-view4.component.html',
        styleUrls: ['./payment-view4.component.scss'],
})
export class PaymentView4Component {
        @Output() eventOption = new EventEmitter<string>();
        @Input() itemsStep: any = [];
        @Input() choosedCreditOld: any;
        paymentMethod: any;
        infoModal: any;
        showModal: boolean = false;

        constructor(
                private ModalServiceAlert: ModalServiceAlert,
                private payment_service: PaymentServiceCustomer,
                public loaderService: LoaderService
        ) { }

        ngOnInit() {
                this.itemsStep = this.choosedCreditOld.option_method;
        }

        setOptionsChoose(option: string) {
                // Pagos digitales y en el el se va para pagos en corresponsal
                toggleLoader(this.loaderService, true);

                this.choosedCreditOld.payment_method = option;
                if (option == '0') {
                        // just filter customer dates
                        var customer = {
                                name: this.choosedCreditOld.customer.name,
                                last_name: this.choosedCreditOld.customer.last_name,
                                email: this.choosedCreditOld.customer.email,
                                phone_number: this.choosedCreditOld.customer.phone_number,
                                requires_account: false,
                                external_id: this.choosedCreditOld.customer.external_id,
                                customer_address: {
                                        department:
                                                this.choosedCreditOld.customer.customer_address.department,
                                        city: this.choosedCreditOld.customer.customer_address.city,
                                        additional:
                                                this.choosedCreditOld.customer.customer_address.additional,
                                },
                        };

                        var total_credit;
                        //Logica para asegurar que el valor sea superior a 1000
                        if (this.choosedCreditOld.credit.type == 2) {
                                if (this.choosedCreditOld._credit_choosed == 2) {
                                        if (
                                                typeof this.choosedCreditOld.abono !== 'undefined' &&
                                                typeof this.choosedCreditOld.abono === 'number' &&
                                                this.choosedCreditOld.abono < 1000
                                        ) {
                                                this.ModalServiceAlert.openModal(
                                                        '¡Alerta!',
                                                        `El valor de abono debe ser igual o mayor a $1000`,
                                                        'warning'
                                                );
                                                toggleLoader(this.loaderService, false);
                                                return;
                                        }
                                        total_credit = this.choosedCreditOld.abono;
                                } else {
                                        if (
                                                typeof this.choosedCreditOld.total_credit !== 'undefined' &&
                                                typeof this.choosedCreditOld.total_credit === 'number' &&
                                                this.choosedCreditOld.total_credit < 1000
                                        ) {
                                                this.ModalServiceAlert.openModal(
                                                        '¡Alerta!',
                                                        `El valor de abono debe ser igual o mayor a $1000`,
                                                        'warning'
                                                );
                                                toggleLoader(this.loaderService, false);
                                                return;
                                        }
                                        total_credit =
                                                this.choosedCreditOld.credit.payTotMin == 0
                                                        ? this.choosedCreditOld.credit.min_payment
                                                        : this.choosedCreditOld.credit.total_payment;
                                }
                        } else if (this.choosedCreditOld.credit.type == 1) {
                                if (this.choosedCreditOld._credit_choosed == 2) {
                                        if (
                                                typeof this.choosedCreditOld.abono !== 'undefined' &&
                                                typeof this.choosedCreditOld.abono === 'number' &&
                                                this.choosedCreditOld.abono < 1000
                                        ) {
                                                this.ModalServiceAlert.openModal(
                                                        '¡Alerta!',
                                                        `El valor de abono debe ser igual o mayor a $1000`,
                                                        'warning'
                                                );
                                                toggleLoader(this.loaderService, false);
                                                return;
                                        }
                                        total_credit = this.choosedCreditOld.abono;
                                } else {
                                        if (
                                                typeof this.choosedCreditOld.total_credit !== 'undefined' &&
                                                typeof this.choosedCreditOld.total_credit === 'number' &&
                                                this.choosedCreditOld.total_credit < 1000
                                        ) {
                                                this.ModalServiceAlert.openModal(
                                                        '¡Alerta!',
                                                        `El valor de abono debe ser igual o mayor a $1000`,
                                                        'warning'
                                                );
                                                toggleLoader(this.loaderService, false);
                                                return;
                                        }
                                        total_credit = this.choosedCreditOld.total_credit;
                                }
                        }

                        // Que el valor no este vacio
                        if (!total_credit) {
                                this.ModalServiceAlert.openModal(
                                        '¡Alerta!',
                                        `El valor de abono esta vacio ${total_credit}`,
                                        'warning'
                                );
                                toggleLoader(this.loaderService, false);
                                return;
                        }

                        // Generar dos números Ramdom
                        const generatedIds: number[] = [];
                        function generateRandomId() {
                                let randomId;
                                do {
                                        randomId = Math.floor(Math.random() * 10000);
                                } while (generatedIds.includes(randomId));

                                generatedIds.push(randomId);
                                return randomId;
                        }

                        this.infoModal = {
                                method: 'bank_account',
                                amount: total_credit,
                                currency: 'COP',
                                description: `IDENTIFICACIÓN:${customer.external_id} | OBLIGACIÓN:${this.choosedCreditOld.code_pay ?? 'N/A'
                                        }`,
                                order_id: generateRandomId(),
                                iva: '0',
                                redirect_url: environment.apiCreditosZiroReturn,
                                customer: customer,
                        };
                        this.infoModal.customer.external_id = generateRandomId();

                        this.payment_service
                                .createLink(this.infoModal)
                                .subscribe((response: any) => {
                                        toggleLoader(this.loaderService, false);

                                        if (response.payment_method === undefined) {
                                                this.ModalServiceAlert.openModal(
                                                        'Error!',
                                                        '¡Lo sentimos hubo un error en los metodos de pago, por favor vuelve a intentarlo!',
                                                        'error'
                                                );
                                        } else {
                                                this.paymentMethod = response.payment_method;
                                                this.showModal = true;
                                        }
                                });
                } else {
                        toggleLoader(this.loaderService, false);
                        this.eventOption.emit('corresponsal');
                }
        }

        returnDataModal() {
                return {
                        paymentMethod: this.paymentMethod,
                        showModal: this.showModal,
                        infoModal: this.infoModal,
                };
        }

        closeModal() {
                this.showModal = false;
        }
}
