<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog custom-modal modal-dialog-centered">
                <div class="modal-content">
                        <div class="modal-body">
                                <span type="button" class="button-close" (click)="closeModal()">
                                        <i class="fa-solid fa-circle-xmark close"></i>
                                </span>
                                <div class="columns">
                                        <div class="first-column column">
                                                <div class="header">
                                                        <img src="/assets/img/somoszirosas.jpg"
                                                                alt="Logo SOMOS ZIRO SAS" />
                                                        <div class="title">
                                                                <p class="fw-bold">Pago a</p>
                                                                <h1>SOMOS ZIRO SAS</h1>
                                                        </div>
                                                </div>
                                                <div class="body">
                                                        <h1 class="price"> {{ data.infoModal.amount | numberFormat }}
                                                                <span class="fs-5">COP</span> </h1>
                                                        <app-terms-conditions></app-terms-conditions>
                                                </div>
                                        </div>
                                        <div class="last-column column">
                                                <div class="header">
                                                        <h2>Escoge el método de pago</h2>
                                                        <h5>Elige la mejor opción de pago, ejemplo: (Nequi, Bancolombia,
                                                                BBVA)</h5>
                                                </div>
                                                <div class="payment-method">
                                                        <a [href]="data.paymentMethod.url" class="method"
                                                                rel="noopener noreferrer">
                                                                <div class="logo-method">
                                                                        <img src="/assets/img/pse.png" alt="Logo PSE" />
                                                                </div>
                                                                <h5>
                                                                        Transfiere con tu cuenta de ahorros o corriente
                                                                        a cualquier
                                                                        Banco con (PSE)
                                                                </h5>
                                                        </a>
                                                </div>
                                        </div>
                                </div>

                        </div>
                </div>
        </div>
</div>