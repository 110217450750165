import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  convertFileToBase64,
  numberFollowedByLetterValidator,
  numericValidator,
  onlyNumbers,
  removeNonNumeric,
  toggleLoader,
} from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';
import { ResponseSearch } from './interfaces/register.interfaces';
import { RegisterService } from './services/register.service';

import { ActivatedRoute, Router } from '@angular/router';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { ParemetersService } from 'src/app/shared/services/parameters.service';
import { validateCampo } from '../../shared/helpers/functions-helper.service';
import { AuthService } from '../login/services/auth.service';
import { StepService } from './components/step/service/step.service';
// import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';

import { Loader } from '@googlemaps/js-api-loader';
import {
  BehaviorSubject,
  catchError,
  delay,
  interval,
  map,
  Observable,
  of,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { AlertNewService } from 'src/app/shared/components/modal-alert-new/service/modal-alert-new.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    './register.component.scss',
    './pages/third-page/third-page.component.html',
    './pages/four-page/four-page.component.scss',
    './pages/six-page/six-page.component.scss',
  ],
})
export class NewRegisterComponent2 {
  @ViewChild('otroInput') otroInput!: ElementRef;

  allyForm: FormGroup;
  step: number = 1;
  documentFrontImage: SafeResourceUrl | undefined;
  documentBackImage: SafeResourceUrl | undefined;
  selfieImage: SafeResourceUrl | undefined;
  facturaImage: SafeResourceUrl | undefined;
  defaultFormControl = new FormControl();
  cities: any[] = [];
  imagenFrontalVisible: boolean = false;
  imagenTraseraVisible: boolean = false;
  imagenSelfieVisible: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  validateCampo = validateCampo;
  tokenMetamap: string = '';
  // id para las consultas del hook de metamap
  idMetamap: string = '';
  // id para las peticiones de envio de fotos
  idetifyMetamap: string = '';
  // verificacion de metamap completada
  verificacionMetamap: boolean = false;
  // reintento al consultar datos del cliente en metamap
  retriesSearchMetamap: number = 1;
  businessTypes: any[] = [];
  flagSendCodeOpt = false;
  // Modal para aceptar y enviar codigo por whatsapp!!!
  modalTermsAndConditions: boolean = false;
  phoneStorage: number;
  code: string | null;
  identificationTypeNew: string = '';

  logo_proveedor: string | null;
  // se almacenara los datos del usuario cuando vengan de metamap aqui para luego recuperarlo ya que al deshabilitar los campos no se
  // puede recuperar los datos del formulario
  currentName = '';
  currentLastName = '';
  currentIdentification = '';
  currentPhone1Valido = '';
  existCustomer = false;
  currenValueRequest = 0;

  // Variables
  // Almacenamos  el email
  public emailReal: string = '';
  // Como son 2 direcciones almacenamos una y otr
  public existAdress: boolean = false;
  public existAdres2: boolean = false;
  // Codigo postales de las 2 direcciones
  public postalCodeAddress1: string | null;
  public postalCodeAddress2: string | null;

  // checkId para el registro:
  public chekId: string = '';

  // propiedad para manejar el flujo de update
  public stateRegister: string;
  // fecha de expedition:
  public documentExpeditionDate: string = '';

  private swalModal: any; // Propiedad para almacenar el objeto Swal
  // vista de autorizacion de terminos y condiciones
  comunication_all = true;
  comunication_email = true;
  comunication_message = true;
  authorization_treatment_data = false;
  registeredChamberCommerceOptions: { value: string; label: string }[] = [
    { value: '1', label: 'Sí' },
    { value: '2', label: 'No' },
  ];
  registeredChamberoptions = [
    { value: 'persona_natural', name: 'Persona Natural (a tu nombre)' },
    {
      value: 'persona_juridica',
      name: 'Persona Jurídica (SAS, SA, LTD, etc.)',
    },
    {
      value: 'no_registrado',
      name: 'No estoy registrado en Cámara de Comercio',
    },
  ];
  showNitField = new BehaviorSubject<boolean>(false);

  isCheckAuth: boolean = false;

  // GOOGLE:
  public searchResults: any[] = [];
  public google: any;
  public direccionValida: boolean = false;
  public loadingResults: boolean = false;
  public direccionNegocioValida: boolean = false;
  public businessSearchResults: any[] = [];
  public hasSearched: boolean = false;
  public citySuccess: boolean = false;
  public citySelected!: string;

  // Direcciones:
  neighborhood: string = '';
  street: string = '';
  numberStreet: string = '';
  numberStreet2: string = '';
  floor: string = '';
  streetType: string = '';
  city_id2: string = '';
  city1: string = '';
  readonlyOrNo: boolean = false;
  validateCharacter: boolean = false;

  countValidationPhoto: number = 0;
  addresUpdateHomeB: boolean = false;
  addresUpdateHome: string = '';
  addresUpdateBussinessB: boolean = false;
  addresUpdateBussiness: string = '';

  imagenFacturaVisible: boolean = false;
  advisorID: string;
  public citySuccessSelect: boolean = false;
  public streetPlaceholder: string = 'Ej: Cra';
  public isStreetFieldsDisabled = false;

  public streetTypes: string[] = [
    'Avenida',
    'Avenida Calle',
    'Calle',
    'Carrera',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía',
  ];

  /**
   * Representa una lista de pasos a seguir en el proceso.
   * Cada paso tiene un número y un nombre descriptivo.
   * @type {Array<{ numero: number, nombre: string }>}
   */
  pasos: { numero: number; nombre: string }[] = [
    { numero: 1, nombre: 'Terminos y condiciones' },
    { numero: 2, nombre: 'Verificación de identidad' },
    { numero: 3, nombre: 'Verificación de identidad' },
    { numero: 4, nombre: 'Verificación de identidad' },
    { numero: 5, nombre: 'Verificación de identidad' },
    { numero: 6, nombre: 'Datos Personales' },
    { numero: 7, nombre: 'Datos Personales' },
    { numero: 8, nombre: 'Datos de tu Negocio' },
    { numero: 9, nombre: 'Datos de tu Negocio' },
  ];

  /**
   * Constructor for the class.
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private loaderService: LoaderService,
    private parametersService: ParemetersService,
    private router: Router,
    private authService: AuthService,
    private navService: NavService,
    public StepService: StepService,
    private route: ActivatedRoute,
    private modalService: ModalServiceAlert,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private alertNewService: AlertNewService
  ) {
    this.flagSendCodeOpt = false;
    this.initFormRegister();
    this.updateStorage();
    const storedStep = localStorage.getItem('step');
    this.step = storedStep ? parseInt(storedStep) : 1;
    const loader = new Loader({
      apiKey: 'AIzaSyCxatr4i9kn0aT1kpKk676UunHwKaPyxrk', // Reemplaza con tu API Key de Google
      libraries: ['places'],
    });

    loader.load().then((google) => {
      this.google = google;
    });
  }

  /**
   * Obtiene el paso actual del servicio StepService.
   *
   * @returns {number} El paso actual.
   * @see StepService#getStep
   */
  get stepNew() {
    return this.StepService.getStep();
  }

  /**
   * Incrementa el paso actual utilizando el servicio StepService.
   * Muestra en la consola el valor del paso actualizado.
   */
  incrementar() {
    this.StepService.incrementar();
  }

  /**
   * Decrementa el paso actual utilizando el servicio StepService.
   */
  decrementar() {
    this.StepService.decrementar();
    if (this.stepNew === 11 && this.street != '') this.setValueBussinesAdress();
    // this.resetVariables()
  }

  decrementarBussinnes() {
    this.StepService.decrementar();
    // this.resetVariables()
    this.allyForm.get('checkBussinnes')?.setValue(false);
  }

  /**
   * Alterna el estado de visualización del modal de términos y condiciones.
   * También muestra en consola el estado actual del modal y el paso actual.
   */
  toggleModal() {
    this.modalTermsAndConditions = !this.modalTermsAndConditions;
  }

  /**
   * Obtiene el nombre de la sección actual basado en el paso en el que se encuentra el usuario.
   *
   * @returns {string} El nombre de la sección actual. Si el paso no se encuentra en el array 'pasos',
   * devuelve 'Nombre de sección no encontrado'.
   */
  getNombreSeccionActual() {
    const seccion = this.pasos.find((paso) => paso.numero === this.step);
    return seccion ? seccion.nombre : 'Nombre de sección no encontrado';
  }

  /**
   * Cambia el paso actual al valor proporcionado y almacena el nuevo paso en el almacenamiento local.
   *
   * @param {number} nuevoStep - El número del paso al que se debe cambiar.
   *
   */
  cambiarStep(nuevoStep: number): void {
    localStorage.setItem('step', nuevoStep.toString());
  }

  /**
   * Actualiza la propiedad `phoneStorage` utilizando el valor almacenado en `localStorage` bajo la clave 'phone_1'.
   * Si el valor no está presente o es nulo en `localStorage`, se asigna un valor predeterminado a `phoneStorage`.
   *
   * @returns {void}
   */
  updateStorage() {
    const phoneStorageString = localStorage.getItem('phone_1'); // Obtiene el valor como cadena desde localStorage
    if (phoneStorageString !== null) {
      this.phoneStorage = parseInt(phoneStorageString, 10); // Convierte la cadena en un número
    } else {
      this.phoneStorage = 0; // O cualquier otro valor predeterminado
    }
  }

  /**
   * Función para inicializar el formulario de registro del aliado.
   * Utiliza la biblioteca de Reactive Forms de Angular para crear un conjunto
   * de controles de formulario con validaciones específicas.
   *
   * @returns {void} No retorna ningún valor; su objetivo es inicializar y configurar `this.allyForm`
   */
  initFormRegister() {
    // Inicializa un nuevo formulario reactivo utilizando FormBuilder
    this.allyForm = this.formBuilder.group({
      // Define los campos del formulario y asigna validadores
      code_shop: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      identification_type: [{ value: '', disabled: true }, Validators.required],
      identification: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      advisor_identification: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      phone_1: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      phone_1_valido: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      neighborhood: ['', [Validators.required, Validators.minLength(3)]],
      street: ['', [Validators.required, Validators.minLength(1)]],
      numberStreet: [
        '',
        [Validators.required, Validators.minLength(1), numericValidator()],
      ],
      numberStreet2: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          numberFollowedByLetterValidator(),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      streetType: ['', [Validators.required]],
      hasNoStreetNumber: [false],
      checkBussinnes: [false],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          onlyNumbers(),
        ],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      postal_code_1: [''],
      nit: [''],
      value_request: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.max(3000000),
          Validators.min(200000),
        ],
      ],
      business_name: ['', [Validators.required, Validators.minLength(3)]],
      city_id: ['', Validators.required],
      city_id2: ['', Validators.required],
      businness_address: ['', [Validators.required, Validators.minLength(5)]],
      postal_code_2: [''],
      registered_chamber_commerce: [
        '',
        [Validators.required, Validators.minLength(1)],
      ],
      photo_document_front: ['', Validators.required],
      photo_document_back: ['', Validators.required],
      photo_selfie: ['', Validators.required],
      customers_invoice: ['', Validators.required],

      registraduria: ['', Validators.required],
      customer_business_types_id: ['', Validators.required],
      comunication_all: [true, ''],
      comunication_email: [true, ''],
      comunication_message: [true, ''],
      authorization_treatment_data: [false, Validators.required],
    });
  }

  /**
   * Método ngOnInit que se ejecuta al inicializar el componente.
   * No devuelve ningún valor (void).
   */
  ngOnInit(): void {
    // this.getCodeParamProveedor();
    this.code = this.route.snapshot.paramMap.get('code');
    // this.cambiarStep(this.step);
    // Llama a la función getCities para obtener una lista de ciudades
    this.getCities();
    // Llama a la función getBusinessTypes para obtener los tipos de negocios
    this.getBusinessTypes();

    const hasNoStreetNumber = this.allyForm.get('hasNoStreetNumber')?.value;

    if (hasNoStreetNumber) {
      this.isStreetFieldsDisabled = true;
      this.allyForm.get('numberStreet')?.disable();
      this.allyForm.get('numberStreet2')?.disable();
    } else {
      this.isStreetFieldsDisabled = false;
      this.allyForm.get('numberStreet')?.enable();
      this.allyForm.get('numberStreet2')?.enable();
    }

    this.initRegisteredChamberCommerceListener();
  }

  public initRegisteredChamberCommerceListener(): void {
    this.allyForm
      .get('registered_chamber_commerce')
      ?.valueChanges.subscribe((value) => {
        setTimeout(() => {
          if (value === 'persona_juridica') {
            this.showNitField.next(true);
            this.allyForm.get('nit')?.setValidators([Validators.required]);
          } else {
            this.showNitField.next(false);
            this.allyForm.get('nit')?.clearValidators();
          }
          this.allyForm.get('nit')?.updateValueAndValidity();
          this.changeDetectorRef.detectChanges();
        });
      });
  }

  /**
   * Se ejecuta cada vez que el usuario escribe en el campo 'password'.
   * Llama a la función 'removeNonNumeric' para eliminar cualquier carácter que no sea un número.
   *
   * @returns {void} No devuelve ningún valor.
   */
  onPasswordInput() {
    // Obtiene el control de formulario correspondiente al campo 'password'.
    const control = this.allyForm.get('password');

    // Llama a la función de ayuda 'removeNonNumeric' para eliminar caracteres no numéricos.
    // Verifica si el control es null antes de proceder
    if (control !== null) {
      // Llama a la función de ayuda 'removeNonNumeric' para eliminar caracteres no numéricos.
      removeNonNumeric(control);
    }
  }

  /**
   * Formatea el valor de entrada de un campo de formulario a un formato de moneda.
   * @param event - Evento que dispara la función, generalmente un evento de teclado o cambio.
   */
  formatInputToCurrency(event: any) {
    // Extrae el valor del campo de formulario del objeto de evento
    let inputValue = event.target.value;

    // Elimina todos los caracteres que no sean dígitos numéricos
    let numericValue = inputValue.replace(/[^0-9]/g, '');

    // Formatea el número con comas como separadores de miles
    let formattedValue = this.formatNumberWithCommas(numericValue);

    // Establece el valor del control "value_request" en el formulario "allyForm"
    // y previene la emisión de eventos para evitar cualquier bucle infinito de cambios
    this.allyForm.controls['value_request'].setValue(formattedValue, {
      emitEvent: false,
    });
  }

  /**
   * Formatea un número con comas como separadores de miles.
   * @param x - Objeto que tiene una función toString que devuelve una representación de cadena del número.
   * @returns Una cadena que representa el número formateado con comas como separadores de miles.
   */
  formatNumberWithCommas(x: { toString: () => string }) {
    // Convierte el número a una cadena y luego utiliza una expresión regular
    // para insertar comas como separadores de miles.
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Maneja el avance al siguiente paso en el formulario y realiza validaciones y acciones específicas.
   * @returns {void} No hay valor de retorno explícito.
   */
  nextStep() {
    toggleLoader(this.loaderService, false);
    const STEPS = {
      PHOTO_FRONT: 6,
      PHOTO_BACK: 7,
      PHOTO_SELFIE: 5,
      CODE_PROVIDER: 8,
      CEDULA: 9,
      EMAIL: 10,
      ADDRESS: 11,
      BUSINESS_DETAILS: 12,
      VALUE_REQUEST: 14,
      CITY_BUSINESS_ADDRESS: 13,
      PHOTO_INVOICE: 15,
    };

    switch (this.stepNew) {
      case STEPS.PHOTO_SELFIE:
        this.handlePhotoSelfieStep();
        break;
      case STEPS.PHOTO_FRONT:
        this.handlePhotoFrontStep();
        break;
      case STEPS.PHOTO_BACK:
        this.handlePhotoBackStep();
        break;
      case STEPS.CODE_PROVIDER:
        this.handleCodeProviderStep();
        break;
      case STEPS.CEDULA:
        this.handleCedulaStep();
        break;
      case STEPS.EMAIL:
        this.handleEmailStep();
        break;
      case STEPS.ADDRESS:
        this.handleAddressStep();
        break;
      // case STEPS.NIT_VALIDATION:
      //         this.handleNitValidationStep();
      //         break;
      case STEPS.BUSINESS_DETAILS:
        this.handleBusinessDetailsStep();
        break;
      case STEPS.VALUE_REQUEST:
        this.handleValueRequestStep();
        break;
      case STEPS.CITY_BUSINESS_ADDRESS:
        this.handleCityBusinessAddressStep();
        break;
      case STEPS.PHOTO_INVOICE:
        this.handlePhotoInvoiceStep();
        break;
      default:
        console.warn('Paso no reconocido:', this.stepNew);
        break;
    }
  }

  /**
   * Gestiona el paso relacionado con la foto frontal del documento.
   * Valida el campo 'photo_document_front' y, si es válido, genera un token para Metamap.
   * @returns {void}
   */
  handlePhotoFrontStep() {
    if (this.validateAndToggleLoader(['photo_document_front'])) {
      this.validatePhotoFront();
    }
  }

  /**
   * Gestiona el paso relacionado con la foto trasera del documento.
   * Valida el campo 'photo_document_back' y, si es válido, procede con la validación de la foto de reverso.
   *
   * @returns {void}
   */
  handlePhotoBackStep() {
    if (this.validateAndToggleLoader(['photo_document_back'])) {
      this.validatePhotoReverso();
    }
  }

  /**
   * Gestiona el paso relacionado con la foto tipo selfie del usuario.
   * Valida el campo 'photo_selfie' y, si es válido, procede con la validación de la foto selfie.
   *
   * @returns {void}
   */
  handlePhotoSelfieStep() {
    if (this.validateAndToggleLoader(['photo_selfie'])) {
      this.validatePhotoSelfie();
    }
  }

  /**
   * Gestiona el paso relacionado con la verificación del código de proveedor.
   * Muestra un loader con el mensaje 'Verificando código de proveedor' y luego procede a verificar si el código de proveedor existe.
   */
  handleCodeProviderStep() {
    toggleLoader(this.loaderService, true, 'Verificando código de proveedor');
    this.verifyCodigoProveedorExist();
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de nombre y apellido.
   * Valida si los campos "name" y "last_name" son válidos y, en caso afirmativo, avanza al siguiente paso.
   *
   * @returns {void}
   */
  // handleNameLastnameStep(): void {
  //         if (this.validateAndToggleLoader(['name', 'last_name'], false)) {
  //                 this.incrementar();
  //         }
  //         this.incrementar();

  // }

  /**
   * Gestiona el paso relacionado con la validación de los campos de cédula.
   * Activa el componente de carga y llama a la función "verifyCedulaExist" para verificar la existencia de la cédula.
   *
   * @returns {void}
   */
  handleCedulaStep(): void {
    if (
      this.validateAndToggleLoader(
        ['identification_type', 'identification'],
        false
      ) &&
      this.validateAndToggleLoader(['name', 'last_name'], false)
    ) {
      this.verifyCedulaExist();
    }
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de correo electrónico y contraseña.
   * Activa el componente de carga y, si los campos son válidos, llama a la función "verifyEmailExist" para verificar la existencia del correo electrónico.
   */
  public handleEmailStep(): void {
    if (this.validateAndToggleLoader(['email', 'password'])) {
      if (!this.existCustomer) {
        this.validationRealEmail()
          .then((isReal) => {
            if (isReal === false) {
              //!isReal
              this.modalService.openModal(
                'Error',
                'Por favor ingresa un correo electrónico valido',
                'error'
              );
              toggleLoader(this.loaderService, false); // Desactivar el loader
            } else {
              // Si el correo es real, verificar si ya existe.
              this.verifyEmailExist();
            }
          })
          .catch((error) => {
            // Manejo de errores de la validación del correo
            console.error('Error al validar el email', error);
            this.modalService.openModal(
              'Error',
              'Error de conexión al validar el correo electrónico. Intenta de nuevo.',
              'error'
            );
            toggleLoader(this.loaderService, false);
          });
      } else {
        this.incrementar();
        toggleLoader(this.loaderService, false);
      }
    }
  }

  /**
   * Este metodo consume un servicio para esperar la respuesta del back
   * @returns
   */
  public validationRealEmail(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const email = this.allyForm.get('email')?.value;
      this.registerService.validatedEmailNeverBounce(email).subscribe(
        (response: any) => {
          if (response.data) {
            this.emailReal = response.data.result;
            resolve(this.emailReal !== 'invalid');
          } else {
            console.log('No data field in response');
            resolve(false);
          }
        },
        (error) => {
          console.error('Error al validar el email', error);
          reject(error); // Rechazar la promesa en caso de errores en la solicitud
        }
      );
    });
  }
  /**
   * Gestiona el paso relacionado con la validación del campo de dirección.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleAddressStep(): void {
    const fields = [
      'city_id2',
      'neighborhood',
      'street',
      'numberStreet',
      'numberStreet2',
    ];

    const isValid = fields.every((field) =>
      this.validateAndToggleLoader([field], false)
    );

    if (isValid) {
      const formValues = this.allyForm.value;
      const {
        city_id2: city,
        neighborhood,
        street,
        streetType,
        numberStreet,
        numberStreet2,
      } = formValues;

      const formattedAddress = `${streetType || ''} ${street || ''} ${
        numberStreet || ''
      } #${numberStreet2 || ''}, ${neighborhood || ''}, ${city || ''}`;

      this.allyForm.get('address')?.setValue(formattedAddress);
      this.neighborhood = neighborhood;
      this.street = street;
      this.numberStreet = numberStreet;
      this.numberStreet2 = numberStreet2;
      this.streetType = streetType;

      this.allyForm.get('city_id2')?.setValue(this.city1);

      this.updateStorage();
      this.incrementar();
    } else {
      this.modalService.openModal(
        'Error',
        'Por favor ingresa una dirección válida.',
        'error'
      );
    }
  }

  toggleStreetFields(event: any) {
    const isChecked = event.target.checked;
    this.isStreetFieldsDisabled = isChecked;

    if (isChecked) {
      // Deshabilita y elimina los valores de los campos
      this.allyForm.get('numberStreet')?.reset();
      this.allyForm.get('numberStreet')?.disable();
      this.allyForm.get('numberStreet2')?.reset();
      this.allyForm.get('numberStreet2')?.disable();
    } else {
      // Habilita los campos para permitir la edición
      this.allyForm.get('numberStreet')?.enable();
      this.allyForm.get('numberStreet2')?.enable();
    }

    // Guarda el estado del checkbox para el futuro
    this.allyForm.get('hasNoStreetNumber')?.setValue(isChecked);
  }

  /**
   * Gestiona el paso relacionado con la validación del campo NIT.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleNitValidationStep(): void {
    // if (this.validateAndToggleLoader(['nit'], false)) {
    //         if (!this.existCustomer) {
    //                 this.verifyNitExist();
    //         } else {
    //                 this.incrementar();
    //                 toggleLoader(this.loaderService, false);
    //         }
    // }
    this.incrementar();
  }

  /**
   * Gestiona el paso relacionado con los detalles del negocio.
   * Activa el componente de carga y, si los campos son válidos, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleBusinessDetailsStep(): void {
    if (
      this.validateAndToggleLoader(
        [
          'business_name',
          'customer_business_types_id',
          'registered_chamber_commerce',
        ],
        false
      )
    ) {
      if (
        this.allyForm.get('registered_chamber_commerce')?.value ===
        'persona_juridica'
      ) {
        // Validar si el campo NIT es válido
        if (
          !this.allyForm.get('nit')?.value ||
          this.allyForm.get('nit')?.invalid
        ) {
          this.allyForm.get('nit')?.markAsTouched();
          this.alertNewService.openModal(
            'Error',
            'Por favor ingresa un NIT válido.',
            'error'
          );
          return; // Salimos si el NIT no es válido
        }

        this.verifyNitExist(this.allyForm.get('nit')?.value);
        return;
      }

      // Si no existe el cliente, procedemos normalmente
      if (!this.existCustomer) {
        this.incrementar();
        this.cleanFormByBussinesAdress();
      } else {
        // Si el cliente ya existe, incrementamos dos veces para saltar pasos
        this.incrementar();
        this.incrementar();
      }
    }
  }

  /**
   * Gestiona el paso relacionado con la solicitud de valor.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleValueRequestStep(): void {
    // Usando validateAndToggleLoader directamente
    const rawValue = this.allyForm
      .get('value_request')!
      .value.replace(/,/g, '');
    this.allyForm.get('value_request')!.setValue(rawValue);
    if (this.validateAndToggleLoader(['value_request'], false)) {
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la ciudad y la dirección comercial.
   * Activa el componente de carga y, si los campos son válidos, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleCityBusinessAddressStep(): void {
    const fields = [
      'city_id',
      'neighborhood',
      'street',
      'streetType',
      'numberStreet',
      'numberStreet2',
    ];

    const isValid = fields.every((field) =>
      this.validateAndToggleLoader([field], false)
    );

    if (isValid) {
      const formValues = this.allyForm.value;
      const {
        city_id,
        neighborhood,
        streetType,
        street,
        numberStreet,
        numberStreet2,
      } = formValues;

      const formattedAddress = `${streetType || ''} ${street || ''} ${
        numberStreet || ''
      } #${numberStreet2 || ''}, ${neighborhood || ''}, ${this.city1 || ''} `;

      this.allyForm.get('businness_address')?.setValue(formattedAddress);
      this.allyForm.get('city_id')?.setValue(this.city_id2);
      this.incrementar();
    } else {
      this.modalService.openModal(
        'Error',
        'Por favor ingresa una dirección válida.',
        'error'
      );
    }
  }

  toggleSameAddress(event: any): void {
    if (event.target.checked) {
      this.setValueBussinesAdress();
    } else {
      this.cleanFormByBussinesAdress();
    }
  }

  public cleanFormByBussinesAdress(): void {
    this.readonlyOrNo = false;
    this.allyForm.get('hasNoStreetNumber')?.enable();
    this.allyForm.patchValue({
      city_id: '',
      city_id2: '',
      streetType: '',
      neighborhood: '',
      street: '',
      numberStreet: '',
      numberStreet2: '',
    });

    [
      'city_id2',
      'city_id',
      'streetType',
      'neighborhood',
      'street',
      'numberStreet',
      'numberStreet2',
    ].forEach((field) => {
      const control = this.allyForm.get(field);
      if (control) {
        control.markAsPristine();
        control.markAsUntouched();
      }
    });
  }

  public setValueBussinesAdress(): void {
    this.allyForm.get('city_id')?.setValue(this.city_id2);
    this.allyForm.get('city_id2')?.setValue(this.city1);
    this.allyForm.get('streetType')?.setValue(this.streetType);
    this.allyForm.get('neighborhood')?.setValue(this.neighborhood);
    this.allyForm.get('street')?.setValue(this.street);
    this.allyForm.get('numberStreet')?.setValue(this.numberStreet);
    this.allyForm.get('numberStreet2')?.setValue(this.numberStreet2);
    this.readonlyOrNo = true;
    this.stepNew === 13
      ? this.allyForm.get('hasNoStreetNumber')?.disable()
      : null;
  }

  /**
   * Valida los campos del formulario y controla la visibilidad del componente de carga.
   *
   * @param {string[]} fields - Los campos a validar.
   * @returns {boolean} - True si todos los campos son válidos, de lo contrario, false.
   */
  validateAndToggleLoader(
    fields: string[],
    showLoader: boolean = true
  ): boolean {
    let isValid = true;
    let error!: any;
    fields.forEach((field) => {
      const control = this.allyForm.controls[field];
      error = control;
      if (control.invalid) {
        this.markFieldsSelectedAsTouched([field]);
        isValid = false;
      }
    });
    if (!isValid) {
      if (error?.errors?.max?.max) {
        this.modalService.openModal(
          'Error',
          'El cupo máximo que puedes solicitar a zíro es $3,000,000',
          'error'
        );
      } else if (error?.errors?.min?.min) {
        this.modalService.openModal(
          'Error',
          'El cupo mínimo que puedes solicitar a zíro es $200,000',
          'error'
        );
      } else {
        this.modalService.openModal(
          'Error',
          'Verifica la información ingresada',
          'error'
        );
      }

      toggleLoader(this.loaderService, false);
    } else {
      toggleLoader(this.loaderService, showLoader);
    }
    return isValid;
  }

  /**
   * Retrocede al paso anterior en el formulario, si es posible.
   * @returns {void} No hay valor de retorno explícito.
   */
  prevStep() {
    // Mostrar el número de paso actual en la consola
    console.log(this.step);

    // Verificar si el paso actual es 1; si es así, no se puede retroceder más
    if (this.step == 1) {
      return; // No se realiza ninguna acción si el paso actual es 1
    }

    // Retroceder un paso si el paso actual no es 1
    this.step--;
  }

  /**
   * Valida y envía un código OTP al número de celular proporcionado en el formulario.
   * Realiza acciones según la validez del número de celular.
   * @returns {void} No hay valor de retorno explícito.
   */
  validarCelular() {
    // Obtener el control del número de celular del formulario
    const phone_1 = this.allyForm.controls['phone_1'];
    const identification = this.allyForm.get('identification')?.value;
    const buttonConfig = {
      acceptText: 'Actualizar mis datos',
      showCancel: false,
    };

    // Validar si el número de celular es inválido
    if (phone_1.invalid) {
      // Marcar el campo como "touched" para mostrar errores y mensaje de error
      this.markFieldsSelectedAsTouched(['phone_1']);
      // Mostrar un mensaje de error al usuario
      this.modalService.openModal(
        'Error',
        'Verifica la información ingresada',
        'error'
      );
      return;
    } else if (phone_1.valid) {
      toggleLoader(this.loaderService, true, 'Validando celular...');
      this.registerService.validateCellphonePrimary(phone_1.value).subscribe({
        next: (response: any) => {
          toggleLoader(this.loaderService, false);
          if (response.data === 'mora') {
            this.showMessageAndSendCodeOtpCustomer(
              response.message,
              buttonConfig,
              phone_1.value
            );
          } else {
            this.showMessageAndSendCodeOtpCustomer(
              response.message,
              buttonConfig,
              phone_1.value
            );
          }
        },
        error: (error) => {
          const buttonConfig = {
            acceptText: 'Inicia sesión para validar tu estado de cuenta',
            showCancel: false,
          };

          toggleLoader(this.loaderService, false);
          console.log(
            'Error al validar el número de celular en la base de datos',
            error
          );
          if (error.error.data === 'no-update') {
            this.alertNewService
              .openModal(
                'Advertencia',
                error.error.message,
                'warning',
                buttonConfig
              )
              .subscribe((result) => {
                if (result) {
                  this.router.navigate(['/iniciar-sesion']);
                  this.StepService.setStep(1);
                }
              });
          } else {
            this.modalService.openModal(
              'Error',
              error.error.message ||
                'Ha ocurrido un error al validar el número de celular',
              'error'
            );
          }
        },
      });
    }
  }

  public showMessageAndSendCodeOtpCustomer(
    message: string,
    buttonConfig: any,
    phone: any
  ) {
    if (message === 'El número de teléfono está disponible para usarlo') {
      this.sendCodeOtpCustomer(phone);
    } else {
      this.alertNewService
        .openModal('Advertencia', message, 'warning', buttonConfig)
        .subscribe((result) => {
          if (result) {
            this.sendCodeOtpCustomer(phone);
          }
        });
    }
  }

  /**
   * Obtiene y asigna las ciudades utilizando el servicio de parámetros.
   * Realiza una solicitud y actualiza la lista de ciudades.
   * @returns {void} No hay valor de retorno explícito.
   */
  getCities(): void {
    // Utilizar el servicio de parámetros para obtener las ciudades
    this.parametersService.getCities().subscribe((data: any) => {
      // Asignar la lista de ciudades desde los datos recibidos
      this.cities = data.data;
    });
  }

  /**
   * Obtiene y asigna los tipos de negocio utilizando el servicio de parámetros.
   * Realiza una solicitud y actualiza la lista de tipos de negocio.
   * @returns {void} No hay valor de retorno explícito.
   */
  getBusinessTypes(): void {
    // Utilizar el servicio de parámetros para obtener los tipos de negocio
    this.parametersService.getBusinessTypes().subscribe((data: any) => {
      // Mostrar los datos en la consola (opcional)
      // Asignar la lista de tipos de negocio desde los datos recibidos
      this.businessTypes = data.data;
    });
  }

  public refreshData(type: 'business' | 'cities'): void {
    const loaderMessage =
      type === 'business'
        ? 'Cargando tipos de negocios...'
        : 'Cargando ciudades...';
    toggleLoader(this.loaderService, true, loaderMessage);

    let serviceCall;
    if (type === 'business') {
      serviceCall = this.parametersService.getBusinessTypes();
    } else {
      serviceCall = this.parametersService.getCities();
    }

    serviceCall.subscribe((data: any) => {
      if (type === 'business') {
        this.businessTypes = data.data;
      } else {
        this.cities = data.data;
      }
      toggleLoader(this.loaderService, false);
    });
  }

  /**
   * Verifica la existencia de una cédula utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyCedulaExist(): void {
    toggleLoader(this.loaderService, true);
    const cedula = this.allyForm.controls['identification'].value;
    const cedulaExiste = `La cedula: ${cedula} ya se encuentra en nuestros registros`;
    this.registerService.verifyCedula(cedula).subscribe(
      (response: ResponseSearch) => {
        toggleLoader(this.loaderService, false);
        this.handleSwitchResponse(response);
      },
      (error) => {
        console.log(error);

        this.modalService.openModal(
          'Error',
          error.error.message ||
            'Ocurrio un problema, ya lo estamos solucionando',
          'error'
        );

        toggleLoader(this.loaderService, false);
      }
    );
  }

  public handleSwitchResponse(response: ResponseSearch) {
    switch (response.status) {
      case 'update':
        this.stateRegister = 'update';
        this.showModalAndDiferentAction(response.message, this.stateRegister);
        break;
      case 'no-update':
        this.showModalAndDiferentAction(response.message, 'no-update');
        break;
      case 'create':
        this.stateRegister = 'create';
        this.incrementar();
        break;
      case 'mora':
        this.stateRegister = 'update';
        this.showModalAndDiferentAction(response.message, this.stateRegister);
        break;
      default:
        this.modalService.openModal('Error', 'Error inesperado', 'error');
    }
  }

  public showModalAndDiferentAction(message: string, action: string) {
    const buttonConfig = { acceptText: 'Actualizar Datos', showCancel: false };
    if (action === 'no-update') {
      this.alertNewService
        .openModal('Advertencia', message, 'warning')
        .subscribe((result) => {
          if (result) {
            this.resetForm();
            this.generateCheckId();
            this.StepService.setStep(1);
            this.router.navigate(['/iniciar-sesion']);
          }
        });
    } else {
      this.alertNewService
        .openModal('Advertencia', message, 'warning', buttonConfig)
        .subscribe((result) => {
          if (result) {
            this.incrementar();
          }
        });
    }
  }

  /**
   * Verifica la existencia de un código de proveedor utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyCodigoProveedorExist(): void {
    const code_shop = this.allyForm.controls['code_shop'];
    const advisor = this.allyForm.controls['advisor_identification'];
    toggleLoader(
      this.loaderService,
      true,
      'Por favor espera, validando tu proveedor'
    );
    const errors = [];

    if (!code_shop.valid) {
      errors.push('El código del proveedor no puede estar vacío.');
    }

    if (!advisor.valid) {
      errors.push('La cédula del vendedor no puede estar vacía.');
    }

    if (errors.length > 0) {
      this.showErrorAndToggleLoader(
        ['code_shop', 'advisor_identification'],
        errors.join(' ')
      );
      return;
    }

    const proveedorNoExiste = `El código del proveedor: ${code_shop.value} no se encuentra registrado`;

    this.registerService
      .validateAdvisor(advisor.value)
      .pipe(
        switchMap((advisorResponse: any) => {
          if (advisorResponse.ok) {
            this.advisorID = advisorResponse.data;
            return this.registerService.verifyCodeProvider(code_shop.value);
          } else {
            // Si la validación falla, se lanza un error con el mensaje correspondiente
            this.modalService.openModal(
              'Error',
              'Esa cédula no existe con ningún vendedor',
              'error'
            );
            return new Observable();
          }
        }),
        switchMap((providerResponse: any) => {
          toggleLoader(this.loaderService, false);
          if (providerResponse.ok) {
            return this.pollingRequest(this.chekId);
          } else {
            this.modalService.openModal(
              'Error',
              providerResponse.message,
              'error'
            );
            return new Observable(); // Detiene la ejecución posterior
          }
        }),
        catchError((error) => {
          // Manejo de errores durante cualquiera de las validaciones
          toggleLoader(this.loaderService, false);
          this.showErrorAndToggleLoader(
            ['code_shop'],
            error.error.message || proveedorNoExiste
          );
          console.error('Error en la consulta:', error);
          return new Observable(); // Detiene la ejecución posterior
        })
      )
      .subscribe((response: any) => {
        if (response.data && response.data.status === 'completed') {
          toggleLoader(this.loaderService, false);
          this.incrementar();
        }
      });
  }

  /**
   * Muestra un mensaje de error y controla la visibilidad del componente de carga, además de marcar
   * los campos especificados como "touched".
   *
   * @param {string[]} fields - Los campos a marcar como "touched".
   * @param {string} errorMessage - El mensaje de error a mostrar.
   */
  showErrorAndToggleLoader(fields: string[], errorMessage: string) {
    fields.forEach((field) => {
      this.allyForm.controls[field].markAsTouched();
    });

    toggleLoader(this.loaderService, false);
    this.modalService.openModal('Error', errorMessage, 'error');
  }

  /**
   * Verifica la existencia de un email utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyEmailExist(): void {
    const email = this.allyForm.controls['email'].value;
    const identification = this.allyForm.controls['identification'].value;
    this.registerService
      .verifyEmail(email, identification, this.stateRegister)
      .subscribe({
        next: (response: ResponseSearch) => {
          this.incrementar();
        },
        error: (error: any) => {
          toggleLoader(this.loaderService, false);
          this.handleErrorsMethod(error);
        },
        complete: () => {
          toggleLoader(this.loaderService, false);
        },
      });
  }

  /**
   * Muestra en pantalla el mensaje de error proporcionado
   * @param error
   */
  private handleErrorsMethod(error: any) {
    console.log(`Error: ${error.status} `, error.error.message);
    toggleLoader(this.loaderService, false);
    this.modalService.openModal('Error', error.error.message, 'error');
  }

  /**
   * Verifica la existencia de un email utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyNitExist(nit: string): void {
    const nitF = nit;
    let identification = this.allyForm.controls['identification'].value;
    const nitExiste = `El nit: ${nit} ya se encuentra registrado`;
    toggleLoader(this.loaderService, true);

    this.registerService
      .verifyNit(nitF, identification, this.stateRegister)
      .subscribe({
        next: (response) => {
          toggleLoader(this.loaderService, false);
          this.cleanFormByBussinesAdress();
          this.incrementar();
        },
        error: (error: any) => {
          this.handleErrorsMethod(error);
          toggleLoader(this.loaderService, false);
        },
        complete: () => {
          toggleLoader(this.loaderService, false);
        },
      });
  }

  /**
   * Marca todos los campos del formulario como "touched".
   * Itera a través de los controles del formulario y los marca como "touched".
   * @returns {void} No hay valor de retorno explícito.
   */
  markAllFieldsAsTouched() {
    // Iterar a través de los nombres de los controles en el formulario
    Object.keys(this.allyForm.controls).forEach((controlName) => {
      // Marcar cada control como "touched"
      this.allyForm.controls[controlName].markAsTouched();
    });
  }

  /**
   * Marca los campos seleccionados del formulario como "touched".
   * Itera a través de los nombres de los campos proporcionados y los marca como "touched".
   * @param {string[]} fields - Lista de nombres de campos a marcar como "touched".
   * @returns {void} No hay valor de retorno explícito.
   */
  markFieldsSelectedAsTouched(fields: string[]) {
    fields.forEach((fieldName) => {
      // Obtener el control correspondiente al nombre del campo
      const control = this.allyForm.get(fieldName);
      if (control) {
        // Marcar el control como "touched"
        control.markAsTouched();
      }
    });
  }

  /** *********************************************************
   * PROCESO CODE OTP TERMINOS Y CONDICIONES
   *************************************************************/

  /**
   * Envía un código OTP al número de celular proporcionado utilizando el servicio de registro.
   * Muestra un mensaje de carga durante la solicitud y maneja posibles respuestas y errores.
   * @param {number} $celular - Número de celular al que se enviará el código OTP.
   * @returns {void} No hay valor de retorno explícito.
   */
  sendCodeOtpCustomer($celular: number) {
    toggleLoader(
      this.loaderService,
      true,
      'Por favor, espera, el envío del código puede tardar hasta un minuto'
    );

    this.registerService
      .sendCodeOtp($celular)
      .pipe(
        switchMap((data) => {
          const startTime = Date.now();
          return of(data).pipe(
            delay(30000),
            tap(() => {
              const endTime = Date.now();
              toggleLoader(this.loaderService, false);
            })
          );
        }),
        catchError((error) => {
          // Si ocurre un error, desactivar el loader inmediatamente
          console.log(error);
          toggleLoader(this.loaderService, false);
          return throwError(error);
        })
      )
      .subscribe(
        (data: any) => {
          // Después de que el loader se haya desactivado, ejecutar el flujo
          if (!data.ok) {
            // Mostrar mensaje de error si la respuesta no es exitosa
            this.modalService.openModal(
              'Error',
              'Ha ocurrido un error al enviar el código',
              'error'
            );
          } else {
            // Mostrar modal con el código OTP si la respuesta es exitosa
            this.showModalCodeOtp();
          }
        },
        (error: any) => {
          // Si ocurre un error en la solicitud, el loader ya debería haberse desactivado en el catchError
          console.log('Error en la solicitud:', error);
          this.modalService.openModal(
            'Error',
            'Ha ocurrido un error al enviar el código',
            'error'
          );
        }
      );
  }

  /**
   * Muestra un modal interactivo para ingresar el código OTP y confirmar los términos y condiciones.
   * El usuario puede ingresar el código OTP y confirmar su acuerdo con los términos y condiciones.
   * @returns {void} No hay valor de retorno explícito.
   */
  showModalCodeOtp() {
    Swal.fire({
      // title: "¿Estás de acuerdo con los términos y condiciones?",
      html:
        'Al ingresar el código confirmas  que eres el propietario de esta línea y estás de acuerdo con los ' +
        '<br>' +
        " <a target='_blank' href='https://somosziro.com/terminos-y-condiciones/' rel='noopener noreferrer' style='color: blue; text-decoration: underline;'>Términos y Condiciones</a>: " +
        '<br><br>' +
        "<input type='number' class='form-control' id='codeOtp' placeholder='Ingresa el código' value='' formControlName='codeOtp' required/>",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Acepto Términos y Condiciones',
      allowOutsideClick: false, // No permitir cerrar al hacer clic fuera del cuadro de diálogo
      allowEscapeKey: false, // No permitir cerrar al presionar la tecla Escape
      preConfirm: () => {
        // Obtener el código OTP ingresado por el usuario
        const codigoOtpDigitado = (<HTMLInputElement>(
          document.getElementById('codeOtp')
        )).value;
        if (codigoOtpDigitado === '') {
          Swal.showValidationMessage(
            'Por favor, ingresa el código que enviamos a tu WhatsApp'
          );
        } else {
          // Validar el código OTP ingresado por el usuario
          this.validateCodeOtpCustomer(Number(codigoOtpDigitado));
        }
      },
    });
  }

  onStreetTypeSelected(event: string): void {
    this.allyForm.get('streetType')?.setValue(event);
  }

  /**
   * valida el codigo OTP enviado al celular
   * @param $code
   */
  validateCodeOtpCustomer($code: number) {
    const self = this; // Almacenamos el contexto actual en una variable
    toggleLoader(
      this.loaderService,
      true,
      'Nos aliamos con tu proveedor para ofrecerte un nuevo método de pago'
    );
    const phone_1 = this.allyForm.controls['phone_1'].value;
    localStorage.setItem('phone_1', phone_1);
    const data = {
      phone: phone_1,
      code: $code,
    };
    const message = '¡Ha ocurrido un error al validar el código enviado!';
    this.registerService.validateCodeOtpCustomerService(data).subscribe(
      (data: any) => {
        toggleLoader(this.loaderService, false);

        if (!data.ok) {
          self.showModalCodeOtp();
          this.modalService.openModal(
            'Error',
            data.message ? data.message : message,
            'error'
          );
        } else {
          this.modalTermsAndConditions = false;
          this.cambiarStep(2);
          this.incrementar();
        }
      },
      (error: any) => {
        toggleLoader(this.loaderService, false);
        Swal.fire({
          title: 'Error',
          text: error.error.message ? error.error.message : message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.showModalCodeOtp();
          }
        });
      }
    );
  }

  /** *********************************************************
   * PROCESO VALIDACIÓN METAMAP
   *************************************************************/

  /**
   * Maneja la selección de un archivo de imagen y realiza acciones en función del tipo de foto.
   * @param event - El evento de selección de archivo que desencadenó la función.
   * @param tipoFoto - El tipo de foto seleccionado ("frontal", "trasera" o "selfie").
   */
  onFileSelected(event: any, tipoFoto: string) {
    const file = event.target.files[0];

    // Ahora puedes hacer algo con el archivo, como convertirlo en una URL de objeto y mostrarlo en la página
    if (event.target && event.target.files && event.target.files.length > 0) {
      const fileUrl = URL.createObjectURL(file);
      // ...Haz algo con fileUrl, como mostrarlo en la página

      // Crear el elemento de imagen y asignarle las propiedades
      let imageElement = document.createElement('img');
      imageElement.src = fileUrl;
      imageElement.style.width = 'auto'; // ajusta a la anchura deseada
      imageElement.style.height = '300px'; // ajusta a la altura deseada

      if (tipoFoto == 'frontal') {
        this.imagenFrontalVisible = true;
        this.documentFrontImage = fileUrl;
        this.selectedFiles['photo_document_front'] = file;
        const formControl = this.allyForm.get('photo_document_front');
        if (formControl) {
          formControl.setValue(file);
          //this.formData.append('photo_document_front', file, file.name);
        } else {
          console.error('Form control photo_document_front not found');
        }
      }
      if (tipoFoto == 'trasera') {
        this.imagenTraseraVisible = true;
        this.documentBackImage = fileUrl;
        this.selectedFiles['photo_document_back'] = file;
        const formControlBack = this.allyForm.get('photo_document_back');
        if (formControlBack) {
          formControlBack.setValue(file);
          //this.formData.append('photo_document_back', file, file.name);
        } else {
          console.error('Form control photo_document_back not found');
        }
      }
      if (tipoFoto == 'selfie') {
        this.imagenSelfieVisible = true;
        this.selfieImage = fileUrl;
        this.selectedFiles['photo_selfie'] = file;
        const formControlSelfie = this.allyForm.get('photo_selfie');
        if (formControlSelfie) {
          formControlSelfie.setValue(file);
          //this.formData.append('photo_selfie', file, file.name);
        } else {
          console.error('Form control photo_selfie not found');
        }
      }

      if (tipoFoto == 'factura') {
        this.imagenFacturaVisible = true;
        this.facturaImage = fileUrl;
        this.selectedFiles['customers_invoice'] = file;
        const formControlFactura = this.allyForm.get('customers_invoice');
        if (formControlFactura) {
          formControlFactura.setValue(file);
        } else {
          console.error('Form control customers_invoice not found');
        }
      }
    }
  }

  /**
   * Este metodo proporciona la foto, el tipo, el metodo y el mensaje de carga
   */
  public validatePhotoFront() {
    this.validateAndSendPhoto(
      'photo_document_front',
      'front',
      'PATCH',
      'Por favor espera, estamos validando que tu foto coincida con la foto de tu cédula'
    );
  }

  /**
   * Este metodo proporciona la foto, el tipo, el metodo y el mensaje de carga
   */
  public validatePhotoReverso() {
    this.validateAndSendPhoto(
      'photo_document_back',
      'back',
      'PATCH',
      'Por favor espera, estamos validando tu identidad'
    );
  }

  /**
   * Este metodo proporciona la foto, el tipo, el metodo y el mensaje de carga
   */
  public validatePhotoSelfie() {
    this.validateAndSendPhoto(
      'photo_selfie',
      'selfie',
      'POST',
      'Por favor espera, estamos validando tu identidad'
    );
  }

  public handlePhotoInvoiceStep() {
    if (this.validateAndToggleLoader(['customers_invoice'], false)) {
      this.incrementar();
    }
  }

  /**
   *  valida la foto dependiendo el {method} que se le pase
   * @param data => {type, check_id, img}
   * @param method  => {POST, PATCH}
   */
  public validateSendPhoto(data: any, method: any) {
    this.registerService.newValidateApitude(data, method).subscribe({
      next: (response) => {
        toggleLoader(this.loaderService, false);
        if (response.ok) {
          this.handleResponseType(data, response);
        }
      },
      error: (error) => {
        console.log(error);
        toggleLoader(this.loaderService, false);
        this.handleValidationError(data, error);
      },
    });
  }
  private handleValidationError(data: any, error: any) {
    console.log(error);

    if (data.type === 'front') {
      this.countValidationPhoto++;

      if (this.countValidationPhoto >= 3) {
        this.handlePhotoMismatch();
      } else {
        this.modalService.openModal(
          'Error',
          error.error.Message || error.error.message,
          'error'
        );
      }
    } else {
      this.modalService.openModal(
        'Error',
        error.error.Message || error.error.message,
        'error'
      );
    }
  }

  private handlePhotoMismatch() {
    const buttonConfig = { acceptText: 'Retomar la foto del rostro' };

    this.alertNewService
      .openModal(
        'La foto de tu rostro no coincide con la fotografía de tu cédula.',
        'Asegúrate que haya una buena iluminación y no estes usando objetos cómo, gafas, gorras y tapabocas.',
        'warning',
        buttonConfig
      )
      .subscribe((result) => {
        if (result) {
          const phone_1 = this.allyForm.get('phone_1')?.value;
          const phone_1_valido = this.allyForm.get('phone_1_valido')?.value;
          this.resetForm();
          this.allyForm.patchValue({
            phone_1: phone_1,
            phone_1_valido: phone_1_valido,
          });
          this.generateCheckId();
          this.StepService.setStep(5);
          this.countValidationPhoto = 0;
        }
      });
  }

  /**
   * Este metodo maneja la respuesta dependiendo el tipo
   * @param data => {type, check_id, img}
   * @param response
   */
  public handleResponseType(data: any, response: any) {
    switch (data.type) {
      case 'back':
        this.handleBackResponse();
        break;
      case 'front':
        this.handleFrontResponse(response);
        break;
      default:
        this.incrementar();
        break;
    }
  }

  /**
   * Llama al metodo para validar el resultado de las 3 imagenes ya proporcionada
   * @param checkId => es el identificador que se uso para las 3 imagenes
   */
  public handleBackResponse() {
    toggleLoader(this.loaderService, false);
    this.incrementar();
  }

  /**
   * Valida que la cara coincida y si no retorna muestra un mensaje de error
   * @param response
   */
  public handleFrontResponse(response: any) {
    if (response.data.face_match && response.data.validation) {
      this.incrementar();
    } else {
      this.modalService.openModal('Error', response.data.details, 'error');
    }
  }

  /**
   *  Valida el resultado de las 3 fotos ya antes proporcionada
   * @param checkId => identificador unico de las 3 fotos
   */
  public validateResult(checkId: string) {
    const data = {
      type: 'result',
      check_id: checkId,
    };
    return this.registerService.newValidateApitude(data, 'POST').pipe(
      tap({
        next: (response) => {
          if (response.ok) {
            const {
              identification,
              name,
              last_name,
              date_expedition,
              registraduria,
            } = response.data;
            this.allyForm.patchValue({
              name: name,
              last_name: last_name,
              identification: identification,
              phone_1_valido: localStorage.getItem('phone_1'),
            });
            this.documentExpeditionDate = date_expedition;
            const validateDocutentTypes = ['version_2000', 'version_2020'];
            const documentType = response.data.document_type;
            this.identificationTypeNew = validateDocutentTypes.includes(
              documentType
            )
              ? 'CC'
              : 'CE';
            this.allyForm
              .get('identification_type')
              ?.setValue(this.identificationTypeNew);
            this.allyForm.get('registraduria')?.setValue(registraduria);
          }
          // toggleLoader(this.loaderService, false);
        },
        error: (error) => {
          // toggleLoader(this.loaderService, false);
          console.log(error);
          this.modalService.openModal('Error', error.error.Message, 'error');
        },
      })
    );
  }

  public pollingRequest(checkId: string) {
    toggleLoader(
      this.loaderService,
      true,
      'Por favor espera, validando tu proveedor'
    );

    return interval(6000).pipe(
      tap(() => {
        toggleLoader(
          this.loaderService,
          true,
          'Por favor espera, validando tu proveedor'
        );
      }),
      switchMap(() => this.validateResult(checkId)),
      map((response: any, index: number) => {
        // Añadimos el índice al objeto de respuesta
        return { ...response, attempt: index };
      }),
      tap((response: any) => {
        if (response.data.status === 'completed') {
          toggleLoader(this.loaderService, false);
        }
      }),
      takeWhile((response: any) => {
        // Continuamos mientras el estado no sea "completed" y el intento sea menor a 5
        return response.data.status !== 'completed' && response.attempt < 4;
      }, true),
      tap((response: any) => {
        // Si llegamos al quinto intento y no está "completed", lo simulamos
        if (response.attempt >= 4 && response.data.status !== 'completed') {
          response.data.status = 'completed'; // Simulamos el estado
          toggleLoader(this.loaderService, false); // Detenemos el loader
        }
      }),
      catchError((error) => {
        console.error('Polling error:', error);
        toggleLoader(this.loaderService, false);
        return of(null); // Retornamos un observable vacío para que termine el flujo
      })
    );
  }

  /**
   * Este metodo llama al servicio y dependiendo de los parametros y el metodo hace cierta consulta
   * @param photoControlName  => nombre del control del formulario
   * @param type => tipo de foto{front, back, selfie}
   * @param method => {POST, PATCH}
   * @param loaderMessage => mensaje de carga
   */
  private async validateAndSendPhoto(
    photoControlName: string,
    type: string,
    method: string,
    loaderMessage: string
  ): Promise<void> {
    const formControlPhoto = this.allyForm.get(photoControlName);

    toggleLoader(this.loaderService, true, loaderMessage);

    if (!this.chekId) {
      this.generateCheckId();
    }

    const imgBase64 = await convertFileToBase64(formControlPhoto?.value);

    const data = {
      type: type,
      check_id: this.chekId,
      img: imgBase64,
    };

    this.validateSendPhoto(data, method);
  }

  /**
   * Genera un identificador único para la validación de la foto.
   */
  public generateCheckId() {
    this.chekId = uuidv4();
  }

  /** *********************************************************
   * PROCESO APROBACIÓN DE CUPO
   *************************************************************/

  /**
   * Procesa el formulario de registro y maneja la lógica para guardar y aprobar registros.
   *
   * @returns {void}
   */
  register(): void {
    // Verificar si el formulario es válido
    // ! Le setemos valor del advisor al formularios
    this.allyForm.get('advisor_identification')?.setValue(this.advisorID);
    const {
      authorization_treatment_data,
      comunication_all,
      comunication_email,
      comunication_message,
    } = this.allyForm.value;

    if (!authorization_treatment_data) {
      this.modalService.openModal(
        'Alerta',
        'Debes autorizar a zíro a consultar tu información',
        'warning'
      );

      return;
    }

    if (this.allyForm.valid) {
      // Mostrar el componente de carga mientras se valida la información
      toggleLoader(this.loaderService, true);
      // Crear un objeto FormData para enviar los datos
      const formData = new FormData();
      const formControlPhoto_document_front = this.allyForm.get(
        'photo_document_front'
      );
      const formControlPhoto_document_back = this.allyForm.get(
        'photo_document_back'
      );
      const formControlPhoto_selfie = this.allyForm.get('photo_selfie');
      const formControlPhoto_customer_invoice =
        this.allyForm.get('customers_invoice');
      if (formControlPhoto_document_front) {
        formData.append(
          'photo_document_front',
          formControlPhoto_document_front.value,
          formControlPhoto_document_front.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      if (formControlPhoto_document_back) {
        formData.append(
          'photo_document_back',
          formControlPhoto_document_back.value,
          formControlPhoto_document_back.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }

      if (formControlPhoto_selfie) {
        formData.append(
          'photo_selfie',
          formControlPhoto_selfie.value,
          formControlPhoto_selfie.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }

      if (formControlPhoto_customer_invoice) {
        formData.append(
          'customers_invoice',
          formControlPhoto_customer_invoice.value,
          formControlPhoto_customer_invoice.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      formData.append('code_shop', this.allyForm.value.code_shop);
      formData.append('name', this.allyForm.value.name);
      formData.append('last_name', this.allyForm.value.last_name);
      formData.append('identification', this.allyForm.value.identification);
      formData.append('phone_1', this.allyForm.value.phone_1);
      formData.append('email', this.allyForm.value.email);
      formData.append('address', this.allyForm.value.address);
      formData.append('postal_code_1', this.allyForm.value.postal_code_1);
      formData.append('nit', this.allyForm.value.nit);
      formData.append(
        'type_register',
        this.allyForm.value.registered_chamber_commerce
      );
      formData.append('business_name', this.allyForm.value.business_name);

      formData.append(
        'value_request',
        this.existCustomer
          ? this.currenValueRequest
          : this.allyForm.value.value_request.replace(/,/g, '')
      );

      formData.append('city_id', this.allyForm.value.city_id);
      formData.append(
        'businness_address',
        this.allyForm.value.businness_address
      );
      formData.append('postal_code_2', this.allyForm.value.postal_code_2);
      formData.append(
        'customer_business_types_id',
        this.allyForm.value.customer_business_types_id
      );
      formData.append('password', this.allyForm.value.password);
      //   formData.append('user_id_commerce', this.allyForm.value.user_id_commerce);
      formData.append('identification_type', this.identificationTypeNew);

      formData.append(
        'advisor_identification',
        this.allyForm.value.advisor_identification
      );

      // Información de comunicación y autorización de tratamiento de datos
      formData.append('comunication_all', this.allyForm.value.comunication_all);
      formData.append(
        'comunication_email',
        this.allyForm.value.comunication_email
      );
      formData.append(
        'comunication_message',
        this.allyForm.value.comunication_message
      );
      formData.append(
        'authorization_treatment_data',
        this.allyForm.value.authorization_treatment_data
      );
      formData.append('registraduria', this.allyForm.value.registraduria);

      const messageError = 'Ocurrió un error al guardar el registro.';
      // Realizar la solicitud para guardar el registro
      this.registerService
        .saveRegister(formData)
        .pipe(
          switchMap((response) => {
            if (response.ok) {
              const data = {
                date_expedition: this.documentExpeditionDate,
                document_number: formData.get('identification'),
                name: formData.get('name'),
                last_name: formData.get('last_name'),
              };

              return this.registerService.createPipeline(data);
            } else {
              this.modalService.openModal(
                'Error',
                response.message || messageError,
                'error'
              );
              return new Observable();
            }
          })
        )
        .subscribe({
          next: (response) => {
            toggleLoader(this.loaderService, false);
            this.modalService.openModal(
              '!Espera unos minutos mientras evaluamos tu solicitud!',
              'Recibirás un mensaje por WhatsApp si tu cupo ha sido aprobado, con instrucciones para finalizar tu registro y activar el cupo. Si no podemos aprobarlo hoy, te enviaremos un mensaje informativo.',
              'warning',
              600000,
              21
            );
            this.incrementar();
          },
          error: (error) => {
            toggleLoader(this.loaderService, false);
            this.modalService.openModal(
              'Error',
              error.error.message || messageError,
              'error'
            );
            console.log(error);
          },
        });
    } else {
      const invalidControls: any = {};
      const controls = this.allyForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalidControls[name] = controls[name].errors;
        }
      }
      console.log('Errores:', invalidControls);

      // Recorrer los errores y construir un mensaje
      let errorMessage = 'Aún faltan campos por diligenciar:\n';
      for (const field in invalidControls) {
        if (invalidControls.hasOwnProperty(field)) {
          errorMessage += `| ${field}\n`;
        }
      }

      // Marcar todos los campos como tocados
      this.markAllFieldsAsTouched();
      this.modalService.openModal('Error', errorMessage, 'error');
    }
  }

  /**
   * Realiza una solicitud de aprobación de cupo y maneja el proceso de registro exitoso.
   *
   * @returns {void}
   */
  requestQuotaApproval() {
    // Datos necesarios para la solicitud de aprobación de cupo
    const data = {
      identification: this.allyForm.controls['identification'].value,
      nit: this.allyForm.controls['nit'].value,
      document_type_id: this.allyForm.controls['identification_type'].value,
      businness_type_id:
        this.allyForm.controls['customer_business_types_id'].value,
    };
    // const data = {
    //   identification: 1000253230,
    //   nit: 800226646,
    //   document_type_id: 1,
    //   businness_type_id: 1,
    // };

    // Realizar la solicitud de aprobación de cupo
    const messageErrorQuota =
      'Ocurrió un error al solicitar la aprobación de cupo.';
    this.registerService.requestQuota(data).subscribe(
      (responseQuota: any) => {
        if (
          responseQuota.ok === true &&
          responseQuota.creditApproved === true
        ) {
          toggleLoader(this.loaderService, false);
          Swal.fire(
            'Felicitaciones!',
            responseQuota.message
              ? responseQuota.message
              : 'Felicitaciones! Se ha aprobado un cupo para ti',
            'success'
          ).then(() => {
            // Mostrar componente de carga y realizar inicio de sesión
            toggleLoader(
              this.loaderService,
              true,
              responseQuota.message
                ? responseQuota.message
                : 'Felicitaciones! Se ha aprobado un cupo para ti'
            );

            this.goLogin();
          });
        } else {
          toggleLoader(this.loaderService, false);
          // Manejo de error en la solicitud de cuota
          console.error(
            'Error en la solicitud de cuota:',
            responseQuota.message
          );

          this.modalService.openModal('Error', responseQuota.message, 'error');
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        // Manejo de errores en la solicitud de aprobación de cuota
        console.error('Error en la solicitud de cuota:', error);

        this.modalService.openModal(
          'Error',
          error.error.message ? error.error.message : messageErrorQuota,
          'error'
        );
      }
    );
  }

  /**
   * Realiza una solicitud de login luego del proceso de registro exitoso.
   *
   * @returns {void}
   */
  goLogin() {
    const email = this.allyForm.value.email;
    const password = this.allyForm.value.password;
    const messageErrorLogin =
      'Ocurrió un error al iniciar sesión. Inténtalo nuevamente.';
    // Iniciar sesión después del registro exitoso
    this.authService.login(email, password).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('AUTH LOGIN', response);
        if (response.ok) {
          this.authService.setUser(response.data);
          this.authService.setToken(response.token);
          const rol = Number(response.data.role_id);
          this.navService.updateMenu(rol);
          // Redirigir a clientes/inicio
          this.router.navigate(['clientes/inicio']);
        } else {
          this.modalService.openModal(
            'Error',
            response.message ? response.message : messageErrorLogin,
            'error'
          );
        }
      },
      (error) => {
        // Manejo de errores en la solicitud de aprobación de cuota
        toggleLoader(this.loaderService, false);

        this.modalService.openModal(
          'Error',
          error.error.message ? error.error.message : messageErrorLogin,
          'error'
        );
        this.router.navigate(['/']);
      }
    );
  }

  checkboxChanged(id: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.isCheckAuth = !this.isCheckAuth;
  }

  public resetVariables() {
    this.existAdress = false;
    this.existAdres2 = false;
  }

  /**
   * Este metodo lanza una advertencia antes de retroceder
   * y si el usuario acepta se resetea el formulario y se genera un nuevo checkId
   */
  public sureYouWantToGoBack() {
    const buttonConfig = {
      acceptText: 'Aceptar',
      cancelText: 'Rechazar',
      showCancel: true,
    };
    this.alertNewService
      .openModal(
        'Advertencia',
        'Si retrocedes, deberás iniciar el registro nuevamente. ¿Estás seguro que deseas retroceder?',
        'warning',
        buttonConfig
      )
      .subscribe((result) => {
        if (result) {
          const phone_1 = this.allyForm.get('phone_1')?.value;
          const phone_1_valido = this.allyForm.get('phone_1_valido')?.value;

          // Resetea el formulario
          this.resetForm();
          // Restaurar los valores de phone_1 y phone_1_valido
          this.allyForm.patchValue({
            phone_1: phone_1,
            phone_1_valido: phone_1_valido,
          });
          this.generateCheckId();

          this.StepService.setStep(5);
          this.countValidationPhoto = 0;
        }
      });
  }

  /**
   * Este metodo resetea el formulario y las imagenes
   */
  public resetForm() {
    this.allyForm.reset();
    this.initFormRegister();
    // Limpiar imágenes
    this.imagenFrontalVisible = false;
    this.imagenTraseraVisible = false;
    this.imagenSelfieVisible = false;
  }

  public onCitySelected(event: any) {
    this.city_id2 = event.id;
    this.city1 = event.name;
  }

  public backToAgain() {
    this.generateCheckId();
  }

  public onNumberStreet2Input(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Verificar si el valor ya tiene un número al principio
    const hasLeadingNumber = /^[0-9]/.test(value);

    if (!hasLeadingNumber) {
      // Si no hay un número al principio, eliminar las letras ingresadas
      value = value.replace(/[^0-9]/g, ''); // Permitir solo números
      this.validateCharacter = true;
    } else {
      // Si hay un número, permitir letras después
      value = value.replace(/[^0-9a-zA-Z]/g, ''); // Permitir números y letras
      this.validateCharacter = false;
    }

    // Actualizar el valor del input y el formulario
    input.value = value;
    this.allyForm.get('numberStreet2')?.setValue(value);
  }
}
