import { ChangeDetectorRef, Component } from "@angular/core";
import { CommerceDashboardService } from "../../services/commerce-dashboard.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import Swal from "sweetalert2";
import { DataMiniCards, SalesByCityInterface, SalesCity, TotalDayInterface, TotalMonthInterface, TotalShopQuotaInterface } from "../../interfaces/commerce-dashboard.interface";

@Component({
  selector: "app-commerce-dashboard-home",
  templateUrl: "./commerce-dashboard-home.component.html",
  styleUrls: ["./commerce-dashboard-home.component.scss"],
})
export class CommerceDashboardHomeComponent {

  // Para mostrar el skeleton
  public isLoading: boolean = true

  // Datos que le enviamos al hijop
  public dataOftotalDay: TotalDayInterface
  public dataOfminiCards: DataMiniCards
  public dataOftotalMonth: TotalMonthInterface
  public dataSendCity: SalesCity;

  
  public dataOfCoupon: TotalShopQuotaInterface
  public date!: string

  // Propiedad para obtener las ciudades con las que vamos a trabajar
  public salesCity: SalesByCityInterface[];
  
  // Datos para mostrar y controlar la paginacion de la grafica
  public currentPageCity: number = 1;
  public stateGraphic: boolean
  public limitCity: number
  /**
   * Constructor de la clase CustomerDashboardComponent.
   *
   * Este constructor inicializa la instancia de la clase CustomerDashboardComponent
   * y se encarga de inyectar los servicios y recursos necesarios para el componente.
   */
  constructor(
    private commerceDashboardService: CommerceDashboardService,
    private loaderService: LoaderService,
      private cdr: ChangeDetectorRef
  ) {}

  /**
   * Método ngOnInit de la clase PaymentsAlliesComponent.
   *
   * Este método se ejecuta durante la inicialización del componente y se encarga de manejar la lógica inicial.
   * Inicializa el indicador de carga y obtiene los créditos.
   */
  ngOnInit(): void {
    toggleLoader(this.loaderService, true);
    this.getDataDashboard();
    
  }

  /**
   * Obtiene los datos del panel de control del comercio
   * Actualiza las propiedades del componente con la respuesta del servicio.
   */
  getDataDashboard(): void {
    // Activa el loader
    toggleLoader(this.loaderService, true);
    const messageError =
      "Ocurrió un error al consultar la información del cliente.";
    // Realiza la solicitud para obtener datos del panel de control del cliente
    this.commerceDashboardService.getDataDashboard().subscribe(
      (response) => {
        if (response.ok) {
          // Desactiva el loader
          toggleLoader(this.loaderService, false);
          
          console.log(response.data)
          // Asigancion de datos a sus respectivas propiedades
          this.dataOfminiCards = {
            totalCustomers: response.data.total_customers,
            withdrawnCredits: response.data.withdrawn_credits,
            withdrawnCreditsCount: response.data.withdrawn_credits_count,
            creditsRequestsCount: response.data.credits_requests_count
          }

          this.dataOftotalDay = response.data.total_day
          this.dataOftotalMonth = response.data.total_month
          this.dataOfCoupon = response.data.total_shop_quota
          
          this.salesCity = response.data.sales_by_city
          this.date = response.data.total_month.current.date
          
          this.changeCitysBypagination(1)
          this.isLoading = false
        } else {
          // Desactiva el loader y muestra un mensaje de error en caso de respuesta no exitosa
          toggleLoader(this.loaderService, false);
          Swal.fire("Error", response.message || messageError, "error");
        }
      },
      (error) => {
        // Desactiva el loader y maneja el error del servicio
        toggleLoader(this.loaderService, false);
        Swal.fire("Error", error.error.message || messageError, "error");
      },
    
    );
  }

  /**
   * Este metodo nos sirve para controlar y mostrar
   * las ciudades de 15 en 15
   * y controlamos eso con paginacion 
   * @param index 
   */
  public changeCitysBypagination( index: number ){
    
    this.stateGraphic = false; // propiedad para contralar el estado de la grafica y el spinner
    this.currentPageCity = index; // le asignamos el valor que recibimos
    this.limitCity = Math.ceil(this.salesCity.length / 15) // redondeamos para validar la maxima pagina
    
    // *  Recortamos para que vaya en 15 en 15 dependiendo el currentPageCity
    const dataChoosed = this.salesCity.slice((15 * ( this.currentPageCity - 1 )),( (15 * this.currentPageCity)  ))
    
    // ? Crearemos los arreglos de las ciudades
    let label: string[] = []
    let data: number[] = [] 
    let id: number[] = []


    // * Recorremos el array con las 15 ciudades y le asignamos los valores a los arrays creados
    dataChoosed.forEach( ( city ) => {
          label.push( city.city )
          data.push( city.total_sales )
          id.push( city.id )
    })  

    /**
     * Le asignamos los valores al objeto dataSend
     * */ 
    this.dataSendCity = {
      label: label,
      data: data,
      id: id

    }

    // Establecemos un setTimeout para controlar el estado
    setTimeout(() =>{
      this.stateGraphic = true;
      this.cdr.detectChanges()
    }, 1500)

   
     
  }
}
