import { Component, forwardRef, Input, NgModule, Optional, ViewEncapsulation } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-inpit-document',
  templateUrl: './inpit-document.component.html',
  styleUrls: ['./inpit-document.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InpitDocumentComponent),
      multi: true
  }
  ]
})
export class InpitDocumentComponent implements ControlValueAccessor {
  @Input() formControlName:string = '';
  @Input() readonly:boolean = false;
  @Input() label:string = 'Documento de identidad';
  @Input() price:boolean = false;
  @Input() icon:string = "fa-solid fa-address-card";

  constructor(@Optional() private controlContainer: ControlContainer) { }

  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
}
  
get control(): FormControl {
    return this.form.get(this.formControlName) as FormControl;
  }

  registerOnChange() {}

  registerOnTouched() {} 

  writeValue() {}

  setDisabledState() {}
}
