<div class="media profile-media">
  <!-- <svg-icon src="assets/img-news/user-icon.svg"></svg-icon> -->
  <a (click)="logoutFunc()">
    <i class="fa-solid fa-arrow-right-from-bracket fa-lg"></i>
  </a>
</div>
<!-- <ul class="profile-dropdown onhover-show-div"> -->
  <!-- <li>
    <a><i data-feather="user"></i><span>Perfil </span></a>
  </li> -->
  <!-- <li
    ><a href="email/email-app"><i data-feather="mail"></i><span>Inbox</span></a></li
  > -->
  <!-- <li
    ><a href="task"><i data-feather="file-text"></i><span>Taskboard</span></a></li
  > -->
  <!-- <li
    ><a href="#"><i data-feather="settings"></i><span>Ajustes</span></a></li
  > -->
  <!-- <li>
    <a (click)="logoutFunc()"><i data-feather="log-in"> </i><span>Cerrar sesión</span></a>
  </li>
</ul> -->