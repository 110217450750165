import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'dateCustomer'
})
export class DateCustomerPipe implements PipeTransform {

  transform(value: string): string {
    // Parsear la fecha en un objeto Date
    const date = new Date(value);
    
    // Obtener el nombre del mes
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const month = monthNames[date.getMonth()];

    // Obtener el año
    const year = date.getFullYear();

    // Retornar la fecha formateada
    return `${month}/${year}`;
  }

}