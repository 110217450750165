import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-payment-how-much',
  templateUrl: './payment-how-much.component.html',
  styleUrls: ['./payment-how-much.component.scss']
})
export class PaymentHowMuchComponent {
  @Output() eventOption = new EventEmitter<string>();
  @Output() eventChooseOnlineCorrespondent = new EventEmitter<any>();
  @Output() eventSendValueAbono = new EventEmitter<any>();
  @Input() choosedCreditOld: any = [];
  @Input() itemsStep: any = [];
  @Input() paymentDeadline: string = '';
  @Input() date_status: string = '';
  @Input() chooseTotalPay:  any;

  showList: boolean = false;

  ngOnInit() {
    if(this.chooseTotalPay && this.chooseTotalPay.type == 2) {
      this.itemsStep = [
        {
          type: 0,
          description: 'Pago mínimo',
          description2: 'Para estar al día',
          price_sm: this.chooseTotalPay.min_payment
        },{
          type: 1,
          description: 'Pago total',
          description2: 'Deuda total hoy para liberar el cupo',
          price_sm: this.chooseTotalPay.total_payment
        },{
          type: 2,
          title_sm: 'Abonar a mi deuda',
          description2: 'Personaliza el control de tus pagos a tu manera'
        }
      ];
    } else if(this.chooseTotalPay.type == 1){
      this.itemsStep = this.choosedCreditOld.options;
    }
  }

  setOptionsChoose(option: string) {
    if(this.chooseTotalPay && this.chooseTotalPay.type == 2) {
      this.eventChooseOnlineCorrespondent.emit(option);
    } else if(this.choosedCreditOld.type == 1) {
      this.choosedCreditOld.amount_pay = option;
      if(option != '2') {
        this.eventChooseOnlineCorrespondent.emit(this.choosedCreditOld)
      } else {
        this.eventChooseOnlineCorrespondent.emit(option);
      }      
    }
  }

  sendValueAbono(objeto: any) {
    this.eventSendValueAbono.emit(objeto)
  }
}
