import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberFormat' })
export class NumberFormatPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value) || value === null) {
      return '-';
    }

    let parts = value.toFixed().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return '$' + parts.join(",");
  }
}
