import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
      selector: 'app-payment-credits',
      templateUrl: './payment-credits.component.html',
      styleUrls: ['./payment-credits.component.scss']
})
export class PaymentCreditsComponent {
      @Output() eventOptionByType3 = new EventEmitter<string>();
      @Input() itemsStep: any = [];
      showList = false;

      setOptionsChoose(option: string) {
            this.eventOptionByType3.emit(option);
      }
}
