import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/Loader.service';

@Component({
      selector: 'app-loader',
      templateUrl: './loader.component.html',
      styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
      message = 'Cargando...';
      constructor(public loaderService: LoaderService) { }

      ngOnInit() {
            this.loaderService.message$.subscribe((message) => {
                  if (message) {
                        this.message = message;
                  }
            });
      }

      ngOnDestroy() { }
}
