import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditsDocumentsResponseData } from 'src/app/administrative/credits-requests/interfaces/credits-documents-interface';
import { CreditsNotesResponseData } from 'src/app/administrative/credits-requests/interfaces/credits-notes-interface';
import { CreditRequestService } from 'src/app/administrative/credits-requests/services/credit-request.service';
import { CreditWithArrearInterface } from 'src/app/administrative/credits/interfaces/credits-with-arrears-interface';
import { CustomerInterface } from 'src/app/administrative/customers/interfaces/customer-interface';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { ParemetersService } from 'src/app/shared/services/parameters.service';
import { CustomerService } from "src/app/administrative/customers/services/customer-service";
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { ModalFinantialComponent } from '../../modal-finantial/modal-finantial.component';

@Component({
      selector: 'app-customer-info-show-modal',
      templateUrl: './customer-info-show-modal.component.html',
      styleUrls: ['./customer-info-show-modal.component.css'],
})
export class CustomerInfoShowModalComponent {
      /**************************
       * Propiedades
       *********************/
      @Input() title: string = 'Solicitud de crédito';
      tabActiva = 'informacionPersonal';
      @Input() customerInfo: CustomerInterface; // Propiedad para almacenar la información del cliente
      @Input() creditRequestId: number; // Propiedad para almacenar el id de la solicitud de crédito
      @Input() datosDeshabilitados: boolean = true; // Inicialmente, los datos están deshabilitados
      canSendWhatsapp: boolean = false;
      cities: any = [];
      departments: any = [];
      isAdmin: boolean = false;
      ccis: any = [
            { id: 1, name: 'Si' },
            { id: 2, name: 'No' },
      ];
      typeIdentifications: any = [
            { id: 'CC', name: 'CC' },
            { id: 'CE', name: 'CE' },
      ];
      customerForm: FormGroup; // Formulario reactivo
      customerEditForm: FormGroup; // Formulario reactivo para editar la información del cliente
      notasAgregadas: CreditsNotesResponseData[] = [];
      documentsList: CreditsDocumentsResponseData[] = [];
      textareaContent: string; // Propiedad para almacenar el contenido del textarea
      historyCreditsCustomerList: CreditWithArrearInterface[]; // Propiedad para almacenar el historial de créditos del cliente

      /**************************
       * constructor
       *********************/
      constructor(
            public activeModal: NgbActiveModal,
            private CreditRequestService: CreditRequestService,
            private customerService: CustomerService,
            private formBuilder: FormBuilder,
            private loaderService: LoaderService,
            private localitationService: ParemetersService,
            private modalService: NgbModal
      ) {
            // Inicializar el formulario reactivo
            this.customerForm = this.formBuilder.group({
                  id: [this.customerInfo?.id],
                  nit: [this.customerInfo?.nit],
                  name: [this.customerInfo?.name],
            });
      }

      /**************************
       * Inicio del ciclo de vida
       * ********************/
      ngOnInit(): void {
            this.setFormCustomer();
            this.getRole();

            const departmentCityId = this.customerInfo?.get_city?.get_department_city?.id;

            const requests: any = {
            departments: this.localitationService.getDepartments(),
            notes: this.CreditRequestService.getCustomerNotes(this.creditRequestId),
            documents: this.CreditRequestService.getCustomerDocuments(this.customerInfo.id),
            historyCredits: this.CreditRequestService.getHistoryCreditsCustomers(this.customerInfo.id),
            };

            if (departmentCityId) {
                  requests.cities = this.localitationService.getCitiesByDepartments(departmentCityId);
            }

            forkJoin( requests ).subscribe({
                  next: (results: any) => {
                        this.departments = results.departments.data;
                        if(departmentCityId) {
                              this.cities = results.cities.data;
                        }
                        this.notasAgregadas = results.notes.data;
                        this.documentsList = results.documents.data;
                        this.historyCreditsCustomerList = results.historyCredits.data;

                        toggleLoader(this.loaderService, false);
                  },
                  error: (error) => {
                        console.log('Ocurrió un error en alguna de las peticiones: ', error);
                        // Ocultar el loader incluso si hay un error
                        toggleLoader(this.loaderService, false);
                        Swal.fire('Error', 'Ocurrió un error al cargar la información. Por favor, inténtalo nuevamente.', 'error');
                  }
            });


      }

      getRole() {
            const userString = localStorage.getItem('user');
            if (userString) {
                  const user = JSON.parse(userString);
                  this.isAdmin = user.role_id == '1';
            }
      }

      /**************************
       * metodos
       *********************/

      cambiarPestana(pestana: string) {
            this.tabActiva = pestana;
      }

      subirDocumento(fileInput: any) {
            // Lógica para subir documentos
            const file = fileInput.files[0];

            if (!file) {
                  Swal.fire('Error', 'No se seleccionó un archivo.', 'error');
                  return;
            }
            toggleLoader(this.loaderService, true, 'Subiendo documento...');
            const customerId = this.creditRequestId;
            const formdata = new FormData();
            formdata.append('file', file);
            formdata.append('credit_request_id', customerId.toString());
            formdata.append('customer_id', this.customerInfo.id.toString());
            this.CreditRequestService.saveCustomerDocument(formdata).subscribe({
                  next: (response) => {
                        toggleLoader(this.loaderService, false);
                        if (response.ok) {
                              Swal.fire('Éxito', 'Se subió el documento correctamente.', 'success');
                              fileInput.value = '';
                              this.getDocumentsCredits();
                        }
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        Swal.fire('Error', 'Ocurrio un error al subir el documento', 'error');
                  }
            });
      }

      habilitarDatos() {
            this.datosDeshabilitados = false; // Habilitar los datos cuando se hace clic en el botón correspondiente
      }

      ActualizarDatos() {
            if (this.customerEditForm.valid) {
                  toggleLoader(this.loaderService, true);
                  this.CreditRequestService.updateInfoCustomer(this.customerEditForm.value).subscribe(res => {
                        toggleLoader(this.loaderService, false);
                        console.log(res);
                        if (res.ok) {
                              if (res.message && res.message.includes("Se enviará un nuevo enlace")) {
                                    this.canSendWhatsapp = true;
                              }
                              Swal.fire('Éxito', 'Se actualizó la información del cliente.', 'success');
                        } else {
                              const campos = Object.keys(res.message);
                              let text = "faltan los campos: ";

                              if (campos.length > 1) {
                                    for (let i = 0; i < campos.length; i++) {
                                          if (i === campos.length - 1) {
                                                text = text + campos[i];
                                          } else {
                                                text = text + campos[i] + ", ";
                                          }
                                    }
                              }
                              Swal.fire('Error', text, 'error');
                        }
                  });
            } else {
                  Swal.fire('Error', 'Por favor, completa todos los campos.', 'error');
            }
      }

      saveNotes() {
            const customerId = this.creditRequestId;
            const valueNote = this.textareaContent;
            console.log('Contenido del textarea:', this.textareaContent);
            toggleLoader(this.loaderService, true);
            this.CreditRequestService.saveCustomerNote(customerId, valueNote).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        console.log('Response from server: ', response);
                        if (response.ok) {
                              this.getNotesCredits();
                              Swal.fire('Éxito', 'Se agrego una nueva nota.', 'success');
                        } else {
                              toggleLoader(this.loaderService, false);
                              Swal.fire(
                                    'Error',
                                    'No se guardo la nota intenta nuevamente.',
                                    'error'
                              );
                        }
                  }
            );
      }

      getNotesCredits() {
            toggleLoader(this.loaderService, true);
            const customerId = this.creditRequestId;
            this.CreditRequestService.getCustomerNotes(customerId).subscribe(
                  (response) => {
                        console.log('Response from server: ', response);
                        if (response.ok) {
                              this.notasAgregadas = response.data;
                        } else {
                              Swal.fire(
                                    'Error',
                                    'No se pudo obtener las notas. Inténtalo nuevamente.',
                                    'error'
                              );
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        Swal.fire(
                              'Error',
                              'Ocurrió un error al obtener las notas. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                        console.log('Error: ', error);
                  }
            );
      }

      getDocumentsCredits() {
            toggleLoader(this.loaderService, true);
            const creditRequestId = this.creditRequestId;
            this.CreditRequestService.getCustomerDocuments(this.customerInfo.id).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        console.log('Response from server: ', response);
                        if (response.ok) {
                              this.documentsList = response.data;
                              console.log(this.documentsList)
                        } else {
                              Swal.fire(
                                    'Error',
                                    'No se pudieron obtener los documentos. Inténtalo nuevamente.',
                                    'error'
                              );
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        Swal.fire(
                              'Error',
                              'Ocurrió un error al obtener los documentos. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                        console.log('Error: ', error);
                  }
            );
      }

      getHistoryCredits() {
            const customerInfoId = this.customerInfo.id;
            toggleLoader(this.loaderService, true);
            this.CreditRequestService.getHistoryCreditsCustomers(
                  customerInfoId
            ).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        console.log('Response from server: ', response);
                        if (response.ok) {
                              this.historyCreditsCustomerList = response.data;
                        } else {
                              Swal.fire(
                                    'Error',
                                    'No se pudieron obtener los créditos. Inténtalo nuevamente.',
                                    'error'
                              );
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        Swal.fire(
                              'Error',
                              'Ocurrió un error al obtener los créditos. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                        console.log('Error: ', error);
                  }
            );
      }

      getCitiesByDepartments(departmentId: number) {
            toggleLoader(this.loaderService, true);
            this.localitationService.getCitiesByDepartments(departmentId).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  this.cities = res.data;
            });
      }

      setFormCustomer() {
            this.customerEditForm = this.formBuilder.group({
                  id: [this.customerInfo.id, Validators.required],
                  nit: [this.customerInfo.nit, Validators.required],
                  buss_name: [this.customerInfo.buss_name, Validators.required],
                  department_id: [this.customerInfo.city_id ? this.customerInfo.get_city.department_id : null, Validators.required],
                  city_id: [this.customerInfo.city_id ? this.customerInfo.get_city.id : null, Validators.required],
                  businness_address: [this.customerInfo.businness_address, Validators.required],
                  registered_chamber_commerce: [this.customerInfo.cci == 'si' ? 1 : 2, Validators.required],
                  name: [this.customerInfo.name, Validators.required],
                  last_name: [this.customerInfo.last_name, Validators.required],
                  identification_type: [this.customerInfo.identification_type, Validators.required],
                  identification: [this.customerInfo.identification, Validators.required],
                  phone: [this.customerInfo.phone ?? null, Validators.required],
                  phone_2: [this.customerInfo.phone_2 ?? null, Validators.required],
                  email: [this.customerInfo.email, Validators.required],
                  address: [this.customerInfo.address, Validators.required]
            });
      }

      changeArrayCities() {
            this.customerEditForm.get('city_id')?.setValue(null);
            this.getCitiesByDepartments(this.customerEditForm.get('department_id')?.value);
      }

      sendWhatsappLinkDeceval() {
            toggleLoader(this.loaderService, true);
            this.customerService.sendWhatsappLinkDeceval(this.customerEditForm.value.id).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  if (res.ok) {
                        Swal.fire('Exito', res.message, 'success');
                  } else {
                        Swal.fire('Error', res.message, 'error');
                  }
            })
      }

      public downloadFile(file: string) {
            toggleLoader(this.loaderService, true, 'Descargando archivo...');
            this.CreditRequestService.downloadFile(file).subscribe({
                  next: (response: any) => {
                        const blob = new Blob([response], { type: response.type });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = file;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        toggleLoader(this.loaderService, false);
                  },
                  error: (error) => {
                        console.log(error);
                        Swal.fire('Error', 'Ocurrió un error al descargar el archivo.', 'error');
                        toggleLoader(this.loaderService, false);
                  }
            });
      }

      public openFinancialInfoModal(customer: any) {
            const modalRef = this.modalService.open(ModalFinantialComponent, {
                  size: 'lg'
            });
            modalRef.componentInstance.customerId = customer.id;
      }
}
