import { Component, OnInit, Input } from "@angular/core";
import Chart, { ChartEvent, ChartOptions, ChartType } from "chart.js/auto";
import { SalesCity } from "../../interfaces/commerce-dashboard.interface";
import { Router } from "@angular/router";

@Component({
  selector: "app-graphic-dashboard",
  templateUrl: "./graphic-dashboard.component.html",
  styleUrls: ["./graphic-dashboard.component.scss"],
})
export class GraphicDashboardComponent  {
  public chart: Chart; // ? Esta propiedad es para renderizar la grafica

  @Input() public dataOfCity: SalesCity

  // Contiene la data que se renderizar en la grafica
  private data = {
    labels: [
      { id: 1, name: "Barranquilla" },
      { id: 2, name: "Cali" },
      { id: 3, name: "Bogota" },
      { id: 4, name: "Bucaramanga" },
      { id: 5, name: "Ibague" },
      { id: 6, name: "Medellin" },
      { id: 7, name: "Cartagena" },
      { id: 8, name: "Pereira" },
      { id: 9, name: "Pasto" },
    ],
    datasets: [
      {
        label: "Ventas",
        data: [65, 59, 80, 44, 56, 73, 23, 82, 40, 50, 60, 44,22,33,44,55,44],
        backgroundColor: [
          "rgb(255, 153, 51)",
          "rgb(102, 204, 255)",
          "rgb(255, 102, 102)",
          "rgb(51, 153, 102)",
          "rgb(204, 102, 255)",
          "rgb(255, 204, 51)",
          "rgb(153, 51, 255)",
          "rgb(0, 153, 204)",
          "rgb(255, 51, 153)",
          "rgb(51, 204, 153)",
          "rgb(204, 0, 102)",
          "rgb(0, 204, 204)",
          "rgb(255, 102, 204)",
          "rgb(102, 255, 102)",
          "rgb(255, 153, 204)"
        ],
        borderWidth: 0,
      },
    ],
  };

  constructor( private router: Router ){}

  ngAfterViewInit(): void {
    if (!this.dataOfCity || !this.dataOfCity.label.length || !this.dataOfCity.data.length) {
      return;
    }
    this.data.labels = this.dataOfCity.label.map((cityName, index) => {
      return { id: this.dataOfCity.id[index], name: cityName };
    });

    this.data.datasets[0].data = this.dataOfCity.data;
    this.changeChartType();
  }


  /**
   * Este metodo es para configurar la grafica
   * @param type => string
   * @param eje => enum 'x' | 'y'
   * @returns { void }
   */
  private configurationsChart(type: string, eje: 'x' | 'y'): void {
    Chart.defaults.font.family = "Poppins"; // Con este le indicamos que toda la grafica va tener ese tipo de letra
    Chart.defaults.color= "#1E2F65"

    if (this.chart) {
      this.chart.destroy();
    }
    const cityLabels = this.data.labels.map(city => city.name);

    this.chart = new Chart("chart", {
      type: type as ChartType,
      data: {
        labels: cityLabels,
        datasets: this.data.datasets
      },
      options: {
        responsive: true,
        indexAxis: eje,
        plugins: {
          // Esta parte controla el titulo de la grafica
          title: {
            display: true,
            text: "Ciudades con mayores ventas",
            font:{
              size: 20
            }
          },
          legend: {
            labels: {
              boxWidth: 30, // Aca controlamos la anchura para el rectangulo de ventasa
            },
          },
          
        },
        onClick: this.onClick.bind(this),
        
      },
    });

  }

  /**
   * Este metodo es para crear un grafico diferente dependiendo del tamaño de la pantalla 
   * @param {void}
   * @returns { void}
   */
  private changeChartType(): void {

    // Definimos las  consultas de medios para verificar el tamaño de la pantalla
    const mediaQuery880 = window.matchMedia("(max-width: 880px)");
    const mediaQuery600 = window.matchMedia("(max-width: 669px)");
  
    // Función para actualizar el gráfico según el tamaño de la pantalla
    const updateChart = () => {
      if (mediaQuery600.matches) {
        this.configurationsChart('doughnut', 'x'); // Gráfico circular para pantallas <= 669px
      } else if (mediaQuery880.matches) {
        this.configurationsChart('bar', 'x');   // Gráfico de barras (horizontal) para pantallas <= 880px
      } else {
        this.configurationsChart('bar', 'y');   // Gráfico de barras (vertical) para pantallas más grandes
      }
    };
  
    // Agregar oyentes de eventos a las consultas de medios para llamar a updateChart al cambiar el tamaño
    mediaQuery880.addListener(updateChart);
    mediaQuery600.addListener(updateChart);
  
    // Llamar a updateChart inicialmente para establecer el tipo de gráfico según el tamaño actual de la pantalla
    updateChart();
  }

  private onClick(event: ChartEvent, items: any[]) {
    if (items.length > 0) {
      const clickedCityIndex = items[0].index; // Índice de la barra clicada
      const clickedCityId = this.data.labels[clickedCityIndex].id; // ID de la ciudad correspondiente al índice
      this.router.navigate(['creditos/listado', 1, clickedCityId]); // Navegar a la ruta deseada con el ID de la ciudad
    }
  }
  


}
