import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-all-credits',
  templateUrl: './loader-all-credits.component.html',
  styleUrls: ['./loader-all-credits.component.scss']
})
export class LoaderAllCreditsComponent {

}
