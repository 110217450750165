<form [formGroup]="customerForm">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            ¿Cuánto cupo deseas editarle al cliente?
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
        <!-- total cupo aprobado -->
        <div class="row">
            <div class="d-flex gap-2">
                <div class="card mb-2 w-100 border border-success border-opacity-25 rounded">
                    <div class="card-body p-3">
                        <h4 class="card-title">Cupo Aprobado</h4>
                        <h2>{{ cupoApproved | currency : "$" : "symbol" : "1.0-0" }}</h2>
                        <div>
                            <p class="card-text">¡Total cupo aprobado del cliente!</p>
                        </div>
                    </div>
                </div>

                <div class="card mb-2 w-100 border border-success border-opacity-25 rounded" *ngIf="cupoActived == 'No'">
                    <div class="card-body p-3">
                        <div class="d-flex gap-2">
                            <h4 class="card-title">Cupo no activado</h4>
                            <h2>
                                <i class="fa fa-exclamation-circle mr-2 text-danger" style="font-size: 24px"></i>
                            </h2>
                        </div>
                        <div>
                            <p class="card-text">El cliente aún no ha firmado el pagaré.</p>
                            <small>https://creditos.somosziro.com/firma/pagare/{{customerIdentification}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="identification">Identificación</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="identification" 
                        id="identification">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'identification') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="nit">Nit</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="nit" 
                        id="nit">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'nit') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="name">Nombres</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="name" 
                        id="name">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'name') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="last_name">Apellidos</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="last_name" 
                        id="last_name">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'last_name') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="buss_name">Nombre negocio</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="buss_name" 
                        id="buss_name">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'buss_name') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="email" 
                        id="email">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'email') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="phone">Télefono</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="phone" 
                        id="phone">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'phone') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="city_id">Ciudad</label>
                    <select class="form-control" formControlName="city_id" id="city_id">
                        <option *ngFor="let city of citiesList" [value]="city.id">{{ city.name }}</option>
                    </select>
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'city_id') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="address">Dirección personal</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="address" 
                        id="address">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'address') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="businness_address">Dirección empresa</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="businness_address" 
                        id="businness_address">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'businness_address') as error">
                    {{ error }}
                </p>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-group">
                    <label for="total_approved_quota">Cupo aprobado</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        formControlName="total_approved_quota" 
                        id="total_approved_quota">
                </div>
                <p class="text-danger" *ngIf="validateCampo(customerForm, 'total_approved_quota') as error">
                    {{ error }}
                </p>
            </div>
            
        </div>
        <div class="d-flex justify-content-end">
                <button *ngIf="canSendWhatsapp" type="button" class="btn btn-success me-2" (click)="sendWhatsappLinkDeceval()">
                    Enviar enlace de pagare
                </button>
            <button type="button" class="btn btn-dark me-2" (click)="activeModal.dismiss()">
                Cerrar
            </button>
            <button type="button" class="btn btn-info d-flex align-items-center gap-2"
                (click)="customerUpdate()">
                Actualizar
                <i class="fa-solid fa-wand-magic-sparkles"></i>
            </button>
        </div>
    </div>
</form>