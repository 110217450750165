import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultIfEmpty'
})
export class DefaultIfEmptyPipe implements PipeTransform {
  transform(value: string | any[] | null | number  , defaultValue: string): any {
    if( Array.isArray(value) && value.length === 0) {
      return value.length > 0 ? value : defaultValue
    } else {
      return ( value !== null && value !== '' ) ? value : defaultValue;
    }
  }
}