import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-components',
  templateUrl: './spinner-components.component.html',
  styleUrls: ['./spinner-components.component.scss']
})
export class SpinnerComponentsComponent {

  @Input() public state: boolean



}
