<!-- <div class="one_page">
  <div class="one_page_content">
    <div class="row">
      <div class="col-12">
        <img
          class="img-fluid"
          src="../../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
      </div>
      <div class="col-12 celebration">
        <div class="img_figure"></div>
        <h1>{{ customerInfo.name | titlecase }}</h1>
      </div>

      <div class="col-12">
        <span class="highlighted-text">
          Tu información ha sido validada y tienes un cupo pendiente por
          activar. Haz clic en 'Enviar código OTP por WhatsApp' para continuar y
          sigue las instrucciones
        </span>
      </div>
      <div class="col-12">
        <button
          type="button"
          class="btn btn-success"
          (click)="sendCodeOtpPagare()"
        >
          Enviar código OTP por WhatsApp
        </button>
      </div>
    </div>
  </div>
</div> -->

<div class="one_page">
  <div class="one_page_content">
    <div class="img_figure">
      <img
        src="../../../../assets/img-news/logo-ilustration.svg"
        alt="Figuras"
      />
    </div>

    <div class="welcome">
      <h1  class="text-send" ><b>Paso Final</b></h1>
      <div class="welcome-info">
        <h1 class="text-send">Recibirás un código vía  SMS para activar tu cupo</h1>
      </div>

      <form [formGroup]="myInputFormNumber"  class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1"> <i class="fa-solid fa-phone" style="color: #064853;"></i> </span>
        <input formControlName="phone" type="number" class="form-control" placeholder="Digita número celular." aria-label="Username" aria-describedby="basic-addon1">
        <span class="info-mini"> Por seguridad, ingresa el número de célula donde recibes llamadas. Si es el mismo que ingresaste al inicio, introducelo nuevamente. </span>
      </form>
      <span *ngIf="myInputFormNumber.get('phone')!.invalid && myInputFormNumber.get('phone')!.touched" class="text-danger">
        {{ getPhoneNumberErrorMessage() }}
      </span>



      <button type="button" (click)="sendCodeOtpPagare()">Activar cupo</button>
    </div>
    <div class="ilustration">
      <img
        src="../../../../assets/img-news/user_password.gif"
        alt="Ilustración"
      />
    </div>
  </div>
</div>

