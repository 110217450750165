import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-rues',
  templateUrl: './table-rues.component.html',
  styleUrls: ['./table-rues.component.scss']
})
export class TableRuesComponent {

  @Input() public infoRues: any[] = [];

  
}
