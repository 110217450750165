import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';

@Component({
        selector: 'app-payment-view5',
        templateUrl: './payment-view5.component.html',
        styleUrls: ['./payment-view5.component.scss']
})
export class PaymentView5Component {
        @Input() totalPayment: any;
        @Output() eventOptionAbonoSend = new EventEmitter<any>;
        itemsStep: any
        showInputAbono: boolean = true;
        form: FormGroup = this.fb.group({
                abono: ["", [Validators.required, Validators.minLength(4), Validators.pattern("^[0-9]+$")]]
        })

        constructor(private fb: FormBuilder, private ModalServiceAlert: ModalServiceAlert,) {

        }

        ngOnInit() {
                this.itemsStep = [
                        {
                                type: 0,
                                description2: 'Deuda total hoy para liberar el cupo',
                                description: ' ',
                                price_sm: this.totalPayment
                        }, {
                                type: 1,
                                title_sm: "Abonar a mi deuda",
                                description2: "Personaliza el control de tus pagos a tu manera"
                        }
                ];
        }

        setOptionsChoose(option: string) {
                if (option == '0') {
                        // Ir a pagar el total
                        this.eventOptionAbonoSend.emit({ option: 0, value: null });
                } else {
                        this.form.get('abono')?.setValue("");
                        this.showInputAbono = !this.showInputAbono;
                }
        }

        eventSendValueAbono() {
                // Enviar el valor a abonar
                if (this.form.value.abono >= 1000) {
                        if (this.form.value.abono > this.totalPayment) {
                                this.ModalServiceAlert.openModal("Alerta!", "Debe pagar como máximo el valor total de tu deuda!", "warning");
                        } else {
                                this.eventOptionAbonoSend.emit({ option: 1, value: this.form.value.abono });
                        }
                } else {

                        this.ModalServiceAlert.openModal("Alerta!", "Debe pagar como mínimo $1.000!", "warning");
                }
        }
}
