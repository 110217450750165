import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var bootstrap: any;

@Component({
        selector: 'app-payment-modal',
        templateUrl: './payment-modal.component.html',
        styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent {
        @Input() data: any;
        @Output() eventCloseModal = new EventEmitter<boolean>();
        myModal: any;

        constructor() { }
        ngOnInit(): void {
                this.openModal();
                setTimeout(() => {
                        const backdrop = document.querySelector('.modal-backdrop.fade.show');
                        if (backdrop) {
                                backdrop.remove();
                        }
                }, 100);
        }


        openModal() {
                this.myModal = new bootstrap.Modal(document.getElementById('exampleModal'), {
                        keyboard: false
                });
                this.myModal.show();
        }

        closeModal() {
                this.myModal.hide();
                this.eventCloseModal.emit(false);
        }
}