<!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->
<div class="container d-flex justify-content-center px-4 align-items-center ">
    <div class="d-flex flex-column" >
        <header>
            <h2 class="fs-2 m-0 pb-2">Selecciona una de las opciones</h2>
            <p class="pb-3">
                ¡Estamos mejorando para ti! Hemos migrado a una nueva plataforma y <span class="text-ziro"> aún puedes tener obligaciones anteriores </span> . Si realizaste compras antes del 2 de noviembre, elige la opción  <span class="text-ziro">pago de obligaciones anteriores</span>.
            </p>
        </header>

        <app-list-group (eventOption)="setOptionsChoose($event)" [items]="itemsStep"></app-list-group>
        
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
