import { Routes } from "@angular/router";

export const customerRouters: Routes = [
  /***********************
   * RUTAS USUARIO CLIENTE*
   **********************/
  {
    path: "clientes/inicio",
    loadComponent: () =>
      import(
        "../../customer-standalone/pages/customer-home/customer-home.component"
      ).then((c) => c.CustomerHomeComponent),
  },
  {
    path: "clientes/solicitudes-credito",
    loadComponent: () =>
      import(
        "../../customer-standalone/pages/customer-request-increase/customer-request-increase.component"
      ).then((c) => c.CustomerRequestIncreaseComponent),
  },
  {
    path: "clientes/compras",
    loadComponent: () =>
      import(
        "../../customer-standalone/pages/customer-purchases/customer-purchases.component"
      ).then((c) => c.CustomerPurchasesComponent),
  },
  {
    path: "clientes/pagos",
    loadComponent: () =>
      import(
        "../../customer-standalone/pages/customer-payments/customer-payments.component"
      ).then((c) => c.CustomerPaymentsComponent),
  },
  {
    path: "clientes/compras-sin-entregar",
    loadComponent: () =>
      import(
        "../../customer-standalone/pages/customer-purchases-not-delivered/customer-purchases-not-delivered.component"
      ).then((c) => c.CustomerPurchasesNotDeliveredComponent),
  },
  { path: 'webhook', loadChildren: () => import('../../webhook/webhook.module').then((m) => m.WebhookModule) },
];
