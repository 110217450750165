import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-trasunion',
  templateUrl: './loader-trasunion.component.html',
  styleUrls: ['./loader-trasunion.component.scss']
})
export class LoaderTrasunionComponent {

}
