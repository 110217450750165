<ng-template #modalRequestWithdrawal let-modal>
    <form [formGroup]="formWithdrawCredit">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Confirmar retiro del crédito
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <h4>Información del cliente</h4>
                <div class="col-12">
                    <b>Cliente:</b> {{ customer.name | titlecase }}
                    {{ customer.last_name | titlecase }} <br />
                    <b>Cédula:</b> {{ customer.identification }}
                </div>
            </div>
            <hr />
            <!-- <div class="mb-4">
          <h4>¿El cliente ya realizó el pedido?</h4>
        </div> -->

            <div class="row">
                
                <div class="col-md-6 col-sm-12 mb-3">
                    <label for="valueCredit"> Número de orden </label>
                    <div class="input-group">
                        <input class="form-control" formControlName="order_number" [readonly]="flagConfirmCreditAction" />
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formWithdrawCredit, 'order_number') as error">
                        {{ error }}
                    </p>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label for="frecuency">Linéa de crédito</label>
                    <div class="input-group">
                        <!-- <select 
                            class="form-control" 
                            formControlName="creditLine" 
                            [attr.readonly]="flagConfirmCreditAction ? true : null">
                            <option *ngFor="let item of creditsLinesShop" [value]="item?.id">{{ item?.name }}</option>
                        </select> -->

                        <ng-select class="w-100 h-100" formControlName="creditLine" [items]="creditsLinesShop"
                            bindLabel="name" bindValue="id" [attr.readonly]="flagConfirmCreditAction ? true : null"
                            placeholder="Seleccionar Linea de Crédito"></ng-select>
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formWithdrawCredit, 'creditLine') as error">
                        {{ error }}
                    </p>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label for="valueCredit"> Valor total del crédito a utilizar </label>
                    <div class="input-group">
                        <input class="form-control" formControlName="valueCredit" [readonly]="flagConfirmCreditAction"
                            (input)="formatCurrencyInputAction($event, false)" />
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formWithdrawCredit, 'valueCredit') as error">
                        {{ error }}
                    </p>
                </div>

                <!-- <div class="col-md-6 col-sm-12 mb-3">
                    <label for="numberCuotesCredit">A cuántas cuotas</label>
                    <div class="input-group">
                        <select class="form-control" formControlName="numberCuotesCredit">
                            <option *ngFor="let item of numberCuotesOptions" value="{{item}}">{{item}}</option>
                        </select>
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formWithdrawCredit, 'numberCuotesCredit') as error">
                        {{ error }}
                    </p>
                </div> -->

                <div class="col-md-6 col-sm-12 mb-3">
                    <label for="valueCuote">Valor cuota</label>
                    <input class="form-control" formControlName="valueCuote" readonly />
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="flagShowCodeInput">
                    <label for="codeSendToCustomer">Código enviado al WhatsApp o email del cliente</label>
                    <input class="form-control" formControlName="codeSendToCustomer"
                        [readonly]="flagConfirmCreditAction" />
                    <p class="text-danger" *ngIf="
              validateCampo(formWithdrawCredit, 'codeSendToCustomer') as error
            ">
                        {{ error }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="mt-4">
                    <button class="btn btn-primary me-2" (click)="calculateQuote()" *ngIf="!flagConfirmCreditAction">
                        <i class="fa fa-calculator"></i>
                        Calcular cuota
                    </button>
                    <button class="btn btn-info me-2" (click)="verifyOrderNumberAndSendCodeOtp()" *ngIf="!flagConfirmCreditAction">
                        <i class="fa fa-paper-plane"></i>
                        Enviar códigos al cliente
                    </button>
                    <button class="btn btn-success me-2" *ngIf="flagShowCodeInput && !flagConfirmCreditAction"
                        (click)="validateCodeOtp()">
                        <i class="fa fa-paper-plane"></i>
                        Validar código
                    </button>

                    <button class="btn btn-success me-2" *ngIf="flagConfirmCreditAction" (click)="applyCredit()">
                        <i class="fa fa-paper-plane"></i>
                        Confirmar crédito
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>