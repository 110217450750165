import { Component, Input } from '@angular/core';
import { PaymentServiceCustomer } from '../../service/payment-service.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";

@Component({
        selector: 'app-payment-correspondent',
        templateUrl: './payment-correspondent.component.html',
        styleUrls: ['./payment-correspondent.component.scss']
})
export class PaymentCorrespondentComponent {
        @Input() data: any;
        abono: string = '';
        choosedCorrespondent: any;
        message: string = '';
        currentPage: number = 1;
        payment_reference: any = [];
        correspondents: any = [
            {
                  "name": "bancario BBVA",
                  "url_img": "https://zirotemplates.s3.sa-east-1.amazonaws.com/images/bbva.png",
                  "agreement_number": "38446"
            },
            {
                  "name": "Bancolombia",
                  "url_img": "https://zirotemplates.s3.sa-east-1.amazonaws.com/images/convenio-bancolombia.png",
                  "agreement_number": "92379"
            }
      ];

        constructor(private paymen_service: PaymentServiceCustomer, public loaderService: LoaderService) { }

        changeView(bank: string, correspondent_index: number) {
                this.message = bank;
                if (bank == 'bancolombia') {
                        this.payment_reference = [];
                        if(this.data.credit.type_payment == '2') {
                                this.data.credit.credits_new.forEach((credit: any) => {
                                        this.payment_reference.push({
                                                total_balance: credit.total_balance,
                                                code_pay: credit.code_pay
                                        });
                                });
                        } else {
                                this.payment_reference = [{
                                        total_balance: this.returnValueToPay(),
                                        code_pay: this.data.credit.credits_old[this.data.index].code_pay
                                }];
                        }
                }
                this.choosedCorrespondent = this.correspondents[correspondent_index];
                this.currentPage = 2;
        }

        returnValueToPay() {
                if (this.data.credit.type == 1) {
                        if (this.data._credit_choosed && this.data._credit_choosed == 2) {
                                this.abono='(Abono)';
                                return this.data.abono;
                        } else {
                                return this.data.total_credit;
                        }
                } else if (this.data.credit.type == 2) {
                        if (this.data._credit_choosed && this.data._credit_choosed == 2) {
                                this.abono='(Abono)';
                                return this.data.abono;
                        } else {
                                return this.data.credit.total_payment;
                        }
                }
        }

        showNumberForBancolombia(identification: number) {
                const identificationStr = identification.toString(); // Convertir el número a cadena
                const paddedIdentification = identificationStr.padStart(13, '0'); // Rellenar con ceros hasta 13 dígitos
                console.log(paddedIdentification);
                return paddedIdentification;
        }
}
