<div class="four_page">
  <div class="important">
    <span (click)="decrementar()"
      ><i class="fa-solid fa-circle-arrow-left"></i
    ></span>
    <span>
      Paso 1
    </span>
    <img src="../../../../assets/img-news/nubes-sol.svg" alt="Nubes Sol" />
  </div>
  <div class="four_page_content">
    
    <div class="info_content">
      <div class="info">
        <span><i class="fa-solid fa-phone"></i></span>
        <h2>Para continuar, ingresa el número de celular del clientes.</h2>
      </div>

      <div class="input_phone">
        <input type="text" placeholder="+57 315 2058712" />
      </div>

      <div class="button_phone">
        <button type="button">Enviar código</button>
        <p>
          Por digitar tu numero celular, aceptaras que te enviemos informaciónsss
          via <b>WhatsAppssasdas</b>..
        </p>
      </div>
    </div>
  </div>
</div>
