import { Component, Input, OnInit } from '@angular/core';
import { TotalDayInterface } from '../../interfaces/commerce-dashboard.interface';
import { CommerceDashboardService } from '../../services/commerce-dashboard.service';

@Component({
  selector: 'app-today-sales-dashboard',
  templateUrl: './today-sales-dashboards.component.html',
  styleUrls: ['./today-sales-dashboards.component.scss']
})
export class TodaySalesDashboardsComponent  {
  
  // TODO: @Input
  @Input() public totalDay!: TotalDayInterface


  constructor( 
    private commerceDashboardService: CommerceDashboardService
  ){}

  
  /**
   * Este metodo nos sirve para 
   * calcular el porcentaje depeniendo de las ventas actuales y anteriores
   * @param currentValue : Valor actual ( dia )
   * @param previousValue : Valor anterior ( dia )
   * @param type : Valor tipo si es cliente o ventas o pedidos
   * @returns { returns } : Retorna el mensaje dependiendo el tipo 
   */
  public calculateChange(currentValue: number, previousValue: number, type: string): string{
    return this.commerceDashboardService.calculateChange(currentValue, previousValue, type, 'dia')
    
  }
}
